import serverApi from '../serverApi';
import { AuthorizationResponse } from './types';

const loginEndpoint = '/api/user/token/login';

const login = async (username: string, password: string) => {
	const response = await serverApi.post(`${loginEndpoint}/`, { username, password });
	return response.data as AuthorizationResponse;
};

export { login };
