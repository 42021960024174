import { SxProps } from '@mui/material';
import { ComponentSize } from '../types';
import { SectionColor } from './types';

const defaultSectionStyle: SxProps = {
	width: '100%',
	border: '1px solid rgba(0, 0, 0, 0.1)',
	padding: '14px',
};

const getSectionPaperStyle = (size: ComponentSize, color: SectionColor): SxProps => {
	const style = { ...defaultSectionStyle };

	if (color === 'light') {
		style.backgroundColor = 'background.paper';
	} else if (color === 'dark') {
		style.backgroundColor = '#2d2d2d';
	} else {
		style.backgroundColor = 'transparent';
	}

	if (size === 'small') {
		style.paddingY = '8px';
	} else if (size === 'medium') {
		style.paddingY = '12px';
	} else {
		style.paddingY = '14px';
	}

	return style;
};

export { getSectionPaperStyle };
