import React, { useEffect, useState } from 'react';
// import getDummyTestDetails from '../../../../core/submissions/dummyData/dummyTestDetails';
import SubmissionInfo from './SubmissionInfo';
// import TestsDetails from './TestsDetails';
import { getSubmissionDetails } from '../../../../core/submissions/services';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { Submission } from '../../../../core/submissions/types';
import TestsDetails from './TestsDetails';
import { useTitle } from '../../../../contexts/TitleContext';

function SubmissionDetailView() {
	const { setTitle } = useTitle();
	const [submissionDetails, setSubmissionDetails] = useState<Submission | null>(null);
	// const [testsDetails, setTestsDetails] = useState(getDummyTestDetails);
	const [isLoading, setIsLoading] = useState(false);

	const { submissionId } = useParams();
	setTitle(`Submissions | ${submissionId}`);

	const fetchSubmission = () => {
		setIsLoading(true);
		getSubmissionDetails(submissionId)
			.then((result) => {
				setSubmissionDetails(result);
			})
			.catch((error) => {
				console.log(error);
				enqueueSnackbar('Error fetching submission details!', { variant: 'error' });
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		fetchSubmission();
	}, []);

	if (!submissionDetails) return <></>;

	return (
		<div>
			<SubmissionInfo isLoading={isLoading} submission={submissionDetails} fetchSubmission={fetchSubmission} />
			<TestsDetails
				contestSlug={submissionDetails.problem_set.contest_slug}
				testsDetails={submissionDetails.runtime_information}
			/>
		</div>
	);
}

export default SubmissionDetailView;
