import React, { useEffect, useState } from 'react';
import {
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	TextField,
} from '@mui/material';
import { getProblemSet, updateProblemSet } from '../../../core/problemset/services';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import { ProblemSet } from '../../../core/problemset/types';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { SyncProblemButton } from '../SyncProblem/SyncProblemButton';

export default function ProblemSetForm({ problemSetId, contestSlug }: { problemSetId: number; contestSlug: string }) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [problemSet, setProblemSet] = useState<ProblemSet | undefined>(undefined);
	const [errorData, setErrorData] = useState<Record<string, string>>({});

	const navigate = useNavigate();
	const location = useLocation();

	const handleOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		const updatedValue = value.replace(/[^a-zA-Z]/g, '').toUpperCase(); // removes non-alphabets and changes to uppercase
		const order = updatedValue.split('').reduce((acc, char) => acc * 26 + char.charCodeAt(0) - 64, 0); // A -> 1, B -> 2, A -> 26, AA -> 27
		setProblemSet({
			...problemSet!,
			order_character: updatedValue,
			problem_order: order,
		});
	};

	const handleVisibilityChange = (event: SelectChangeEvent) => {
		const { value } = event.target;
		setProblemSet({
			...problemSet!,
			is_visible: value === 'true',
		});
	};

	const getUpdatedPath = (newOrderChar: string) => {
		const path = location.pathname.split('/');
		path[path.length - 2] = newOrderChar;
		return path.join('/');
	};

	useEffect(() => {
		setIsLoading(true);
		getProblemSet(problemSetId)
			.then((data) => {
				setProblemSet(data);
			})
			.catch((e) => {
				console.log(e);
				if (axios.isAxiosError(e)) {
					enqueueSnackbar(`Problem could not be fetched!`, { variant: 'error' });
				}
			})
			.finally(() => setIsLoading(false));
	}, [problemSetId]);

	const handleProblemOrderAndVisibilitySave = () => {
		setIsLoading(true);
		updateProblemSet(problemSet!.id, { problem_order: problemSet!.problem_order, is_visible: problemSet?.is_visible })
			.then((data) => {
				const oldChar = location.pathname.split('/').at(-2);
				if (data.order_character !== oldChar) {
					const updatedPath = getUpdatedPath(data.order_character);
					navigate(updatedPath, { replace: true });
				}
				setProblemSet(data);
				enqueueSnackbar('Problem order updated!', { variant: 'success' });
			})
			.catch((e) => {
				if (axios.isAxiosError(e)) {
					setErrorData(e.response?.data ?? {});
					enqueueSnackbar(`Problem order could not be edited!`, { variant: 'error' });
				}
			})
			.finally(() => setIsLoading(false));
	};

	if (!problemSet || isLoading) return <Skeleton variant='rectangular' width={210} height={60} />;

	return (
		<Grid
			item
			container
			direction='row'
			alignItems='space-between'
			sx={{ width: '100%', padding: '20px', gap: 2 }}
			component={'form'}
		>
			<TextField
				required
				id='problem-character'
				name='problem-character'
				label='Problem Character'
				variant='outlined'
				value={problemSet?.order_character || ''}
				onChange={handleOrderChange}
				error={!!errorData.problem_order}
				helperText={errorData.problem_order}
			/>
			<TextField
				disabled
				id='problem-order'
				name='problem-order'
				label='Problem Order'
				variant='outlined'
				value={problemSet?.problem_order || 0}
			/>
			<TextField
				disabled
				id='contest-slug'
				name='contest-slug'
				label='Contest Slug'
				variant='outlined'
				value={contestSlug || ''}
			/>
			<FormControl>
				<InputLabel id='visibility'>Visible</InputLabel>
				<Select
					labelId='visibility'
					label='Visible'
					onChange={handleVisibilityChange}
					value={problemSet.is_visible.toString()}
				>
					<MenuItem value={'true'}>Yes</MenuItem>
					<MenuItem value={'false'}>No</MenuItem>
				</Select>
			</FormControl>
			<Button disabled={isLoading} variant='contained' onClick={handleProblemOrderAndVisibilitySave}>
				{isLoading ? <CircularProgress size={25} /> : `Save`}
			</Button>
			<SyncProblemButton problemId={problemSetId} />
		</Grid>
	);
}
