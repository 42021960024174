import serverApi from '../serverApi';
import { Clarification, ClarificationFormData, ClarificationsParams, PaginatedClarifications } from './types';

const clarificationsEndpoint = '/api/judge/clarifications';

const getClarifications = async (params: ClarificationsParams) => {
	return (await serverApi.get<PaginatedClarifications>(`${clarificationsEndpoint}/`, { params }))
		.data as PaginatedClarifications;
};

const getClarificationData = async (clarificationId: number) => {
	return (await serverApi.get(`${clarificationsEndpoint}/${clarificationId}/`)).data as Clarification;
};

const createNewClarification = async (clarification: Partial<ClarificationFormData>) => {
	return (await serverApi.post(`${clarificationsEndpoint}/`, clarification)).data as Clarification;
};

const editClarification = async (clarificationId: number, clarification: Partial<ClarificationFormData>) => {
	return (await serverApi.put(`${clarificationsEndpoint}/${clarificationId}/`, clarification)).data as Clarification;
};

export { getClarifications, getClarificationData, createNewClarification, editClarification };
