import React, { useEffect } from 'react';
import { SectionPaper, SectionTitle } from '../../../../../design-library';
import { ContestRead, ProblemSetMeta } from '../../../../../core/contests/types';
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from '@mui/material';
import SideBarBriefAndTimer from '../../../SideBarBriefAndTimer';
import {
	GetSubmissionsParams,
	SUBMISSION_STATUS_ENUMS,
	SubmissionResponseLabels,
	SubmissionResponses,
	SubmissionStatuses,
	SubmissionStatusLabels,
} from '../../../../../core/submissions/types';
import { getLanguages } from '../../../../../core/languages/services';
import { ProblemLanguage } from '../../../../../core/languages/types';
import RejudgeAllConfirmationDialog from './RejudgeAllConfirmationDialog';

export default function SubmissionsSidebar({
	contest,
	filterParams,
	setFilterParams,
	onFilterHandler,
	rejudgeSubmissions,
	isLoading,
	onClearFilters,
	showingOwnSubmission,
}: {
	contest: ContestRead;
	filterParams: GetSubmissionsParams;
	setFilterParams: (data: GetSubmissionsParams) => void;
	onFilterHandler: () => void;
	rejudgeSubmissions: () => Promise<void>;
	isLoading: boolean;
	onClearFilters: () => void;
	showingOwnSubmission: boolean;
}) {
	const [languages, setLanguages] = React.useState<ProblemLanguage[]>([]);
	const [isRejudgeConfirmationOpen, setIsRejudgeConfirmationOpen] = React.useState<boolean>(false);

	useEffect(() => {
		getLanguages().then((data) => {
			setLanguages(data);
		});
	}, []);

	const isJudgeOrStaff = (): boolean => {
		return contest.role === 'JUDGE' || contest.role === 'STAFF';
	};

	const handleAuthorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilterParams({
			...filterParams,
			submitted_by: event.target.value,
		});
	};

	const handleProblemSelect = (event: SelectChangeEvent<string | null>) => {
		setFilterParams({
			...filterParams,
			problem_set: event.target.value === 'all' ? null : parseInt(event.target.value as string),
		});
	};
	const handleStatusSelect = (event: SelectChangeEvent<string | null>) => {
		setFilterParams({
			...filterParams,
			status: event.target.value === 'all' ? null : event.target.value,
			response: null,
		});
	};

	const handleVerdictSelect = (event: SelectChangeEvent<string | null>) => {
		setFilterParams({
			...filterParams,
			response: event.target.value === 'all' ? null : event.target.value,
			status: filterParams.status !== SUBMISSION_STATUS_ENUMS.JUDGED ? null : filterParams.status,
		});
	};

	const handleLanguageSelect = (event: SelectChangeEvent<string | null>) => {
		setFilterParams({
			...filterParams,
			language: event.target.value === 'all' ? null : event.target.value,
		});
	};

	return (
		<Grid container direction='column' rowSpacing={2}>
			<Grid item>
				<SideBarBriefAndTimer contest={contest} />
			</Grid>

			<Grid item container direction='column' rowSpacing='2px'>
				<Grid item>
					<SectionTitle title='Filter' size='small' />
				</Grid>
				<Grid item>
					<SectionPaper>
						<Grid container direction='column' rowSpacing={2}>
							{!showingOwnSubmission && (
								<Grid item>
									<TextField
										fullWidth
										id='submitted_by'
										name='submitted_by'
										label='Username'
										variant='outlined'
										value={filterParams.submitted_by ?? ''}
										onChange={handleAuthorChange}
									/>
									{/*TODO: Convert author to be a searchable field*/}
									{/*<FormControl sx={{ width: '100%' }}>*/}
									{/*	<Autocomplete*/}
									{/*		id='free-solo-2-demo'*/}
									{/*		disableClearable*/}
									{/*		options={exampleContestants.map((option) => option.username)}*/}
									{/*		renderInput={(params) => (*/}
									{/*			<TextField*/}
									{/*				{...params}*/}
									{/*				label='Search Author'*/}
									{/*				value={selectedAuthor}*/}
									{/*				onChange={(event) => setSelectedAuthor(event.target.value)}*/}
									{/*				InputProps={{*/}
									{/*					...params.InputProps,*/}
									{/*					type: 'search',*/}
									{/*				}}*/}
									{/*			/>*/}
									{/*		)}*/}
									{/*	/>*/}
									{/*</FormControl>*/}
								</Grid>
							)}
							<Grid item>
								<FormControl sx={{ width: '100%' }}>
									<InputLabel id='select-problem-label'>Problem</InputLabel>
									<Select
										labelId='select-problem-label'
										id='select-problem'
										value={(filterParams.problem_set ?? 'all') as string}
										label='Problem'
										onChange={handleProblemSelect}
									>
										<MenuItem value={'all'}>All</MenuItem>
										{contest.problem_set.map((problem_set: ProblemSetMeta) => (
											<MenuItem key={problem_set.id} value={problem_set.id}>
												{problem_set.problem_order_character}. {problem_set.title}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item>
								<FormControl sx={{ width: '100%' }}>
									<InputLabel id='select-submission-status-label'>Status</InputLabel>
									<Select
										labelId='select-submission-status-label'
										id='select-submission-status'
										value={filterParams.status ?? 'all'}
										label='Status'
										onChange={handleStatusSelect}
									>
										<MenuItem value={'all'}>All</MenuItem>
										{SubmissionStatuses.map((status) => (
											<MenuItem key={status} value={status}>
												{SubmissionStatusLabels[status]}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item>
								<FormControl sx={{ width: '100%' }}>
									<InputLabel id='select-submission-verdict-label'>Verdict</InputLabel>
									<Select
										labelId='select-submission-verdict-label'
										id='select-submission-verdict'
										value={filterParams.response ?? 'all'}
										label='Verdict'
										onChange={handleVerdictSelect}
									>
										<MenuItem value={'all'}>All</MenuItem>
										{SubmissionResponses.map((response) => (
											<MenuItem key={response} value={response}>
												{SubmissionResponseLabels[response]}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item>
								<FormControl sx={{ width: '100%' }}>
									<InputLabel id='select-language-label'>Language</InputLabel>
									<Select
										labelId='select-language-label'
										id='select-language'
										value={filterParams.language ?? 'all'}
										label='Language'
										onChange={handleLanguageSelect}
									>
										<MenuItem value={'all'}>All</MenuItem>
										{Object.values(languages).map((language: ProblemLanguage) => (
											<MenuItem key={language.id} value={language.name}>
												{language.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid container item spacing={2} justifyContent={'end'}>
								<Grid item xs={6}>
									<Button disabled={isLoading} variant='outlined' fullWidth onClick={onClearFilters}>
										Clear
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button disabled={isLoading} variant='contained' fullWidth onClick={onFilterHandler}>
										Filter
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</SectionPaper>
				</Grid>
			</Grid>
			{isJudgeOrStaff() && (
				<Grid item container direction='column' rowSpacing='2px'>
					<Grid item>
						<SectionTitle title='Rejudge' size='small' />
					</Grid>
					<Grid item>
						<SectionPaper>
							<Grid item container spacing={2}>
								<Grid item>
									<Typography color={'warning'}>
										<strong>Caution:</strong> This will rejudge all submissions following the filters set above!
									</Typography>
								</Grid>
								<Grid item sx={{ width: '100%' }}>
									<Button
										disabled={isLoading}
										variant='outlined'
										color='error'
										fullWidth
										onClick={() => setIsRejudgeConfirmationOpen(true)}
									>
										Rejudge all Submissions
									</Button>
								</Grid>
							</Grid>
						</SectionPaper>
					</Grid>
					<RejudgeAllConfirmationDialog
						open={isRejudgeConfirmationOpen}
						onClose={() => setIsRejudgeConfirmationOpen(false)}
						onConfirmation={() => {
							rejudgeSubmissions().then(() => {
								setIsRejudgeConfirmationOpen(false);
							});
						}}
						filters={filterParams}
						isLoading={isLoading}
					/>
				</Grid>
			)}
		</Grid>
	);
}
