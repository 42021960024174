export interface Submission {
	id: number;
	problem: SubmissionProblem;
	problem_set: SubmissionProblemSet;
	submitted_by: SubmissionSubmittedBy;
	language: SubmissionLanguage;
	submission_type: SubmissionType;
	is_judged: boolean;
	source_path?: string;
	source_url?: string;
	is_cheated?: boolean;
	status: SubmissionStatus;
	points: number;
	cpu: number;
	time: number;
	memory: number;
	created_at: Date;
	updated_at: Date;
	response: SubmissionResponse;
	runtime_information: TestDetail[];
}

export type SubmissionSubmittedBy = {
	id: number;
	username: string;
	full_name: string;
};

export type SubmissionProblemSet = {
	id: number;
	contest_id: number;
	contest_slug: string;
	order_character: string;
};

export type SubmissionProblem = {
	id: number;
	title: string;
};

export const SUBMISSION_STATUS_ENUMS = {
	QUEUED: 'QUEUED',
	RUNNING: 'RUNNING',
	JUDGED: 'JUDGED',
	'-JUDGED': '-JUDGED',
	FILE_UPLOADING: 'FILE_UPLOADING',
	REJUDGING: 'REJUDGING',
};
export const SubmissionStatuses = Object.keys(SUBMISSION_STATUS_ENUMS) as SubmissionStatus[];
export type SubmissionStatus = keyof typeof SUBMISSION_STATUS_ENUMS;
export const SubmissionStatusLabels = {
	[SUBMISSION_STATUS_ENUMS.QUEUED]: 'Queued',
	[SUBMISSION_STATUS_ENUMS.RUNNING]: 'Running',
	[SUBMISSION_STATUS_ENUMS.JUDGED]: 'Judged',
	[SUBMISSION_STATUS_ENUMS['-JUDGED']]: 'Not Judged',
	[SUBMISSION_STATUS_ENUMS.FILE_UPLOADING]: 'File Uploading',
	[SUBMISSION_STATUS_ENUMS.REJUDGING]: 'Rejudging',
};

export const SUBMISSION_RUN_STATUS_ENUMS = {
	QUEUED: 'QUEUED',
	RUNNING: 'RUNNING',
	VALIDATION_PENDING: 'VALIDATION_PENDING',
	DONE: 'DONE',
};

export const SubmissionRunStatuses = Object.keys(SUBMISSION_RUN_STATUS_ENUMS) as SubmissionRunStatus[];
export type SubmissionRunStatus = keyof typeof SUBMISSION_RUN_STATUS_ENUMS;
export const SubmissionRunStatusLabels = {
	[SUBMISSION_RUN_STATUS_ENUMS.QUEUED]: 'Queued',
	[SUBMISSION_RUN_STATUS_ENUMS.RUNNING]: 'Running',
	[SUBMISSION_RUN_STATUS_ENUMS.VALIDATION_PENDING]: 'Validation Pending',
	[SUBMISSION_RUN_STATUS_ENUMS.DONE]: 'Done',
};

export const SUBMISSION_RESPONSE_ENUMS = {
	ACCEPTED: 'ACCEPTED',
	'-ACCEPTED': '-ACCEPTED',
	PENDING: 'PENDING',
	WRONG_ANSWER: 'WRONG_ANSWER',
	TIME_LIMIT_EXCEEDED: 'TIME_LIMIT_EXCEEDED',
	MEMORY_LIMIT_EXCEEDED: 'MEMORY_LIMIT_EXCEEDED',
	OUTPUT_LIMIT_EXCEEDED: 'OUTPUT_LIMIT_EXCEEDED',
	RESTRICTED_FUNCTION_VIOLATION: 'RESTRICTED_FUNCTION_VIOLATION',
	CPU_LIMIT_EXCEEDED: 'CPU_LIMIT_EXCEEDED',
	RUNTIME_ERROR: 'RUNTIME_ERROR',
	COMPILATION_ERROR: 'COMPILATION_ERROR',
	UNKNOWN_EXCEPTION: 'UNKNOWN_EXCEPTION',
	VALIDATOR_ERROR: 'VALIDATOR_ERROR',
	UNKNOWN_ERROR: 'UNKNOWN_ERROR',
	SOURCE_MISSING: 'SOURCE_MISSING',
	NO_TEST_CASE_FOUND: 'NO_TEST_CASE_FOUND',
	SOURCE_LIMIT_EXCEEDED: 'SOURCE_LIMIT_EXCEEDED',
	VERDICT_HIDDEN: 'VERDICT_HIDDEN',
};

export const SubmissionResponses = Object.keys(SUBMISSION_RESPONSE_ENUMS) as SubmissionResponse[];
export type SubmissionResponse = keyof typeof SUBMISSION_RESPONSE_ENUMS;
export const SubmissionResponseLabels = {
	[SUBMISSION_RESPONSE_ENUMS.ACCEPTED]: 'Accepted',
	[SUBMISSION_RESPONSE_ENUMS['-ACCEPTED']]: 'Not Accepted',
	[SUBMISSION_RESPONSE_ENUMS.PENDING]: 'Pending',
	[SUBMISSION_RESPONSE_ENUMS.WRONG_ANSWER]: 'Wrong Answer',
	[SUBMISSION_RESPONSE_ENUMS.TIME_LIMIT_EXCEEDED]: 'Time Limit Exceeded',
	[SUBMISSION_RESPONSE_ENUMS.MEMORY_LIMIT_EXCEEDED]: 'Memory Limit Exceeded',
	[SUBMISSION_RESPONSE_ENUMS.OUTPUT_LIMIT_EXCEEDED]: 'Output Limit Exceeded',
	[SUBMISSION_RESPONSE_ENUMS.RESTRICTED_FUNCTION_VIOLATION]: 'Restricted Function Violation',
	[SUBMISSION_RESPONSE_ENUMS.CPU_LIMIT_EXCEEDED]: 'CPU Limit Exceeded',
	[SUBMISSION_RESPONSE_ENUMS.RUNTIME_ERROR]: 'Runtime Error',
	[SUBMISSION_RESPONSE_ENUMS.COMPILATION_ERROR]: 'Compilation Error',
	[SUBMISSION_RESPONSE_ENUMS.UNKNOWN_ERROR]: 'Unknown Error',
	[SUBMISSION_RESPONSE_ENUMS.UNKNOWN_EXCEPTION]: 'Unknown Exception',
	[SUBMISSION_RESPONSE_ENUMS.VALIDATOR_ERROR]: 'Validator Error',
	[SUBMISSION_RESPONSE_ENUMS.SOURCE_MISSING]: 'Source Missing',
	[SUBMISSION_RESPONSE_ENUMS.NO_TEST_CASE_FOUND]: 'No Test Cases Found',
	[SUBMISSION_RESPONSE_ENUMS.SOURCE_LIMIT_EXCEEDED]: 'Source Limit Exceeded',
	[SUBMISSION_RESPONSE_ENUMS.VERDICT_HIDDEN]: 'Verdict Hidden',
};

type SubmissionLanguage = 'C' | 'C++' | 'JAVA' | 'Python 2' | 'Python 3';
export type SubmissionType = 'PRIVATE' | 'CONTEST' | 'DRY-RUN';

export type SubmissionsFilterParams = {
	contest?: number;
	problem_set?: number | string | null;
	status?: string | null;
	response?: string | null;
	language?: string | null;
	submitted_by?: string | null;
};

export type GetSubmissionsParams = SubmissionsFilterParams & {
	limit?: number;
	offset?: number;
};

export type RejudgeSubmissionsParams = SubmissionsFilterParams;

export interface PaginatedSubmissions {
	count: number;
	previous: string | null;
	next: string | null;
	results: Submission[];
}

export interface TestDetail {
	cpu: number;
	memory: number;
	status: SubmissionRunStatus;
	response: SubmissionResponse;
	writes: number;
	stdout_url: string;
	test_case: TestCase;
	stderr: string;
}

export interface TestCase {
	id: number;
	output_url: string;
}
