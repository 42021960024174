import serverApi from '../serverApi';

import { LanguageName, ProblemLanguage } from './types';

const languageEndpoint = '/api/judge/problem-languages';

const getLanguages = async () => {
	return (await serverApi.get(`${languageEndpoint}/`)).data as ProblemLanguage[];
};

const getLanguageIdentifier = (languageName: LanguageName) => {
	switch (languageName) {
		case 'C':
			return 'c';
		case 'C++':
			return 'cpp';
		case 'JAVA':
			return 'java';
		case 'Python 2':
			return 'py2';
		case 'Python 3':
			return 'py3';
		default:
			return 'cpp';
	}
};

export { getLanguages, getLanguageIdentifier };
