import React from 'react';
import {
	Accordion,
	AccordionSummary,
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import { Problem, ProblemType } from '../../../core/problems/types';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { PROBLEM_TYPE_LABELS, ProblemTypes } from '../../../core/problems/constants';
import _ from 'lodash';
import { editContestProblem } from '../../../core/problems/services';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeEditor from '../../../design-library/CodeEditor';
import { ProblemLanguage } from '../../../core/languages/types';
import { getLanguageIdentifier } from '../../../core/languages/services';

const PARAMETERS_FIELD = [
	'problem_type',
	'validator',
	'validator_lang',
	'time_limit',
	'cpu_limit',
	'memory_limit',
	'output_limit',
	'source_limit',
];

export default function ProblemParameters({
	problem,
	setProblem,
	languages,
}: {
	problem: Problem;
	setProblem: (value: Problem) => void;
	languages: ProblemLanguage[];
}) {
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = React.useState(false);
	const [formData, setFormData] = React.useState<Partial<Problem>>(_.pick(problem, PARAMETERS_FIELD));
	const [errorData, setErrorData] = React.useState<Partial<Problem>>({});

	const handleValueChange = (event: React.FocusEvent<HTMLInputElement>) => {
		const { name, value, type, checked } = event.target;
		const updatedValue = type === 'checkbox' ? checked : value;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: updatedValue,
		}));
	};

	const handleSelectChange = (event: SelectChangeEvent<ProblemType | number>) => {
		const { name, value } = event.target;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleNamedValueChange = (name: keyof Problem, value?: string | number) => {
		console.log(name, value);
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	console.log(formData);

	const onSubmitHandler = () => {
		setIsLoading(true);
		editContestProblem(problem.id!, formData, problem.problem_set!.id)
			.then((data) => {
				enqueueSnackbar(`Problem edited successfully!`, {
					variant: 'success',
				});
				setProblem(data);
				setErrorData({});
			})
			.catch((e) => {
				if (axios.isAxiosError(e)) {
					setErrorData(e.response?.data ?? {});
					enqueueSnackbar(`Problem could not be edited!`, { variant: 'error' });
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Grid container direction='column' justifyContent='center' sx={{ width: '100%', gap: 2 }} component={'form'}>
			<FormControl sx={{ width: '100%' }} error={!!errorData.problem_type}>
				<InputLabel id='select-contest-visibility-label'>Judge Type</InputLabel>
				<Select
					labelId='select-problem-type'
					id='select-problem-type'
					name='problem_type'
					defaultValue={formData.problem_type}
					label='Judge Type'
					onChange={handleSelectChange}
				>
					{Object.values(ProblemTypes).map((problemType: ProblemType) => (
						<MenuItem key={problemType} value={problemType}>
							{PROBLEM_TYPE_LABELS[problemType]}
						</MenuItem>
					))}
				</Select>
				<FormHelperText>{errorData.problem_type}</FormHelperText>
			</FormControl>
			{formData.problem_type === 'SPECIAL' && (
				<FormControl sx={{ width: '100%' }} error={!!errorData.validator_lang}>
					<InputLabel id='select-language-label'>Validator Language</InputLabel>
					<Select
						labelId='select-validator-language-label'
						id='select-validator-language'
						name='validator_lang'
						defaultValue={formData.validator_lang}
						label='Validator Language'
						onChange={handleSelectChange}
					>
						{Object.values(languages).map((problemLanguage: ProblemLanguage) => (
							<MenuItem key={problemLanguage.id} value={problemLanguage.id}>
								{problemLanguage.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			{formData.problem_type === 'SPECIAL' && (
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>Validator Code</Typography>
					</AccordionSummary>
					<CodeEditor
						language={getLanguageIdentifier(
							(languages.find((language) => language.id === formData.validator_lang) as ProblemLanguage)?.name ?? '',
						)}
						value={formData.validator ?? ''}
						setValue={(value) => handleNamedValueChange('validator', value)}
					/>
					{!!errorData.validator && <FormHelperText error>{errorData.validator}</FormHelperText>}
				</Accordion>
			)}
			<FormControl fullWidth error={!!errorData.time_limit}>
				<InputLabel htmlFor='outlined-adornment-cpu-limit'>CPU Limit</InputLabel>
				<OutlinedInput
					id='outlined-adornment-cpu-limit'
					name='cpu_limit'
					endAdornment={<InputAdornment position='end'>seconds</InputAdornment>}
					label='CPU Limit'
					onChange={({ target: { value } }) => {
						handleNamedValueChange('cpu_limit', value);
						handleNamedValueChange('time_limit', value); // TODO: Temporary solution, fix it later
					}}
					type='number'
					defaultValue={formData.cpu_limit}
					inputProps={{
						step: 0.5,
						min: 0.5,
					}}
				/>
				<FormHelperText>{errorData.cpu_limit}</FormHelperText>
			</FormControl>
			<FormControl fullWidth error={!!errorData.time_limit}>
				<InputLabel htmlFor='outlined-adornment-time-limit'>Time Limit</InputLabel>
				<OutlinedInput
					disabled
					id='outlined-adornment-time-limit'
					name='time_limit'
					endAdornment={<InputAdornment position='end'>seconds</InputAdornment>}
					label='Time Limit'
					onChange={handleValueChange}
					type='number'
					value={formData.time_limit}
					inputProps={{
						step: 0.5,
						min: 0.5,
					}}
				/>
				<FormHelperText>{errorData.time_limit || 'This will be same as "CPU Limit" for now'}</FormHelperText>
			</FormControl>
			<FormControl fullWidth error={!!errorData.memory_limit}>
				<InputLabel htmlFor='outlined-adornment-memory-limit'>Memory Limit</InputLabel>
				<OutlinedInput
					id='outlined-adornment-memory-limit'
					name='memory_limit'
					endAdornment={<InputAdornment position='end'>MB</InputAdornment>}
					label='Memory Limit'
					onBlur={handleValueChange}
					type='number'
					defaultValue={formData.memory_limit}
					inputProps={{
						step: 512,
						min: 512,
					}}
				/>
				<FormHelperText>{errorData.memory_limit}</FormHelperText>
			</FormControl>
			<FormControl fullWidth error={!!errorData.output_limit}>
				<InputLabel htmlFor='outlined-adornment-output-limit'>Output Limit</InputLabel>
				<OutlinedInput
					id='outlined-adornment-output-limit'
					name='output_limit'
					endAdornment={<InputAdornment position='end'>Bytes</InputAdornment>}
					label='Output Limit'
					onBlur={handleValueChange}
					type='number'
					defaultValue={formData.output_limit}
					inputProps={{
						step: 512,
						min: 512,
					}}
				/>
				<FormHelperText>{errorData.output_limit || 'Please do not use more than ~45MB'}</FormHelperText>
			</FormControl>
			<FormControl fullWidth error={!!errorData.source_limit}>
				<InputLabel htmlFor='outlined-adornment-source-limit'>Source Limit</InputLabel>
				<OutlinedInput
					id='outlined-adornment-source-limit'
					name='source_limit'
					endAdornment={<InputAdornment position='end'>Bytes</InputAdornment>}
					label='Source Limit'
					onBlur={handleValueChange}
					type='number'
					defaultValue={formData.source_limit}
					inputProps={{
						step: 512,
						min: 512,
					}}
				/>
				<FormHelperText>{errorData.source_limit || 'Please do not use more than ~45MB'}</FormHelperText>
			</FormControl>
			{/*<FormControl fullWidth>*/}
			{/*	<InputLabel id='select-languages-label'>Languages</InputLabel>*/}
			{/*	<Select*/}
			{/*		multiple*/}
			{/*		labelId='select-languages-label'*/}
			{/*		id='select-language'*/}
			{/*		// value={selectedValidatorLanguage}*/}
			{/*		label='Languages'*/}
			{/*		// onChange={handleLanguageSelect}*/}
			{/*		size='small'*/}
			{/*	>*/}
			{/*		{Object.values(SUBMISSION_LANGUAGES).map((submissionLanguage: SubmissionLanguage) => (*/}
			{/*			<MenuItem key={submissionLanguage} value={submissionLanguage}>*/}
			{/*				{SUBMISSION_LANGUAGE_LABELS[submissionLanguage]}*/}
			{/*			</MenuItem>*/}
			{/*		))}*/}
			{/*	</Select>*/}
			{/*</FormControl>*/}
			<Button disabled={isLoading} variant='contained' onClick={onSubmitHandler} size='large'>
				{isLoading ? <CircularProgress size={25} /> : `Save`}
			</Button>
		</Grid>
	);
}
