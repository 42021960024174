import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

export default function RenderSubmissionID(props: GridRenderCellParams, contestSlug: string) {
	const { value } = props;

	return (
		<Link to={`/contests/${contestSlug}/submissions/${value}`} component={NavLink}>
			{value}
		</Link>
	);
}
