import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ContestRead } from '../../core/contests/types';
import { getContestData } from '../../core/contests/services';
import { useParams } from 'react-router-dom';
import MarkdownView from '../../design-library/MarkdownView';
import { LinearLoader } from '../../design-library/LinearLoader';
import ContestHeading from '../common/ContestHeading';
import moment from 'moment/moment';
import { SectionPaper } from '../../design-library';
import { useTitle } from '../../contexts/TitleContext';

export default function ContestNotes() {
	const { setTitle } = useTitle();
	setTitle('Notes');
	const { contestSlug } = useParams();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [contest, setContest] = useState<ContestRead | undefined>(undefined);

	useEffect(() => {
		const fetchContest = async () => {
			setIsLoading(true);
			const contestData = await getContestData(contestSlug!);
			setContest(contestData);
			setIsLoading(false);
		};
		fetchContest();
	}, []);

	if (isLoading || !contest) return <LinearLoader />;

	return (
		<Grid container direction={'column'} xs={12} justifyContent={'center'} sx={{ padding: '24px' }} spacing={2}>
			<Grid item container justifyContent={'center'}>
				<Typography variant={'h3'} color={'primary.main'}>
					CONTEST NOTES
				</Typography>
			</Grid>
			<Grid item>
				<ContestHeading name={contest.title} starts_at={moment(contest.starts_at)} stops_at={moment(contest.ends_at)} />
			</Grid>
			<SectionPaper>
				<Box sx={{ width: '100%', paddingX: '20px', marginTop: '20px' }}>
					<MarkdownView data={contest.notes ? contest.notes : '> <center>No notes added yet!</center>'} />
				</Box>
			</SectionPaper>
		</Grid>
	);
}
