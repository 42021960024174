import axios, { AxiosInstance } from 'axios';
import LocalStoreAccessor from './store/LocalStoreAccessor';
import { USER_NAMESPACE } from './store/constants';
const logoutUrl = '/logout';

const userStoreAccessor = LocalStoreAccessor({ namespace: USER_NAMESPACE });

const instance: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8000',
});

instance.interceptors.request.use(function (config) {
	const token = userStoreAccessor.get('token');
	if (token) {
		config.headers.Authorization = `token ${token}`;
	}
	return config;
});

instance.interceptors.response.use(undefined, (error) => {
	if (axios.isAxiosError(error) && error.response?.status === 401) {
		window.location.replace(logoutUrl);
	}
	if (axios.isAxiosError(error) && error.response?.status === 403) {
		window.location.replace('/403');
	}
	return Promise.reject(error);
});

export default instance;
