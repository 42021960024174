import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import AuthorCell from './itemComponents/AuthorCell';

export default function RenderAuthor(props: GridRenderCellParams) {
	const {
		value,
		row: { submission_type: submission_type },
	} = props;

	return <AuthorCell fullName={value.full_name} username={value.username} submission_type={submission_type} />;
}
