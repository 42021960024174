import React from 'react';
import { ContestRead } from '../../../../core/contests/types';
import { Grid } from '@mui/material';
import SideBarBriefAndTimer from '../../SideBarBriefAndTimer';
import ProblemSubmitSection from './ProblemSubmitSection';
import ProblemsetSubmissionsList from './ProblemsetSubmissionsList';

export default function ProblemSidebar({ contest, problemSetId }: { contest: ContestRead; problemSetId: number }) {
	const [newSubmissionId, setNewSubmissionId] = React.useState<number | null>(null);
	return (
		<Grid container direction='column' rowSpacing={2}>
			<Grid item>
				<SideBarBriefAndTimer contest={contest} />
			</Grid>
			{/*<Grid item container direction='column' rowSpacing='2px'>*/}
			{/*	<Grid item>*/}
			{/*		<SectionTitle title='Practice' size='small' />*/}
			{/*	</Grid>*/}
			{/*	<Grid item></Grid>*/}
			{/*</Grid>*/}
			<Grid item>
				<ProblemSubmitSection problemSetId={problemSetId} setNewSubmissionId={setNewSubmissionId} />
			</Grid>
			<Grid item>
				<ProblemsetSubmissionsList
					contestSlug={contest.slug}
					problemSetId={problemSetId}
					newSubmissionId={newSubmissionId}
				/>
			</Grid>
		</Grid>
	);
}
