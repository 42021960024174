import React, { useEffect, useState } from 'react';
import { Submission } from '../../../../core/submissions/types';
import { Grid, IconButton, Typography } from '@mui/material';
import { SectionPaper, SectionTitle } from '../../../../design-library';
import { getSubmissions } from '../../../../core/submissions/services';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import RenderSubmissionID from '../../../common/tableCellRenderers/RenderSubmissionID';
import RenderSubmissionTime from '../../../common/tableCellRenderers/RenderSubmissionTime';
import RenderVerdict from '../../../common/tableCellRenderers/RenderVerdict';
import CachedIcon from '@mui/icons-material/Cached';
import { LinearLoader } from '../../../../design-library/LinearLoader';

export default function ProblemsetSubmissionsList({
	contestSlug,
	problemSetId,
	newSubmissionId,
}: {
	contestSlug: string;
	problemSetId: number;
	newSubmissionId: number | null;
}) {
	const currentUser = useCurrentUser();
	const PAGE_SIZE = 10;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [userSubmissions, setUserSubmissions] = useState<Submission[]>([]);
	const [count, setCount] = useState<number>(0);

	const fetchSubmission = () => {
		setIsLoading(true);
		getSubmissions({ problem_set: problemSetId, submitted_by: currentUser!.username!, limit: PAGE_SIZE })
			.then((data) => {
				setUserSubmissions(data.results);
				setCount(data.count);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		fetchSubmission();
	}, [newSubmissionId]);

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: '#',
			flex: 2,
			sortable: false,
			renderCell: (props: GridRenderCellParams) => RenderSubmissionID(props, contestSlug),
		},
		{
			field: 'created_at',
			headerName: 'Time',
			flex: 2,
			minWidth: 100,
			sortable: false,
			renderCell: RenderSubmissionTime,
		},
		{
			field: 'response',
			headerName: 'Verdict',
			flex: 5,
			renderCell: RenderVerdict,
			minWidth: 100,
			sortable: false,
		},
	];

	return (
		<Grid container direction='column' rowSpacing='2px'>
			<Grid item>
				<SectionTitle
					title={'My Submissions'}
					size='small'
					actions={
						<IconButton onClick={fetchSubmission}>
							<CachedIcon />
						</IconButton>
					}
				/>
			</Grid>
			<Grid item>
				{isLoading && <LinearLoader />}{' '}
				{userSubmissions && userSubmissions.length ? (
					<div>
						<DataGrid
							rows={userSubmissions}
							columns={columns}
							disableRowSelectionOnClick
							sx={{ fontSize: '12px' }}
							hideFooter
							hideFooterPagination
						/>
						{count > PAGE_SIZE && (
							<Typography variant='subtitle2'>
								Only top {PAGE_SIZE} results are listed. Find more in{' '}
								<a href={`/contests/${contestSlug}/submissions`}>submissions</a> tab.{' '}
							</Typography>
						)}
					</div>
				) : (
					<SectionPaper>
						<Typography>{'No submissions yet!'}</Typography>
					</SectionPaper>
				)}
			</Grid>
		</Grid>
	);
}
