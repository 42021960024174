import React, { useState } from 'react';
import { LinkStyle } from '../styles';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { updateSubmissionStatus } from '../../../../../core/submissions/services';
import {
	SUBMISSION_RESPONSE_ENUMS,
	SUBMISSION_STATUS_ENUMS,
	SubmissionResponse,
	SubmissionStatus,
} from '../../../../../core/submissions/types';

function RejudgeSubmission({ onSuccess }: { onSuccess: () => void }) {
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { submissionId } = useParams();
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

	const enqueueSubmissionForRejudge = () => {
		setLoading(true);
		updateSubmissionStatus(
			submissionId!,
			SUBMISSION_STATUS_ENUMS.REJUDGING as SubmissionStatus,
			SUBMISSION_RESPONSE_ENUMS.PENDING as SubmissionResponse,
		)
			.then(() => {
				enqueueSnackbar('Submission queued for rejudge successfully!', {
					autoHideDuration: 3000,
					variant: 'success',
				});
				setIsConfirmationModalOpen(false);
				onSuccess();
			})
			.catch((error: Error) => {
				console.log(error);
				enqueueSnackbar('Error rejudging submission!', {
					autoHideDuration: 3000,
					variant: 'error',
				});
			});
		setLoading(false);
	};

	return (
		<div>
			{loading ? (
				<CircularProgress size={24} />
			) : (
				<span onClick={() => setIsConfirmationModalOpen(true)} style={LinkStyle}>
					Rejudge
				</span>
			)}
			<Dialog open={isConfirmationModalOpen} onClose={() => setIsConfirmationModalOpen(false)}>
				<DialogTitle>Rejudge submission</DialogTitle>
				<DialogContent sx={{ backgroundColor: 'white' }}>
					<DialogContentText>Are you sure you want to rejudge this submission?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} variant='contained' onClick={enqueueSubmissionForRejudge}>
						{loading ? <CircularProgress size={25} /> : `Rejudge`}
					</Button>
					<Button disabled={loading} variant='outlined' onClick={() => setIsConfirmationModalOpen(false)}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default RejudgeSubmission;
