// TitleContext.tsx

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface TitleContextType {
	title: string;
	setTitle: (title: string) => void;
}

interface TitleProviderProps {
	children: ReactNode;
}

const TitleContext = createContext<TitleContextType | undefined>(undefined);

export const TitleProvider: React.FC<TitleProviderProps> = ({ children }) => {
	const [title, setTitle] = useState<string>('BAPS OJ');

	useEffect(() => {
		document.title = title;
	}, [title]);

	return <TitleContext.Provider value={{ title, setTitle }}>{children}</TitleContext.Provider>;
};

export const useTitle = () => {
	const context = useContext(TitleContext);

	if (!context) {
		throw new Error('useTitle must be used within a TitleProvider');
	}

	return context;
};
