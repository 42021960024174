import serverApi from '../serverApi';
import { PaginatedStandings } from './types';
// import { getPaginatedDummyStandingsData } from './dummyData/DummyStandingsData';

const standingsEndpoint = '/api/judge/standings';
const standingsInternalEndpoint = '/api/judge/internal/standings';

// To test with real data
// const _getStandings = async (page: number) => {
// 	return (
// 		await serverApi.get('https://api.bapsoj.org/api/judge/standings/', {
// 			params: {
// 				contest: 4,
// 				page,
// 			},
// 		})
// 	).data as PaginatedStandings;
// };

const getPublicStandings = async (contest: number, page: number) => {
	// return getPaginatedDummyStandingsData();
	// return await _getStandings(page);
	return (
		await serverApi.get(`${standingsEndpoint}/`, {
			params: {
				contest,
				page,
			},
		})
	).data as PaginatedStandings;
};

const getInternalStandings = async (contest: number, page: number) => {
	return (
		await serverApi.get(`${standingsInternalEndpoint}/`, {
			params: {
				contest,
				page,
			},
		})
	).data as PaginatedStandings;
};

const getFrozenCatchyMessages = () => {
	const winterIsHere = [
		"In the game of rankings, you win or you wait. Who'll seize the throne?",
		'Hold your dragons! The results are coming, like a storm!',
		'Winterfell is frozen, but the leaderboard will thaw soon.',
		'Valar Dohaeris - All scores must serve! Be patient.',
		'The Iron Throne of victory is fiercely contested. Await the outcome.',
		'While we wait for the night to pass, enjoy some ginger ale!',
		'The standings are a mystery, like the secrets of the Red Keep. But all will be revealed soon!',
		'Prepare for the long night. The standings are in the crypts, ready to rise!',
		'The final results are in the shadows, like the Faceless Men.',
		'Winter is here, and so is the suspense. Stay vigilant!',
		'In the realm of competitions, the Seven Kingdoms await a ruler.',
		'Like Jon Snow, the standings know nothing. Stay vigilant!',
		'The leaderboard is waiting for the right moment to strike.',
		"Winter is coming, and with it, the leaderboard's return.",
		'The North remembers, and so will you when the results are revealed.',
		'In the world of challenges, who will emerge victorious?',
		"The standings are paused, like Bran Stark's visions. Anticipate the outcome.",
		'The leaderboard is hidden, but not for long. Prepare for the reveal.',
		'Prepare for the long night. The standings are in the crypts, ready to rise!',
		'The suspense is as thick as the Wall. Who will conquer it?',
		"While the Seven Kingdoms await their ruler, we await the leaderboard's verdict.",
		'The night is dark and full of suspense. Winter is here, and so are the final results!',
		'Êl síla erin lû e-govaned vîn',
	];
	return winterIsHere[Math.floor(Math.random() * winterIsHere.length)];
};

export { getPublicStandings, getInternalStandings, getFrozenCatchyMessages };
