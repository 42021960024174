import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import ProblemTitleCell from './itemComponents/ProblemTitleCell';

export default function RenderProblemTitle(props: GridRenderCellParams, contestSlug: string) {
	const {
		value,
		row: {
			problem_set: { order_character: orderCharacter },
		},
	} = props;

	return <ProblemTitleCell contestSlug={contestSlug} orderCharacter={orderCharacter} problemTitle={value.title} />;
}
