import React from 'react';
import { Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

export default function ProblemTitleCell({
	contestSlug,
	orderCharacter,
	problemTitle,
}: {
	contestSlug: string;
	orderCharacter: string;
	problemTitle: string;
}) {
	return (
		<Link
			to={`/contests/${contestSlug}/problems/${orderCharacter}`}
			component={NavLink}
		>{`${orderCharacter}. ${problemTitle}`}</Link>
	);
}
