export interface Clarification {
	id: number;
	question: string;
	answer: string;
	clarification_type: ClarificationType;
	problem?: ProblemBasicMeta;
	problem_set?: ProblemSetBasicMeta;
	contest: number;
	status: ClarificationStatus;
	created_by: ClarificationUser | null;
	answered_by: ClarificationUser | null;
	created_at: Date;
	updated_at: Date;
}

export interface ClarificationUser {
	full_name: string;
	username: string;
}

export interface ClarificationFormData {
	id?: number;
	question: string;
	answer: string;
	clarification_type: ClarificationType;
	problem_set: string | null;
	contest: number;
	status: ClarificationStatus;
}

type ProblemBasicMeta = {
	id: number;
	title: string;
};

type ProblemSetBasicMeta = {
	id: number;
	contest_id: number;
	contest_slug: string;
	order_character: string;
};

export const CLARIFICATION_STATUS_ENUMS = {
	ANSWERED: 'ANSWERED',
	PENDING: 'PENDING',
	IGNORED: 'IGNORED',
};
export const ClarificationStatuses = Object.keys(CLARIFICATION_STATUS_ENUMS) as ClarificationStatus[];
export type ClarificationStatus = keyof typeof CLARIFICATION_STATUS_ENUMS;
export const ClarificationStatusLabels = {
	[CLARIFICATION_STATUS_ENUMS.ANSWERED]: 'Answered',
	[CLARIFICATION_STATUS_ENUMS.PENDING]: 'Pending',
	[CLARIFICATION_STATUS_ENUMS.IGNORED]: 'Ignored',
};
export const CLARIFICATION_TYPE_ENUMS = {
	CONTEST: 'CONTEST',
	PROBLEM: 'PROBLEM',
	ANNOUNCEMENT: 'ANNOUNCEMENT',
};

export const ClarificationTypes = Object.keys(CLARIFICATION_TYPE_ENUMS) as ClarificationType[];
export type ClarificationType = keyof typeof CLARIFICATION_TYPE_ENUMS;
export const ClarificationTypeLabels = {
	[CLARIFICATION_TYPE_ENUMS.CONTEST]: 'Contest',
	[CLARIFICATION_TYPE_ENUMS.PROBLEM]: 'Problem',
	[CLARIFICATION_TYPE_ENUMS.ANNOUNCEMENT]: 'Announcement',
};

export interface PaginatedClarifications {
	count: number;
	next: string | null;
	previous: string | null;
	announcements: Clarification[];
	results: Clarification[];
}

export type ClarificationsParams = {
	offset?: number;
	limit?: number;
	contest?: number;
	problem_set?: number | null;
	clarification_type?: string | null;
	status?: string | null;
};
