import React from 'react';
import { Chip } from '@mui/material';
import {
	SUBMISSION_RESPONSE_ENUMS,
	SUBMISSION_RUN_STATUS_ENUMS,
	SUBMISSION_STATUS_ENUMS,
	SubmissionResponse,
	SubmissionResponseLabels,
	SubmissionResponses,
	SubmissionRunStatus,
	SubmissionRunStatuses,
	SubmissionRunStatusLabels,
	SubmissionStatus,
	SubmissionStatuses,
	SubmissionStatusLabels,
} from '../../core/submissions/types';

export default function VerdictChip({
	status,
	verdict,
	type = 'FINAL',
}: {
	status: string;
	verdict: string;
	type?: 'FINAL' | 'SINGLE_RUN';
}) {
	const isJudged = [SUBMISSION_STATUS_ENUMS.JUDGED, SUBMISSION_RUN_STATUS_ENUMS.DONE].includes(status);
	const isAccepted = verdict === SUBMISSION_RESPONSE_ENUMS.ACCEPTED;
	const isProcessing = !isJudged;

	const isKnownError: boolean =
		SubmissionResponses.includes(verdict as SubmissionResponse) ||
		SubmissionStatuses.includes(status as SubmissionStatus) ||
		SubmissionRunStatuses.includes(status as SubmissionRunStatus);

	const label: string = isKnownError
		? isJudged
			? SubmissionResponseLabels[verdict]
			: type === 'FINAL'
			? SubmissionStatusLabels[status]
			: SubmissionRunStatusLabels[status]
		: status || verdict;

	return (
		<>
			<Chip
				label={label}
				color={isAccepted ? 'success' : isProcessing ? 'info' : 'error'}
				variant={isProcessing ? 'outlined' : 'filled'}
				size={'small'}
			/>
		</>
	);
}
