import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import VerdictChip from '../VerdictChip';

export default function RenderVerdict(props: GridRenderCellParams) {
	const {
		value,
		row: { status },
	} = props;

	return <VerdictChip status={status} verdict={value} />;
}
