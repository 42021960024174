import serverApi from '../serverApi';
import { Dataset, DatasetSaveBody } from './types';
import { problemsEndpoint } from '../problems/services';
const problemDatasetEndpoint = '/api/judge/test-cases';

const getDatasets = async (problemId: number) => {
	return (await serverApi.get(`${problemsEndpoint}/${problemId}/test-cases/`)).data as Dataset[];
};

const getContestDatasets = async (problemId: number, problemSetId: number) => {
	return (
		await serverApi.get(`${problemsEndpoint}/${problemId}/test-cases/`, { params: { problem_set_id: problemSetId } })
	).data as Dataset[];
};

const createNewDataset = async (problemId: number, dataset: DatasetSaveBody) => {
	return (
		await serverApi.post(
			`${problemDatasetEndpoint}/`,
			{ problem: problemId, ...dataset },
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		)
	).data as Dataset;
};

const createNewContestDataset = async (problemId: number, problemSetId: number, dataset: DatasetSaveBody) => {
	return (
		await serverApi.post(
			`${problemDatasetEndpoint}/`,
			{ problem: problemId, ...dataset },
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				params: {
					problem_set_id: problemSetId,
				},
			},
		)
	).data as Dataset;
};

// currently backend doesn't support changing the input/output files
const editDataset = async (datasetId: number, dataset: Partial<DatasetSaveBody>) => {
	return (
		await serverApi.patch(`${problemDatasetEndpoint}/${datasetId}/`, dataset, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	).data as Dataset;
};

const editContestDataset = async (datasetId: number, problemSetId: number, dataset: Partial<DatasetSaveBody>) => {
	return (
		await serverApi.patch(`${problemDatasetEndpoint}/${datasetId}/`, dataset, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			params: {
				problem_set_id: problemSetId,
			},
		})
	).data as Dataset;
};

const deleteDataset = async (datasetId: number) => {
	await serverApi.delete(`${problemDatasetEndpoint}/${datasetId}/`);
};

const deleteContestDataset = async (datasetId: number, problemSetId: number) => {
	await serverApi.delete(`${problemDatasetEndpoint}/${datasetId}/`, { params: { problem_set_id: problemSetId } });
};

export {
	getDatasets,
	getContestDatasets,
	createNewDataset,
	createNewContestDataset,
	editDataset,
	editContestDataset,
	deleteDataset,
	deleteContestDataset,
};
