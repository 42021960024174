import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';

export default function MarkdownView({ data }: { data: string }) {
	return (
		<MarkdownPreview
			source={data}
			style={{ width: '100%' }}
			wrapperElement={{
				'data-color-mode': 'light',
			}}
			rehypeRewrite={(node, index, parent) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if (node.tagName === 'a' && parent && /^h(1|2|3|4|5|6)/.test(parent.tagName as string)) {
					parent.children = parent.children.slice(1);
				}
			}}
		/>
	);
}
