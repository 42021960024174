import React from 'react';
import { Grid, IconButton, List, ListItemButton, Pagination, Tooltip, Typography } from '@mui/material';
import { ClarificationItem } from './ClarificationItem';
import { SectionTitle } from '../../../../../design-library';
import { Clarification } from '../../../../../core/clarifications/types';
import CreateNewClarificationModal from './CreateNewClarificationModal';
import { LinearLoader } from '../../../../../design-library/LinearLoader';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';

export default function ClarificationList({
	isLoading,
	contestId,
	canEdit,
	clarifications,
	setClarifications,
	page,
	totalPages,
	onPageChangeHandler,
	isContestManager,
	onReloadPage,
}: {
	isLoading: boolean;
	contestId: number;
	canEdit: boolean;
	clarifications: Clarification[];
	setClarifications: (data: Clarification[]) => void;
	page: number;
	totalPages: number;
	onPageChangeHandler: (event: React.ChangeEvent<unknown>, value: number) => Promise<void>;
	isContestManager: boolean;
	onReloadPage: () => void;
}) {
	const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);

	const handleCreateClarificationSuccess = (clarification: Clarification) => {
		setClarifications([clarification, ...clarifications]);
	};

	return (
		<Grid container direction='row'>
			<Grid item xs={12}>
				<SectionTitle
					title={'Clarifications'}
					size={'small'}
					actions={
						<Grid container spacing={2} alignItems={'center'} alignContent={'center'}>
							<Grid item>
								<Tooltip title={'Create new clarification'}>
									<IconButton onClick={() => setIsAddModalOpen(true)} color='primary'>
										<AddIcon />
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item>
								<Tooltip title={'Reload list'}>
									<IconButton onClick={onReloadPage}>
										<CachedIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					}
				/>
				{(isLoading || !clarifications) && <LinearLoader />}{' '}
				{clarifications && clarifications.length !== 0 ? (
					<List>
						{clarifications.map((clarification) => (
							<ListItemButton
								key={clarification.id}
								sx={{ border: 1, margin: '2px 0px', borderColor: 'rgba(0, 0, 0, 0.07)' }}
							>
								<ClarificationItem
									canEdit={canEdit}
									clarification={clarification}
									isContestManager={isContestManager}
								/>
							</ListItemButton>
						))}
					</List>
				) : (
					<Grid item>
						<Typography variant={'h6'} color={'primary'} sx={{ padding: '12px' }}>
							{'No results found!'}
						</Typography>
					</Grid>
				)}
			</Grid>
			<Grid item container xs={12} direction='column' justifyContent='center' alignItems='center'>
				<Pagination
					page={page}
					count={totalPages}
					variant='outlined'
					shape='rounded'
					size='large'
					onChange={onPageChangeHandler}
					boundaryCount={1}
				/>
			</Grid>
			{isAddModalOpen && (
				<CreateNewClarificationModal
					isOpen={isAddModalOpen}
					setIsOpen={setIsAddModalOpen}
					onSuccess={handleCreateClarificationSuccess}
					contestId={contestId}
					isContestManager={isContestManager}
				/>
			)}
		</Grid>
	);
}
