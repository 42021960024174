import React from 'react';
import { InputOutputSample } from '../../../../core/problems/types';
import { editContestProblemSample } from '../../../../core/problems/services';
import SampleIOForm from './SampleIOForm';
import { Dialog } from '@mui/material';

export default function EditSampleModal({
	isOpen,
	onClose,
	sample,
	setSample,
	problemSetId,
}: {
	isOpen: boolean;
	onClose: () => void;
	sample: InputOutputSample;
	setSample: (sample: InputOutputSample) => void;
	problemSetId: number;
}) {
	const handleSubmit = async (data: InputOutputSample) => {
		const updatedSample = await editContestProblemSample(sample.id!, data, problemSetId);
		setSample(updatedSample);
		return updatedSample;
	};

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'md'}>
			<SampleIOForm type='edit' initFormData={sample} handleSubmit={handleSubmit} onSuccess={onClose} />
		</Dialog>
	);
}
