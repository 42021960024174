import React from 'react';
import { Grid, List, ListItemButton } from '@mui/material';
import { SectionTitle } from '../../../../design-library';
import { ProblemMetaDataItem } from './ProblemMetaDataItem';
import { ProblemSetMeta } from '../../../../core/contests/types';
import { Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { UserRole } from '../../../../core/user/types';

export default function ProblemList({
	contestSlug,
	problemSet,
	userRole,
}: {
	contestSlug: string;
	problemSet: ProblemSetMeta[];
	userRole: UserRole;
}) {
	return (
		<Grid container direction='column'>
			<SectionTitle title={'Problems'} size={'small'} />
			<List>
				{problemSet.map((problem) => (
					<Link
						key={problem.slug}
						to={`/contests/${contestSlug}/problems/${problem.problem_order_character!}`}
						underline={'none'}
						component={NavLink}
					>
						<ListItemButton
							sx={{
								border: 1,
								margin: '2px 0px',
								borderColor: 'rgba(0, 0, 0, 0.07)',
							}}
						>
							<ProblemMetaDataItem key={problem.slug} problem={problem} userRole={userRole} />
						</ListItemButton>
					</Link>
				))}
			</List>
		</Grid>
	);
}
