import React from 'react';
import SampleIOForm from './SampleIOForm';
import { InputOutputSample } from '../../../../core/problems/types';
import { createNewContestProblemSample } from '../../../../core/problems/services';
import { Dialog, DialogContent } from '@mui/material';

export default function CreateSampleModal({
	isOpen,
	onClose,
	problemId,
	onSuccess,
	problemSetId,
}: {
	isOpen: boolean;
	onClose: () => void;
	problemId: number;
	onSuccess: (sample: InputOutputSample) => void;
	problemSetId: number;
}) {
	const initFormData: InputOutputSample = {
		title: '',
		sample_input: '',
		sample_output: '',
	};

	const handleSubmit = async (data: InputOutputSample) => {
		const newSample = await createNewContestProblemSample(problemId, data, problemSetId);
		onSuccess(newSample);
		return newSample;
	};

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'md'}>
			<DialogContent sx={{ backgroundColor: 'white' }}>
				<SampleIOForm
					type='create'
					initFormData={initFormData}
					handleSubmit={handleSubmit}
					onSuccess={() => {
						onClose();
					}}
				/>
			</DialogContent>
		</Dialog>
	);
}
