import React from 'react';
import { Button } from '@mui/material';
import SyncProblemDialog from './SyncProblemDialog';

export function SyncProblemButton({ problemId }: { problemId: number }) {
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Button variant='outlined' color='primary' onClick={() => setOpen(true)}>
				Sync
			</Button>
			<SyncProblemDialog open={open} setOpen={setOpen} problemId={problemId} />
		</>
	);
}
