import { Card, Typography } from '@mui/material';
import React from 'react';
import { TestDetailsHeaderStyle } from '../styles';

function TestDetailsHeader() {
	return (
		<Card variant={'outlined'} raised={true} style={TestDetailsHeaderStyle}>
			<Typography variant={'subtitle1'}>Runs</Typography>
		</Card>
	);
}

export default TestDetailsHeader;
