import React, { useEffect, useState } from 'react';
import {
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import { SectionPaper, SectionTitle } from '../../../../design-library';
import { ProblemLanguage } from '../../../../core/languages/types';
import { getLanguages } from '../../../../core/languages/services';
import { acknowledgeSubmission, createSubmission } from '../../../../core/submissions/services';
import { postPreSignedURL } from '../../../../core/commons/externalApi';
import { enqueueSnackbar } from 'notistack';
import _ from 'lodash';

export default function ProblemSubmitSection({
	problemSetId,
	setNewSubmissionId,
}: {
	problemSetId: number;
	setNewSubmissionId: (id: number) => void;
}) {
	const [submissionStatus, setSubmissionStatus] = React.useState<'IDLE' | 'SUBMITTING'>('IDLE');
	const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
	const [selectedFileName, setSelectedFileName] = React.useState<string | null>(null);
	const [selectedLanguage, setSelectedLanguage] = React.useState<string>('1');
	const [problemLanguages, setProblemLanguages] = React.useState<ProblemLanguage[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedFilePath, setSelectedFilePath] = useState<string>('');

	const allowedFileTypes = ['c', 'cpp', 'c++', 'cc', 'java', 'py'];
	const allowedFileExtensions = ['.c', '.cpp', '.c++', '.cc', '.java', '.py'];

	useEffect(() => {
		const confirmExit = (e: BeforeUnloadEvent) => {
			if (submissionStatus === 'SUBMITTING') {
				e.returnValue = 'Are you sure you want to leave? Your submission is still uploading!';
				return 'Are you sure you want to leave?';
			}
		};

		window.addEventListener('beforeunload', confirmExit);

		return () => {
			window.removeEventListener('beforeunload', confirmExit);
		};
	}, [submissionStatus]);

	const handleSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			setSelectedFile(event.target.files[0] ?? null);
			setSelectedFileName(event.target.files[0]?.name ?? null);
			setSelectedFilePath(event.target.value);
		}
	};

	const handleLanguageSelect = (event: SelectChangeEvent<string | null>) => {
		setSelectedLanguage(event.target.value as string);
	};

	useEffect(() => {
		getLanguages().then((data) => {
			setProblemLanguages(data);
		});
	}, []);

	const isInvalidFile = (): boolean => {
		if (!selectedFileName || selectedFileName?.length === 0) return true;

		const fileExtension = selectedFileName.split('.').pop() ?? null;
		return !allowedFileTypes.includes(fileExtension?.toLowerCase() ?? '');
	};

	const handleSolutionSubmit = () => {
		setIsLoading(true);

		if (isInvalidFile()) {
			enqueueSnackbar('Invalid file type!', {
				autoHideDuration: 3000,
				variant: 'error',
			});
			setIsLoading(false);
			return;
		}

		let submissionId = 0;
		setSubmissionStatus('SUBMITTING');
		createSubmission({
			language: selectedLanguage,
			problem_set: problemSetId,
		})
			.then((data) => {
				submissionId = data.id;
				const presignedUrl = data.presigned_post_url;
				const fields = presignedUrl.fields;
				const url = presignedUrl.url;
				return postPreSignedURL(url, fields, selectedFile);
			})
			.catch((error) => {
				console.log('An error occurred:', error);
				enqueueSnackbar('Something went wrong! Please reload page and try again!', { variant: 'error' });
			})
			.finally(() => {
				acknowledgeSubmission(submissionId)
					.then(() => {
						setNewSubmissionId(submissionId);
						enqueueSnackbar('Submission successful!', { variant: 'success' });
						return Promise.resolve();
					})
					.catch((error) => {
						console.log('An error occurred:', error);
						enqueueSnackbar("Submission wasn't successful! Please try again!", {
							variant: 'error',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
						});
					})
					.finally(() => {
						setIsLoading(false);
						setSubmissionStatus('IDLE');
						setSelectedFileName(null);
						setSelectedFile(null);
						setSelectedFilePath('');
					});
			});
	};

	const handleDebouncedSolutionSubmit = _.debounce(handleSolutionSubmit, 3000, { leading: true, trailing: false });

	return (
		<Grid container direction='column' rowSpacing='2px'>
			<Grid item>
				<SectionTitle title='Submit' size='small' />
			</Grid>
			<Grid item>
				<SectionPaper>
					<Grid container direction='column' rowSpacing={2}>
						<Grid item>
							<FormControl sx={{ width: '100%' }}>
								<InputLabel id='select-language-label'>Language</InputLabel>
								<Select
									labelId='select-language-label'
									id='select-language'
									value={selectedLanguage}
									label='Language'
									onChange={handleLanguageSelect}
									size='small'
								>
									{Object.values(problemLanguages).map((problemLanguage: ProblemLanguage) => (
										<MenuItem key={problemLanguage.id} value={problemLanguage.id}>
											<Grid container direction={'column'}>
												<Grid item>{problemLanguage.name}</Grid>
												<Grid item>
													<Typography sx={{ fontSize: '12px' }}>{problemLanguage.compiler_flags}</Typography>
												</Grid>
											</Grid>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item container direction='row' alignItems='center'>
							<Grid item xs>
								<Button
									variant={'outlined'}
									component='label'
									size='medium'
									color={selectedFileName ? 'info' : 'primary'}
									fullWidth
								>
									{selectedFileName ?? 'Select Source'}
									<input
										type='file'
										value={selectedFilePath}
										hidden
										onChange={handleSelectedFile}
										accept={`${allowedFileExtensions}`}
									/>
								</Button>
							</Grid>
						</Grid>
						<Grid item>
							<Button
								disabled={isLoading || !selectedFile || !selectedLanguage}
								variant='contained'
								fullWidth
								onClick={handleDebouncedSolutionSubmit}
							>
								{isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Submit'}
							</Button>
						</Grid>
					</Grid>
				</SectionPaper>
			</Grid>
		</Grid>
	);
}
