import React, { useState } from 'react';
import { Grid, TextField, Button, CircularProgress, FormHelperText } from '@mui/material';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { SectionPaper, SectionTitle } from '../../../../design-library';
import { Dataset, DatasetSaveBody } from '../../../../core/datasets/types';
import _ from 'lodash';

export default function DatasetForm({
	type,
	initFormData,
	handleSubmit,
	onSuccess,
}: {
	type: 'create' | 'edit';
	initFormData: Dataset;
	handleSubmit: (data: DatasetSaveBody) => Promise<Dataset>;
	onSuccess: () => void;
}) {
	const { enqueueSnackbar } = useSnackbar();
	const [formData, setFormData] = useState<DatasetSaveBody & Dataset>(initFormData);
	const [errorData, setErrorData] = useState<{ [key in keyof DatasetSaveBody]?: string[] }>({});
	const [isLoading, setIsLoading] = useState(false);
	const [fileNames, setFileNames] = useState<{ input_file_name: string | null; output_file_name: string | null }>({
		input_file_name: null,
		output_file_name: null,
	});

	const handleSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, files } = event.target;
		if (files) {
			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: files[0] ?? undefined,
			}));
			setFileNames((prevFileNames) => ({
				...prevFileNames,
				[`${name}_name`]: files[0]?.name ?? null,
			}));
		}
	};

	const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const onSubmitHandler = async () => {
		try {
			setIsLoading(true);
			const response = await handleSubmit(
				_.pick(formData, type === 'edit' ? ['title'] : ['title', 'input_file', 'output_file']),
			);
			enqueueSnackbar(`Dataset "${response.title}" ${type === 'create' ? 'created' : 'edited'} successfully!`, {
				variant: 'success',
			});
			onSuccess();
		} catch (e) {
			if (axios.isAxiosError(e)) {
				setErrorData(e.response?.data ?? {});
				enqueueSnackbar(`Dataset could not be ${type === 'create' ? 'created' : 'edited'}!`, { variant: 'error' });
			} else {
				enqueueSnackbar(`Something went wrong!`, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Grid container alignItems='center' justifyContent='center' sx={{ width: '100%' }}>
			<Grid item container direction='row' xs={12} rowSpacing={'2px'}>
				<Grid item xs={12}>
					<SectionTitle title={`${type === 'create' ? 'Create new' : 'Edit'} Dataset`} size='small' />
				</Grid>
				<Grid item xs={12}>
					<SectionPaper>
						<Grid
							container
							direction='column'
							justifyContent='center'
							sx={{ width: '100%', padding: '35px', gap: 2 }}
							component={'form'}
						>
							<TextField
								id='sample-title'
								name='title'
								label='Title'
								variant='outlined'
								value={formData.title}
								onChange={handleValueChange}
								error={!!errorData.title}
								helperText={errorData.title}
							/>
							<Button
								fullWidth
								disabled={type === 'edit'}
								variant={'outlined'}
								component='label'
								color={fileNames.input_file_name ? 'info' : 'primary'}
							>
								{fileNames.input_file_name ?? 'Select Input File'}
								<input type='file' name='input_file' hidden onChange={handleSelectedFile} />
							</Button>
							{type === 'edit' && (
								<FormHelperText error={true}>
									Input update not available now! Please remove and re-upload.
								</FormHelperText>
							)}
							<FormHelperText error={!!errorData.input_file}>{errorData.input_file}</FormHelperText>
							<Button
								fullWidth
								disabled={type === 'edit'}
								variant={'outlined'}
								component='label'
								color={fileNames.output_file_name ? 'info' : 'primary'}
							>
								{fileNames.output_file_name ?? 'Select Output File'}
								<input type='file' name='output_file' hidden onChange={handleSelectedFile} />
							</Button>
							{type === 'edit' && (
								<FormHelperText error={true}>
									Output update not available now! Please remove and re-upload.
								</FormHelperText>
							)}
							<FormHelperText error={!!errorData.output_file}>{errorData.output_file}</FormHelperText>

							<Button disabled={isLoading} variant='contained' onClick={onSubmitHandler} size='large'>
								{isLoading ? <CircularProgress size={25} /> : `${type === 'create' ? 'Create' : 'Edit'} Dataset`}
							</Button>
						</Grid>
					</SectionPaper>
				</Grid>
			</Grid>
		</Grid>
	);
}
