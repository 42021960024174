import React, { useEffect, useState } from 'react';
import { LinkStyle, SourceLinkDivStyle } from '../styles';
import { Card, Dialog, DialogContent, Typography } from '@mui/material';
import ManualJudgeModal from './ManualJudgeModal';
import RejudgeSubmission from './RejudgeSubmission';
import CodeFetchAndShow from '../../../../common/CodeFetchAndShow';
import { ContestRead } from '../../../../../core/contests/types';
import { getContestData } from '../../../../../core/contests/services';
import { getLanguageIdentifier } from '../../../../../core/languages/services';
import { useCurrentUser } from '../../../../../hooks/useCurrentUser';
import { Submission } from '../../../../../core/submissions/types';
import RequeueSubmission from './RequeueSubmission';

function SubmissionActions({ submission, fetchSubmission }: { submission: Submission; fetchSubmission: () => void }) {
	const currentUser = useCurrentUser();
	const [showManualJudgeModal, setShowManualJudgeModal] = useState(false);
	const [showSourceModal, setShowSourceModal] = useState(false);

	const contestSlug = submission.problem_set.contest_slug;
	const [contest, setContest] = useState<ContestRead | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchContest = async () => {
			setIsLoading(true);
			const contestData = await getContestData(contestSlug);
			setContest(contestData);
			setIsLoading(false);
		};
		fetchContest();
	}, [contestSlug]);

	if (isLoading || !contest) return <></>;

	const isOwnSubmission = submission && submission.submitted_by.username === currentUser?.username;
	const canEdit = ['STAFF', 'JUDGE'].includes(contest.role);

	return (
		<Card style={SourceLinkDivStyle}>
			{canEdit && <RejudgeSubmission onSuccess={fetchSubmission} />}
			{canEdit && <RequeueSubmission onSuccess={fetchSubmission} />}
			{canEdit && (
				<span
					onClick={() => {
						setShowManualJudgeModal(true);
					}}
					style={LinkStyle}
				>
					Manual
				</span>
			)}
			{(canEdit || isOwnSubmission) && (
				<span
					onClick={() => {
						setShowSourceModal(true);
					}}
					style={LinkStyle}
				>
					Source
				</span>
			)}

			<ManualJudgeModal
				currentVerdict={submission.response}
				open={showManualJudgeModal}
				onClose={() => setShowManualJudgeModal(false)}
			/>
			<Dialog open={showSourceModal} onClose={() => setShowSourceModal(false)} fullWidth={true} maxWidth={'md'}>
				<DialogContent>
					<Typography>
						<a href={submission?.source_url} rel='noreferrer' target='_blank' download>
							Download source file
						</a>
					</Typography>
					<CodeFetchAndShow url={submission.source_url ?? ''} language={getLanguageIdentifier(submission.language)} />
				</DialogContent>
			</Dialog>
		</Card>
	);
}

export default SubmissionActions;
