import React from 'react';
import { TestDetailsStyle } from './styles';
import TestDetailsHeader from './TestDetails/TestDetailsHeader';
import TestDetailsTable from './TestDetails/TestDetailsTable';
import { TestDetail } from '../../../../core/submissions/types';

interface Props {
	contestSlug: string;
	testsDetails: TestDetail[];
}

function TestsDetails({ contestSlug, testsDetails }: Props) {
	return (
		<div style={TestDetailsStyle}>
			<TestDetailsHeader />
			<TestDetailsTable contestSlug={contestSlug} testsDetails={testsDetails} />
		</div>
	);
}

export default TestsDetails;
