import React from 'react';
import { Typography, Grid, Tooltip, Chip } from '@mui/material';
import { Clarification, CLARIFICATION_TYPE_ENUMS } from '../../../../../core/clarifications/types';
import _ from 'lodash';
import EditClarificationModal from './EditClarificationModal';
import ClarificationView from './ClarificationView';

type ClarificationItemProps = {
	canEdit: boolean;
	clarification: Clarification;
	isContestManager: boolean;
};

export function ClarificationItem({ canEdit, clarification, isContestManager }: ClarificationItemProps) {
	const [isViewModalOpen, setIsViewModalOpen] = React.useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
	const [clarificationViewData, setClarificationViewData] = React.useState<Clarification>(clarification);

	const handleEditClarificationSuccess = (clarification: Clarification) => {
		setClarificationViewData(clarification);
	};
	const isAnnouncement = clarificationViewData.clarification_type === CLARIFICATION_TYPE_ENUMS.ANNOUNCEMENT;
	const questionColor = isAnnouncement ? '#b63f00' : 'primary';
	const answerColor = isAnnouncement ? '#930000' : '#005b93';

	return (
		<Grid container alignItems='start'>
			<Grid item xs={11} container justifyContent='space-between' onClick={() => setIsViewModalOpen(true)}>
				<Grid item xs={12}>
					<Tooltip title={clarificationViewData.question}>
						<Typography
							variant='h6'
							color={questionColor}
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								display: '-webkit-box',
								WebkitLineClamp: '1',
								WebkitBoxOrient: 'vertical',
							}}
						>
							{clarificationViewData.clarification_type === 'PROBLEM'
								? `[${clarificationViewData.problem_set!.order_character}. ${clarificationViewData.problem!.title}] `
								: `[${_.capitalize(clarification.clarification_type)}] `}
							{clarificationViewData.question}
						</Typography>
					</Tooltip>
				</Grid>
				<Grid item xs={12}>
					<Typography
						variant='subtitle2'
						color={answerColor}
						sx={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: '-webkit-box',
							WebkitLineClamp: '2',
							WebkitBoxOrient: 'vertical',
						}}
					>
						{clarificationViewData.answer}
					</Typography>
				</Grid>
			</Grid>
			<Grid item container xs={1} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
				<Grid item>
					{(!isAnnouncement || isContestManager) && (
						<Chip
							{...(canEdit && { onClick: () => setIsEditModalOpen(true) })}
							label={_.capitalize(clarificationViewData.status)}
							color={
								clarificationViewData.status === 'ANSWERED'
									? 'success'
									: clarificationViewData.status === 'PENDING'
									? 'info'
									: 'warning'
							}
							variant={'filled'}
							size={'small'}
						/>
					)}
				</Grid>
			</Grid>
			{isViewModalOpen && (
				<ClarificationView
					isOpen={isViewModalOpen}
					setIsOpen={setIsViewModalOpen}
					clarification={clarificationViewData}
				/>
			)}
			{isEditModalOpen && (
				<EditClarificationModal
					isOpen={isEditModalOpen}
					setIsOpen={setIsEditModalOpen}
					onSuccess={handleEditClarificationSuccess}
					clarificationId={clarificationViewData.id}
					isContestManager={canEdit}
				/>
			)}
		</Grid>
	);
}
