import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { getSectionPaperStyle } from './_styles';
import { ComponentSize } from '../types';
import { SectionColor } from './types';

export function SectionPaper({ children, size = 'medium', color = 'none' }: SectionPaperProps) {
	return <Box sx={{ ...getSectionPaperStyle(size, color) }}>{children}</Box>;
}

type SectionPaperProps = {
	children: ReactNode;
	size?: ComponentSize;
	color?: SectionColor;
};
