import * as React from 'react';
import { Typography, Toolbar, Box, AppBar, Grid, Container, Button, Link, IconButton, Drawer } from '@mui/material';
import { Logo } from './BrandLogo';
import { NavLink, useLocation } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';

type NavItem = {
	id: string;
	label: string;
	link: string;
};

const adminLinks: NavItem[] = [
	{
		id: 'admin',
		label: 'Admin',
		link: '/admin',
	},
];

const getContestViewLinks = (contestSlug: string): NavItem[] => {
	return [
		{
			id: 'contest-dashboard',
			label: 'Dashboard',
			link: '/contests/' + contestSlug,
		},
		{
			id: 'contest-clarifications',
			label: 'Clarifications',
			link: '/contests/' + contestSlug + '/clarifications',
		},
		{
			id: 'contest-submissions',
			label: 'Submissions',
			link: '/contests/' + contestSlug + '/submissions',
		},
		{
			id: 'contest-standings',
			label: 'Standings',
			link: '/contests/' + contestSlug + '/standings',
		},
		{
			id: 'contest-notes',
			label: 'Notes',
			link: '/contests/' + contestSlug + '/notes',
		},
	];
};
const publicPages: NavItem[] = [
	{
		id: 'contests',
		label: 'Contests',
		link: '/contests',
	},
];
// const settings = ['Profile'];
const authPages: NavItem[] = [
	{
		id: 'login',
		label: 'Login',
		link: '/login',
	},
];

export function AppToolbar() {
	const currentUser = useCurrentUser();
	const location = useLocation();
	const contestSlug = location.pathname.match(/\/contests\/([^/]+)/)?.[1];
	const showContestNavs = contestSlug !== undefined;
	const isAdminRoute = location.pathname.match(/\/admin.*/)?.[0] !== undefined;
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const fullName = (currentUser?.first_name + ' ' + currentUser?.last_name).trim();
	const displayName = fullName || currentUser?.username;

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	return (
		<AppBar position='sticky' color='default'>
			<Container maxWidth='xl'>
				<Toolbar disableGutters>
					{contestSlug && (
						<IconButton
							edge='end'
							color='inherit'
							aria-label='menu'
							onClick={toggleDrawer}
							sx={{ display: { xs: 'flex', md: 'none' } }} // Show on xs screens
						>
							<MenuIcon />
						</IconButton>
					)}
					<Link key='band-logo' to='/' underline='none' component={NavLink}>
						<Grid container direction='row' alignItems='center'>
							<Logo />
							<Typography
								variant='h5'
								noWrap
								sx={{
									mr: 2,
									display: { xs: 'flex', md: 'none' },
									flexGrow: 1,
									fontFamily: 'monospace',
									fontWeight: 700,
									letterSpacing: '.3rem',
									color: 'inherit',
									textDecoration: 'none',
								}}
							>
								BAPS
							</Typography>
						</Grid>
					</Link>

					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						{publicPages.map((page) => (
							<Link key={page.id} to={page.link} underline='none' component={NavLink}>
								<Button sx={{ my: 2, display: 'block' }}>{page.label}</Button>
							</Link>
						))}
					</Box>

					{!isAdminRoute && showContestNavs && (
						<Box sx={{ flexGrow: 4, display: { xs: 'none', md: 'flex' } }}>
							{getContestViewLinks(contestSlug).map((page) => (
								<Link key={page.id} to={page.link} underline='none' component={NavLink}>
									<Button sx={{ my: 2, display: 'block' }}>{page.label}</Button>
								</Link>
							))}
						</Box>
					)}

					{!isAdminRoute && showContestNavs && (
						<Drawer anchor='bottom' open={isDrawerOpen} onClose={toggleDrawer}>
							<Grid container direction={'row'} justifyContent={'center'}>
								{getContestViewLinks(contestSlug).map((page) => (
									<Grid item key={page.id}>
										<Link to={page.link} underline='none' component={NavLink}>
											<Button sx={{ my: 2, display: 'block' }}>{page.label}</Button>
										</Link>
									</Grid>
								))}
							</Grid>
						</Drawer>
					)}

					<Box sx={{ flexGrow: 1 }} component='span' />
					{!currentUser && (
						<Box sx={{ flexGrow: 0, display: { xs: 'flex' } }}>
							{authPages.map((page) => (
								<Link key={page.id} to={page.link} underline='none' component={NavLink}>
									<Button sx={{ my: 2, display: 'block' }}>{page.label}</Button>
								</Link>
							))}
						</Box>
					)}
					{currentUser && (currentUser.is_superuser || currentUser.is_staff) && (
						<Box sx={{ flexGrow: 0, display: { xs: 'flex' } }}>
							{adminLinks.map((page) => (
								<Link key={page.id} to={page.link} underline='none' component={NavLink}>
									<Button sx={{ my: 2, display: 'block' }}>{page.label}</Button>
								</Link>
							))}
						</Box>
					)}

					{currentUser && (
						<Box sx={{ flexGrow: 0, display: { xs: 'flex' } }}>
							<Button sx={{ my: 2, display: 'block' }}>{displayName!}</Button>
							<Link key={'logout'} to={'/logout'} underline='none' component={NavLink}>
								<Button sx={{ my: 2, display: 'block' }}>Logout</Button>
							</Link>
						</Box>
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
}
