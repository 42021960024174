import React, { useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import CodeViewer from '../../design-library/CodeViewer';
import { getFileFromUrl } from '../../core/commons/externalApi';
import { enqueueSnackbar } from 'notistack';

export default function CodeFetchAndShow({
	url,
	language,
}: {
	url: string;
	language: 'c' | 'cpp' | 'py2' | 'py3' | 'java' | 'text';
}) {
	const [fileContent, setFileContent] = React.useState<string>('');
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		getFileFromUrl(url)
			.then((data) => {
				setFileContent(data);
			})
			.catch(() => {
				enqueueSnackbar('Some error occurred. Please reload the page', { variant: 'error' });
			})
			.finally(() => setIsLoading(false));
	}, []);

	return (
		<Grid container justifyContent={'center'}>
			{isLoading ? (
				<Grid item>
					{' '}
					<CircularProgress />
				</Grid>
			) : (
				<Grid item sx={{ width: '100%' }}>
					<CodeViewer language={language} value={fileContent || ''} />
				</Grid>
			)}
		</Grid>
	);
}
