import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ContestRead } from '../../core/contests/types';
import CountdownTimer from '../../design-library/CountdownTimer/CountdownTimer';
import { USER_ROLE_ENUMS } from '../../core/user/types';

export default function ContestNotStartedPage({ contest }: { contest: ContestRead }) {
	const isContestantOrJudge = [USER_ROLE_ENUMS.CONTESTANT, USER_ROLE_ENUMS.JUDGE].includes(contest.role);
	return (
		<Grid
			sx={{
				height: '70vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Box display={'flex'} flexDirection='column' alignItems='center' mb={3}>
				<Typography align={'center'} variant={'h2'} mb={1} color={'primary'}>
					{contest.title}
				</Typography>
				<Box>
					<CountdownTimer starts_at={contest.starts_at} stops_at={contest.ends_at} />
				</Box>
			</Box>
			<Typography variant='h4' align='center' color={'secondary'}>
				Contest has not started yet
			</Typography>
			{isContestantOrJudge && (
				<Typography variant='h6' align='center' color={'primary'} sx={{ marginTop: '20px' }}>
					You are invited as a <strong>{contest.role.toLowerCase()}</strong>!
				</Typography>
			)}
		</Grid>
	);
}
