import React, { ReactElement } from 'react';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { Navigate, Outlet } from 'react-router-dom';

export default function ProtectedRoute(): ReactElement {
	const currentUser = useCurrentUser();

	if (!currentUser?.token) {
		return <Navigate to='/login' replace />;
	}

	return <Outlet />;
}
