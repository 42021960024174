import React from 'react';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { deleteContestProblemSample } from '../../../../core/problems/services';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';

export default function DeleteSampleModal({
	isOpen,
	onClose,
	sampleId,
	sampleTitle,
	onDeleteSuccess,
	problemSetId,
}: {
	isOpen: boolean;
	onClose: () => void;
	sampleId: number;
	sampleTitle: string;
	onDeleteSuccess: (sampleId: number) => void;
	problemSetId: number;
}) {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const handleDeleteConfirmation = async () => {
		setIsLoading(true);
		try {
			await deleteContestProblemSample(sampleId, problemSetId);
			enqueueSnackbar(`Sample deleted successfully!`, { variant: 'success' });
			onDeleteSuccess(sampleId);
		} catch (e) {
			if (axios.isAxiosError(e)) {
				enqueueSnackbar(`Sample could not be deleted!`, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
			onClose();
		}
	};

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Delete Sample</DialogTitle>
			<DialogContent sx={{ backgroundColor: 'white' }}>
				<DialogContentText>Are you sure you want to delete this sample {`"${sampleTitle}"`}?</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button disabled={isLoading} variant='contained' onClick={onClose}>
					Cancel
				</Button>
				<Button disabled={isLoading} variant='outlined' onClick={handleDeleteConfirmation}>
					{isLoading ? <CircularProgress size={25} /> : `Delete`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
