import React from 'react';
import { SectionPaper, SectionTitle } from '../../../../../design-library';
import { ContestRead, ProblemSetMeta } from '../../../../../core/contests/types';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import SideBarBriefAndTimer from '../../../SideBarBriefAndTimer';
import {
	CLARIFICATION_TYPE_ENUMS,
	ClarificationsParams,
	ClarificationStatuses,
	ClarificationStatusLabels,
	ClarificationTypeLabels,
	ClarificationTypes,
} from '../../../../../core/clarifications/types';

export default function ClarificationsSidebar({
	contest,
	filterParams,
	setFilterParams,
	onFilterHandler,
}: {
	contest: ContestRead;
	filterParams: ClarificationsParams;
	setFilterParams: (data: ClarificationsParams) => void;
	onFilterHandler: () => void;
}) {
	const handleProblemSelect = (event: SelectChangeEvent<string | null>) => {
		setFilterParams({
			...filterParams,
			problem_set: event.target.value === 'all' ? null : parseInt(event.target.value as string),
		});
	};

	const handleStatusSelect = (event: SelectChangeEvent<string | null>) => {
		setFilterParams({
			...filterParams,
			status: event.target.value === 'all' ? null : event.target.value,
		});
	};

	const handleTypeSelect = (event: SelectChangeEvent<string | null>) => {
		setFilterParams({
			...filterParams,
			clarification_type: event.target.value === 'all' ? null : event.target.value,
			problem_set: event.target.value === CLARIFICATION_TYPE_ENUMS.CONTEST ? null : filterParams.problem_set,
		});
	};

	return (
		<Grid container direction='column' rowSpacing={2}>
			<Grid item>
				<SideBarBriefAndTimer contest={contest} />
			</Grid>
			<Grid item container direction='column' rowSpacing='2px'>
				<Grid item>
					<SectionTitle title='Filter' size='small' />
				</Grid>
				<Grid item>
					<SectionPaper>
						<Grid container direction='column' rowSpacing={2}>
							<Grid item>
								<FormControl sx={{ width: '100%' }}>
									<InputLabel id='select-clarification-type-label'>Type</InputLabel>
									<Select
										labelId='select-clarification-type-label'
										id='select-clarification-type'
										value={filterParams.clarification_type ?? 'all'}
										label='Type'
										onChange={handleTypeSelect}
									>
										<MenuItem value={'all'}>All</MenuItem>
										{ClarificationTypes.map((type) => (
											<MenuItem key={type} value={type}>
												{ClarificationTypeLabels[type]}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							{filterParams.clarification_type !== CLARIFICATION_TYPE_ENUMS.CONTEST && (
								<Grid item>
									<FormControl sx={{ width: '100%' }}>
										<InputLabel id='select-problem-label'>Problem</InputLabel>
										<Select
											labelId='select-problem-label'
											id='select-problem'
											value={(filterParams.problem_set ?? 'all') as string}
											label='Problem'
											onChange={handleProblemSelect}
										>
											<MenuItem value={'all'}>All</MenuItem>
											{contest.problem_set.map((problem_set: ProblemSetMeta) => (
												<MenuItem key={problem_set.id} value={problem_set.id}>
													{problem_set.problem_order_character}. {problem_set.title}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							)}
							<Grid item>
								<FormControl sx={{ width: '100%' }}>
									<InputLabel id='select-clarification-status-label'>Status</InputLabel>
									<Select
										labelId='select-clarification-status-label'
										id='select-clarification-status'
										value={filterParams.status ?? 'all'}
										label='Status'
										onChange={handleStatusSelect}
									>
										<MenuItem value={'all'}>All</MenuItem>
										{ClarificationStatuses.map((status) => (
											<MenuItem key={status} value={status}>
												{ClarificationStatusLabels[status]}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item>
								<Button variant='contained' fullWidth onClick={onFilterHandler}>
									Filter
								</Button>
							</Grid>
						</Grid>
					</SectionPaper>
				</Grid>
			</Grid>
		</Grid>
	);
}
