import React, { useState } from 'react';
import { LinkStyle } from '../styles';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { requeueSubmission } from '../../../../../core/submissions/services';

function RequeueSubmission({ onSuccess }: { onSuccess: () => void }) {
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { submissionId } = useParams();
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

	const handleRequeueSubmission = () => {
		setLoading(true);
		requeueSubmission(submissionId!)
			.then(() => {
				enqueueSnackbar('Submission queued for re-queued successfully!', {
					autoHideDuration: 3000,
					variant: 'success',
				});
				setIsConfirmationModalOpen(false);
				onSuccess();
			})
			.catch((error: Error) => {
				console.log(error);
				enqueueSnackbar('Error re-queueing submission!', {
					autoHideDuration: 3000,
					variant: 'error',
				});
			});
		setLoading(false);
	};

	return (
		<div>
			{loading ? (
				<CircularProgress size={24} />
			) : (
				<span onClick={() => setIsConfirmationModalOpen(true)} style={LinkStyle}>
					Requeue
				</span>
			)}
			<Dialog open={isConfirmationModalOpen} onClose={() => setIsConfirmationModalOpen(false)}>
				<DialogTitle>Requeue submission</DialogTitle>
				<DialogContent sx={{ backgroundColor: 'white' }}>
					<DialogContentText>
						Are you sure you want to requeue this submission? Please be informed that it will continue from where it got
						stuck instead of rejudging from the beginning.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} variant='contained' onClick={handleRequeueSubmission}>
						{loading ? <CircularProgress size={25} /> : `Requeue`}
					</Button>
					<Button disabled={loading} variant='outlined' onClick={() => setIsConfirmationModalOpen(false)}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default RequeueSubmission;
