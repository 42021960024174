import React, { useEffect } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { ContestRead } from '../../../../core/contests/types';
import SubmissionList from './SubmissionList';
import SubmissionsSidebar from './SubmissionsSidebar';
import { GetSubmissionsParams, Submission } from '../../../../core/submissions/types';
import { getSubmissions, rejudgeBulkSubmissions } from '../../../../core/submissions/services';
import { useSnackbar } from 'notistack';
import _, { debounce } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
	contest: ContestRead;
	showOwnSubmissions: boolean;
	toggleShowOwnSubmissions: () => void;
}

export default function SubmissionListWithFilter({ contest, showOwnSubmissions, toggleShowOwnSubmissions }: Props) {
	const PAGE_SIZE = 25;
	const [page, setPage] = React.useState(1);
	const [submissions, setSubmissions] = React.useState<Submission[]>([]);
	const [count, setCount] = React.useState(0);

	const initFilterParams = {
		problem_set: null,
		submitted_by: '',
		status: null,
		language: null,
		response: null,
	};

	const [filterParams, setFilterParams] = React.useState<GetSubmissionsParams>(initFilterParams);
	const [isLoading, setIsLoading] = React.useState(true);
	const user = useCurrentUser();

	const { enqueueSnackbar } = useSnackbar();

	const location = useLocation();
	const navigate = useNavigate();

	const debounceFetchSubmissions = debounce(
		(page = 1, filterParameters: GetSubmissionsParams = initFilterParams) => fetchSubmissions(page, filterParameters),
		300,
	);

	const fetchSubmissions = async (page: number, filterParameters: GetSubmissionsParams = filterParams) => {
		setIsLoading(true);
		const getSubmissionsParams = {
			contest: contest.id!,
			offset: (page - 1) * PAGE_SIZE,
			limit: PAGE_SIZE,
			...filterParameters,
		};

		if (showOwnSubmissions) {
			getSubmissionsParams.submitted_by = user?.username;
		}

		getSubmissions(getSubmissionsParams)
			.then((submissionsData) => {
				setSubmissions(submissionsData.results);
				setCount(submissionsData.count);
			})
			.catch((error: Error) => {
				console.log(error);
				enqueueSnackbar('Error fetching submissions!', { variant: 'error' });
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		const filterParamsFromURL = getFilterParamsFromURL();
		debounceFetchSubmissions(1, filterParamsFromURL);
	}, [showOwnSubmissions]);

	const getFilterParamsFromURL = (): GetSubmissionsParams => {
		const urlSearchParams = new URLSearchParams(location.search);
		const paramsFromUrl = Object.fromEntries(urlSearchParams.entries()) as GetSubmissionsParams;

		const newParams = {
			...filterParams,
			...paramsFromUrl,
		};

		setFilterParams(newParams);

		return newParams;
	};

	const onPageChangeHandler = async (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
		await debounceFetchSubmissions(value, filterParams);
	};

	const onFilterHandler = async () => {
		setPage(1);
		updateUrlParams();
		await debounceFetchSubmissions(1, filterParams);
	};

	const updateUrlParams = () => {
		const urlParams = convertFiltersToSearchParams(filterParams).toString();
		const newURL = `${location.pathname}?${urlParams}`;
		navigate(newURL, { replace: true });
	};

	const convertFiltersToSearchParams = (filters: GetSubmissionsParams): URLSearchParams => {
		const searchParams = new URLSearchParams();
		Object.entries(filters).forEach(([key, value]) => {
			console.log(key, value);

			if (value !== null) {
				searchParams.set(key, String(value));
			}
		});
		console.log(searchParams.toString());

		return searchParams;
	};

	const rejudgeSubmissions = async () => {
		setIsLoading(true);
		rejudgeBulkSubmissions(_.omit(filterParams, ['offset', 'limit']), contest.id!)
			.then(() => {
				enqueueSnackbar('Submissions queued for rejudge successfully!', {
					autoHideDuration: 3000,
					variant: 'success',
				});
			})
			.catch((error: Error) => {
				console.log(error);
				enqueueSnackbar('Error rejudging submissions!', {
					autoHideDuration: 3000,
					variant: 'error',
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};
	const theme = useTheme();
	const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'));
	const onReloadPage = () => fetchSubmissions(page);

	return (
		<Grid>
			<Grid container direction={greaterThanMid ? 'row' : 'column-reverse'} spacing={4}>
				<Grid item xs={12} lg={9}>
					<SubmissionList
						isLoading={isLoading}
						contestSlug={contest.slug}
						submissions={submissions}
						page={page}
						totalPages={Math.ceil(count / PAGE_SIZE)}
						totalResults={count}
						onPageChangeHandler={onPageChangeHandler}
						showingOwnSubmission={showOwnSubmissions}
						toggleShowOwnSubmissions={toggleShowOwnSubmissions}
						onReloadPage={onReloadPage}
					/>
				</Grid>
				<Grid item xs={12} lg={3}>
					<SubmissionsSidebar
						contest={contest}
						filterParams={filterParams}
						setFilterParams={setFilterParams}
						onFilterHandler={onFilterHandler}
						rejudgeSubmissions={rejudgeSubmissions}
						isLoading={isLoading}
						onClearFilters={() => setFilterParams({ ...initFilterParams })}
						showingOwnSubmission={showOwnSubmissions}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}
