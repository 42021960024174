import React from 'react';
import { Button, Typography } from '@mui/material';
import CreateProblemDialog from './CreateProblemDialog';

export function CreateProblemButton({ contestId, contestSlug }: { contestId: number; contestSlug: string }) {
	const [open, setOpen] = React.useState(false);

	return (
		<div>
			<Button fullWidth variant='contained' color='primary' onClick={() => setOpen(true)} size={'small'}>
				<Typography sx={{ padding: '8px' }}>Create new problem</Typography>
			</Button>
			<CreateProblemDialog open={open} setOpen={setOpen} contestId={contestId} contestSlug={contestSlug} />
		</div>
	);
}
