import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { TableHeaderCellStyle, TableHeaderStyle } from '../styles';

function SubmissionInfoTableHead() {
	return (
		<TableHead style={TableHeaderStyle}>
			<TableRow>
				<TableCell style={TableHeaderCellStyle}>#</TableCell>
				<TableCell style={TableHeaderCellStyle}>Full Name</TableCell>
				<TableCell style={TableHeaderCellStyle}>Problem</TableCell>
				<TableCell style={TableHeaderCellStyle}>Time</TableCell>
				<TableCell style={TableHeaderCellStyle}>Language</TableCell>
				<TableCell style={TableHeaderCellStyle}>CPU</TableCell>
				<TableCell style={TableHeaderCellStyle}>Memory</TableCell>
				<TableCell style={TableHeaderCellStyle}>Verdict</TableCell>
			</TableRow>
		</TableHead>
	);
}

export default SubmissionInfoTableHead;
