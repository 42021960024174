import React from 'react';
import { FormControlLabel, Grid, IconButton, Pagination, Switch, Tooltip, Typography } from '@mui/material';
import { SectionTitle } from '../../../../../design-library';
import { Submission } from '../../../../../core/submissions/types';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import RenderSubmissionID from '../../../../common/tableCellRenderers/RenderSubmissionID';
import RenderAuthor from '../../../../common/tableCellRenderers/RenderAuthor';
import RenderProblemTitle from '../../../../common/tableCellRenderers/RenderProblemTitle';
import RenderSubmissionTime from '../../../../common/tableCellRenderers/RenderSubmissionTime';
import RenderCPU from '../../../../common/tableCellRenderers/RenderCPU';
import RenderMemory from '../../../../common/tableCellRenderers/RenderMemory';
import RenderVerdict from '../../../../common/tableCellRenderers/RenderVerdict';
import { LinearLoader } from '../../../../../design-library/LinearLoader';
import CachedIcon from '@mui/icons-material/Cached';

export default function SubmissionList({
	isLoading,
	contestSlug,
	submissions,
	page,
	totalPages,
	totalResults,
	onPageChangeHandler,
	showingOwnSubmission,
	toggleShowOwnSubmissions,
	onReloadPage,
}: {
	isLoading: boolean;
	contestSlug: string;
	submissions: Submission[];
	page: number;
	totalPages: number;
	totalResults: number;
	onPageChangeHandler: (event: React.ChangeEvent<unknown>, value: number) => Promise<void>;
	showingOwnSubmission: boolean;
	toggleShowOwnSubmissions: () => void;
	onReloadPage: () => void;
}) {
	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: '#',
			flex: 1,
			renderCell: (props: GridRenderCellParams) => RenderSubmissionID(props, contestSlug),
			sortable: false,
		},
		{
			field: 'submitted_by',
			headerName: 'Full Name',
			flex: 1.5,
			minWidth: 120,
			renderCell: RenderAuthor,
			sortable: false,
		},
		{
			field: 'problem',
			headerName: 'Problem',
			flex: 1.5,
			minWidth: 120,
			renderCell: (props: GridRenderCellParams) => RenderProblemTitle(props, contestSlug),
			sortable: false,
		},
		{
			field: 'created_at',
			headerName: 'Time',
			flex: 1,
			minWidth: 100,
			renderCell: RenderSubmissionTime,
			sortable: false,
		},
		{
			field: 'language',
			headerName: 'language',
			flex: 1,
			minWidth: 60,
			sortable: false,
		},
		{
			field: 'cpu',
			headerName: 'CPU',
			flex: 1,
			minWidth: 60,
			renderCell: RenderCPU,
			sortable: false,
		},
		{
			field: 'memory',
			headerName: 'Memory',
			flex: 1,
			minWidth: 80,
			renderCell: RenderMemory,
			sortable: false,
		},
		{
			field: 'response',
			headerName: 'Verdict',
			flex: 2,
			minWidth: 160,
			renderCell: RenderVerdict,
			sortable: false,
			align: 'right',
		},
	];

	return (
		<Grid container direction='row'>
			<Grid item xs={12}>
				<SectionTitle
					title={'Submissions'}
					size={'small'}
					actions={
						<Grid container spacing={2} alignItems={'center'} alignContent={'center'}>
							<Grid item>
								<FormControlLabel
									value='My submissions only'
									control={
										<Switch color='primary' checked={showingOwnSubmission} onChange={toggleShowOwnSubmissions} />
									}
									label='My submissions only'
									labelPlacement='start'
									sx={{ color: '#2F5D62' }}
								/>
							</Grid>
							<Grid item>
								<Tooltip title={'Reload list'}>
									<IconButton onClick={onReloadPage}>
										<CachedIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					}
				/>
				{(isLoading || !submissions) && <LinearLoader />}
				{submissions && submissions.length !== 0 ? (
					<DataGrid
						rows={submissions}
						columns={columns}
						disableRowSelectionOnClick
						getRowClassName={(params) => {
							return params.row.submission_type !== 'CONTEST' ? 'hide_non_contestant_submission' : '';
						}}
						sx={{
							fontSize: '12px',
							width: '100%',
							'.hide_non_contestant_submission': {
								opacity: 0.6,
								bgcolor: '#f2f7ff',
							},
						}}
						hideFooter
						hideFooterPagination
					/>
				) : (
					<Grid item>
						<Typography variant={'h6'} color={'primary'} sx={{ padding: '12px' }}>
							{'No results found!'}
						</Typography>
					</Grid>
				)}
			</Grid>
			<Grid item xs={12} sx={{ padding: '10px' }}>
				<Typography variant={'h6'} color={'primary'}>{`Total results found: ${totalResults}`}</Typography>
			</Grid>
			<Grid
				item
				container
				xs={12}
				direction='column'
				justifyContent='center'
				alignItems='center'
				sx={{ marginTop: '20px' }}
			>
				<Pagination
					page={page}
					count={totalPages}
					variant='outlined'
					shape='rounded'
					size='large'
					onChange={onPageChangeHandler}
					boundaryCount={1}
				/>
			</Grid>
		</Grid>
	);
}
