import React from 'react';
import { createNewContest } from '../../core/contests/services';
import { Contest } from '../../core/contests/types';
import { CONTEST_TYPES, CONTEST_VISIBILITY_TYPES, STANDING_TYPES } from '../../core/contests/constants';
import ContestForm from './ContestForm';
import { useTitle } from '../../contexts/TitleContext';

export default function CreateContest() {
	const { setTitle } = useTitle();
	setTitle('Create Contest');
	const initFormData: Contest = {
		slug: '',
		title: '',
		description: '',
		notes: '',
		starts_at: null,
		duration: undefined,
		contest_type: CONTEST_TYPES.ACM_ICPC,
		standing_type: STANDING_TYPES.PUBLIC,
		contest_visibility: CONTEST_VISIBILITY_TYPES.UNLISTED,
		is_frozen: false,
	};

	const onSubmitHandler = async (formData: Contest) => {
		return await createNewContest(formData);
	};

	return <ContestForm type={'create'} initFormData={initFormData} handleSubmit={onSubmitHandler} />;
}
