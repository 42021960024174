import React, { useEffect, useState } from 'react';
import {
	Alert,
	Box,
	Divider,
	Grid,
	LinearProgress,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { ProblemAttempt, StandingsItem } from '../../../core/standings/types';
import StandingsRow from './StandingsRow';
import { problemHeaderStyle } from './styles';
import moment from 'moment';
import ContestHeading from '../../common/ContestHeading';
import { useSnackbar } from 'notistack';
import { getFrozenCatchyMessages, getInternalStandings, getPublicStandings } from '../../../core/standings/services';
import { useParams } from 'react-router-dom';
import { getContestData } from '../../../core/contests/services';
import { ContestRead } from '../../../core/contests/types';
import ContestNotStartedPage from '../ContestNotStartedPage';
import { LinearLoader } from '../../../design-library/LinearLoader';
import { useTitle } from '../../../contexts/TitleContext';

export default function ContestStandings({ internal = false }: { internal?: boolean }) {
	const { setTitle } = useTitle();
	setTitle((internal ? 'Internal ' : '') + 'Standings');
	const getStandingsFunction = internal ? getInternalStandings : getPublicStandings;
	const PAGE_SIZE = 200;
	const { enqueueSnackbar } = useSnackbar();
	const { contestSlug } = useParams();
	const [contest, setContest] = useState<ContestRead | undefined>(undefined);
	const [isFetchingContest, setIsFetchingContest] = useState(true);

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [standingsItems, setStandingsItems] = useState<StandingsItem[]>([]);
	const [problemsAttempts, setProblemsAttempts] = useState<ProblemAttempt[]>([]);

	useEffect(() => {
		const fetchContest = async () => {
			setIsFetchingContest(true);
			const contestData = await getContestData(contestSlug!);
			setContest(contestData);
			setIsFetchingContest(false);
		};
		fetchContest();
	}, []);

	useEffect(() => {
		if (!contest) return;
		fetchStandings(page);
	}, [contest]);

	const onPageChangeHandler = (event: React.ChangeEvent<unknown>, value: number) => {
		console.log(value);
		setPage(value);
		fetchStandings(value);
	};

	const fetchStandings = async (page: number) => {
		setIsLoading(true);
		getStandingsFunction(contest!.id!, page)
			.then((response) => {
				setTotalPages(response.total_pages);
				setStandingsItems(response.results);
				setProblemsAttempts(response.problem_attempts);
			})
			.catch((error) => {
				enqueueSnackbar('Error loading standings!', {
					autoHideDuration: 3000,
					variant: 'error',
				});
				console.error(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	if (isFetchingContest || !contest) return <LinearLoader />;

	const hasEditAccess = ['STAFF', 'JUDGE'].includes(contest.role);
	const contestNotStarted = moment(contest.starts_at).isAfter(moment());
	if (contestNotStarted && !hasEditAccess) return <ContestNotStartedPage contest={contest} />;

	return (
		<Grid container direction={'column'} xs={12} justifyContent={'center'} sx={{ padding: '24px' }} spacing={2}>
			<Grid item>
				<ContestHeading name={contest.title} starts_at={moment(contest.starts_at)} stops_at={moment(contest.ends_at)} />
			</Grid>
			{internal && (
				<Grid item>
					<Typography color={'secondary'} variant={'body1'} align={'center'}>
						You are watching the internal realtime standings!
					</Typography>
				</Grid>
			)}
			{!internal && contest.is_frozen && (
				<Grid item container direction={'column'}>
					<Typography color={'primary'} variant={'h5'} align={'center'}>
						The standings is currently frozen!
					</Typography>
					<Typography color={'secondary'} variant={'subtitle1'} align={'center'}>
						{getFrozenCatchyMessages()}
					</Typography>
				</Grid>
			)}
			{standingsItems.length > 0 && (
				<Grid item container xs={12} direction='column' justifyContent='center' alignItems='center'>
					<Pagination
						page={page}
						count={totalPages}
						variant='outlined'
						shape='rounded'
						size='large'
						onChange={onPageChangeHandler}
						boundaryCount={1}
					/>
				</Grid>
			)}
			{isLoading && (
				<Box sx={{ width: '100%', paddingX: '20px', marginTop: '20px' }}>
					<LinearProgress />
				</Box>
			)}
			<Grid item>
				<TableContainer>
					<Table>
						<TableHead sx={{ backgroundColor: '#f8f6f6' }}>
							<TableRow>
								<TableCell style={{ width: '50px' }} align={'center'}>
									<strong>#</strong>
								</TableCell>
								<TableCell align={'center'} sx={{ minWidth: '300px' }}>
									<strong>Name</strong>
								</TableCell>
								<TableCell style={problemHeaderStyle} />
								{problemsAttempts.map((problem, index) => (
									<TableCell key={index} align={'center'} style={problemHeaderStyle}>
										<div>
											<strong>{problem.number_to_alpha}</strong>
										</div>
										<Divider />
										<div style={{ fontSize: '12px' }}>
											<strong>{`${problem.total_accepted} / ${problem.total_tries}`}</strong>
										</div>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{standingsItems.map((item, index) => (
								<StandingsRow key={index} offset={(page - 1) * PAGE_SIZE} index={index} data={item} />
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item container xs={12} direction='column' justifyContent='center' alignItems='center'>
				<Pagination
					page={page}
					count={totalPages}
					variant='outlined'
					shape='rounded'
					size='large'
					onChange={onPageChangeHandler}
					boundaryCount={1}
				/>
			</Grid>
		</Grid>
	);
}
