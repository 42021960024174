import React from 'react';
import { Button, Typography } from '@mui/material';
import CloneProblemDialog from './CloneProblemDialog';

export function CloneProblemButton({ contestId }: { contestId: number }) {
	const [open, setOpen] = React.useState(false);

	return (
		<div>
			<Button fullWidth variant='outlined' color='primary' onClick={() => setOpen(true)} size={'small'}>
				<Typography color='primary' sx={{ padding: '8px' }}>
					Clone a problem
				</Typography>
			</Button>
			<CloneProblemDialog open={open} setOpen={setOpen} contestId={contestId} />
		</div>
	);
}
