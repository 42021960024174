import React, { useEffect, useState } from 'react';
import EditProblemTabs from './EditProblemTabs';
import { useParams } from 'react-router-dom';
import { SectionPaper, SectionTitle } from '../../../design-library';
import { Grid } from '@mui/material';
import { ContestRead } from '../../../core/contests/types';
import { getContestData } from '../../../core/contests/services';
import ProblemSetForm from './ProblemSetForm';
import { LinearLoader } from '../../../design-library/LinearLoader';
import { useTitle } from '../../../contexts/TitleContext';

export default function EditProblemSet() {
	const { contestSlug = '', problemCharacter = '' } = useParams<{ contestSlug: string; problemCharacter: string }>();
	const { setTitle } = useTitle();
	setTitle(`${contestSlug} | ${problemCharacter} | Edit`);
	const [problemId, setProblemId] = useState<number | undefined>(undefined);
	const [problemSetId, setProblemSetId] = useState<number | undefined>(undefined);
	const [contest, setContest] = useState<ContestRead | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchContest = async () => {
			setIsLoading(true);
			const contestData = await getContestData(contestSlug);
			setContest(contestData);

			const selectedProblemSet = contestData!.problem_set.find(
				(problem) => problem.problem_order_character === problemCharacter,
			);
			setProblemSetId(selectedProblemSet!.id);
			setProblemId(selectedProblemSet?.problem_id);
			setIsLoading(false);
		};
		if (contestSlug) fetchContest();
	}, [contestSlug]);

	if (isLoading || !contest) {
		return <LinearLoader />;
	}

	return (
		<div>
			<SectionTitle title={'Edit Problem Set'} size={'small'} />
			<SectionPaper size={'small'}>
				<Grid container direction='column' gap={2}>
					{problemSetId && (
						<Grid item>
							<ProblemSetForm problemSetId={problemSetId} contestSlug={contest.slug} />
						</Grid>
					)}
					{problemId && problemSetId && (
						<Grid item>
							<EditProblemTabs problemId={problemId} problemSetId={problemSetId} />
						</Grid>
					)}
				</Grid>
			</SectionPaper>
		</div>
	);
}
