import React, { useEffect, useState } from 'react';
import Timer from './Timer';
import moment from 'moment';
import { MILLISECONDS_IN_AN_HOUR, MILLISECONDS_IN_A_MINUTE, MILLISECONDS_IN_A_SECOND } from '../Constants';
import { CountdownTimerProps, RemainingTime } from './types';
import { Container, Grid, Typography } from '@mui/material';
import TimerHeader from './TimerHeader';

function ContestSidebarTimer({ starts_at, stops_at, inline = false }: CountdownTimerProps) {
	const [finished, setFinished] = useState(false);
	const [started, setStarted] = useState(false);

	const updateRemainingTime = (startTime: number, endTime: number, currentTime: number) => {
		if (currentTime < startTime) {
			setRemainingTime(getTimeBeforeStart(startTime, currentTime));
			return;
		}

		setStarted(true);
		setRemainingTime(getTimeToEnd(endTime, currentTime));
	};

	const getTimeBeforeStart = (startTime: number, currentTime: number) => {
		const remainingMilliseconds = startTime - currentTime;
		const hours = String(Math.floor(remainingMilliseconds / MILLISECONDS_IN_AN_HOUR)).padStart(2, '0');
		const minutes = String(
			Math.floor((remainingMilliseconds % MILLISECONDS_IN_AN_HOUR) / MILLISECONDS_IN_A_MINUTE),
		).padStart(2, '0');
		const seconds = String(
			Math.floor((remainingMilliseconds % MILLISECONDS_IN_A_MINUTE) / MILLISECONDS_IN_A_SECOND),
		).padStart(2, '0');
		return { hours, minutes, seconds };
	};

	const getTimeToEnd = (endTime: number, currentTime: number) => {
		const remainingMilliseconds = Math.max(endTime - currentTime, 0);
		const hours = String(Math.floor(remainingMilliseconds / MILLISECONDS_IN_AN_HOUR)).padStart(2, '0');
		const minutes = String(
			Math.floor((remainingMilliseconds % MILLISECONDS_IN_AN_HOUR) / MILLISECONDS_IN_A_MINUTE),
		).padStart(2, '0');
		const seconds = String(
			Math.floor((remainingMilliseconds % MILLISECONDS_IN_A_MINUTE) / MILLISECONDS_IN_A_SECOND),
		).padStart(2, '0');
		return { hours, minutes, seconds };
	};

	const [remainingTime, setRemainingTime] = useState<RemainingTime>({
		hours: 'HH',
		minutes: 'MM',
		seconds: 'SS',
	});

	useEffect(() => {
		const currentTime = Date.now();
		const startTime = moment(starts_at, true).toDate().getTime();
		const endTime = moment(stops_at, true).toDate().getTime();

		if (currentTime > endTime) {
			setFinished(true);
		} else {
			const interval = setInterval(() => {
				updateRemainingTime(startTime, endTime, Date.now());
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [starts_at, stops_at]);

	if (inline) {
		return (
			<Grid container justifyContent={'end'}>
				<TimerHeader started={started} finished={finished} size={'small'} />
				{!finished && (
					<Typography variant={'body1'} color={'primary'}>
						:&nbsp;&nbsp;
					</Typography>
				)}
				{!finished && <Timer remainingTime={remainingTime} size={'small'} />}
			</Grid>
		);
	}

	return (
		<Container>
			<TimerHeader started={started} finished={finished} />
			{!finished && (
				<Grid container alignItems={'center'} justifyContent={'center'}>
					<Timer remainingTime={remainingTime} />
				</Grid>
			)}
		</Container>
	);
}

export default ContestSidebarTimer;
