import React from 'react';

const themeColor = '#376469';

export const TestDetailsStyle: React.CSSProperties = {
	marginTop: '20px',
};

export const TableStyle: React.CSSProperties = {
	margin: '3px auto',
};

export const TableHeaderStyle: React.CSSProperties = {
	backgroundColor: '#f5f3f3',
};

export const TableHeaderCellStyle: React.CSSProperties = {
	fontWeight: 'bold',
};

export const SourceLinkDivStyle: React.CSSProperties = {
	display: 'flex',
	flexDirection: 'row-reverse',
	padding: '10px 7px',
	textTransform: 'uppercase',
	gap: '10px',
	color: themeColor,
};

export const SubmissionViewHeaderStyle: React.CSSProperties = {
	padding: '10px 7px',
	textTransform: 'uppercase',
	color: themeColor,
};

export const TestDetailsHeaderStyle: React.CSSProperties = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '5px 7px',
	textTransform: 'uppercase',
	color: themeColor,
};

export const LinkStyle: React.CSSProperties = {
	color: themeColor,
	textDecoration: 'none',
	cursor: 'pointer',
};

export const VerdictStyle: React.CSSProperties = {
	padding: '2px 5px',
	borderRadius: '',
};

export const AcceptedVerdictStyle: React.CSSProperties = {
	backgroundColor: '#2f7c31',
};

export const ManualJudgeModalStyle: React.CSSProperties = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	background: '#f8f6f6',
	width: 400,
	boxShadow: '24',
	padding: '10px',
	borderRadius: '5px',
	color: '#376469',
};

export const TextOverflowStyles: React.CSSProperties = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};
