import React from 'react';
import { SectionPaper, SectionTitle } from '../../../../design-library';
import { Divider, Grid, Typography } from '@mui/material';
import { InputOutputSample } from '../../../../core/problems/types';
import DeleteSampleModal from './DeleteSampleModal';
import EditSampleModal from './EditSampleModal';
import MarkdownView from '../../../../design-library/MarkdownView';

export default function SampleListItem({
	sample,
	onDeleteSuccess,
	problemSetId,
}: {
	sample: InputOutputSample;
	onDeleteSuccess: (sampleId: number) => void;
	problemSetId: number;
}) {
	const [sampleViewData, setSampleViewData] = React.useState<InputOutputSample>(sample);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
	const [isEditDialogOpen, setIsEditDialogOpen] = React.useState<boolean>(false);

	const handleEditIconClick = () => {
		setIsEditDialogOpen(true);
	};

	const handleDeleteIconClick = () => {
		setIsDeleteDialogOpen(true);
	};

	return (
		<Grid direction='row' container sx={{ width: '100%' }}>
			<SectionTitle
				title={sampleViewData.title}
				size='small'
				isEditable={true}
				onEdit={handleEditIconClick}
				isDeletable={true}
				onDelete={handleDeleteIconClick}
			/>
			<SectionPaper>
				<Grid item container direction='row' spacing={2}>
					<Grid item container direction='column' xs={6}>
						<Grid item>
							<Typography variant='body1' color='primary.main'>
								Input
							</Typography>
						</Grid>
						<Divider sx={{ marginY: '8px' }} />
						<Grid item sx={{ width: '100%' }}>
							<MarkdownView data={`\`\`\`text\n${sampleViewData.sample_input}\n\`\`\``} />
						</Grid>
					</Grid>
					<Grid item container direction='column' xs={6}>
						<Grid item>
							<Typography variant='body1' color='primary.main'>
								Output
							</Typography>
						</Grid>
						<Divider sx={{ marginY: '8px' }} />
						<Grid item sx={{ width: '100%' }}>
							<MarkdownView data={`\`\`\`text\n${sampleViewData.sample_output}\n\`\`\``} />
						</Grid>
					</Grid>
				</Grid>
			</SectionPaper>
			<EditSampleModal
				isOpen={isEditDialogOpen}
				onClose={() => setIsEditDialogOpen(false)}
				sample={sampleViewData}
				setSample={setSampleViewData}
				problemSetId={problemSetId}
			/>
			<DeleteSampleModal
				sampleId={sample.id!}
				sampleTitle={sampleViewData.title ?? ''}
				isOpen={isDeleteDialogOpen}
				onClose={() => setIsDeleteDialogOpen(false)}
				onDeleteSuccess={onDeleteSuccess}
				problemSetId={problemSetId}
			/>
		</Grid>
	);
}
