import { createContext } from 'react';
import { AuthorizedUser } from '../core/user/types';

export type AuthContextType = {
	user: AuthorizedUser | null;
	token: string | null;
	onLoginSuccess: (userInfo?: AuthorizedUser, newToken?: string) => void;
	onLogoutSuccess: () => void;
};

export const AuthContext = createContext<AuthContextType>({
	user: null,
	token: null,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onLoginSuccess: () => {},
	onLogoutSuccess: () => {},
});
