import { useContext } from 'react';
import { AuthContext, AuthContextType } from '../contexts/AuthContext';

export const useAuth = () => {
	const { onLoginSuccess, onLogoutSuccess }: AuthContextType = useContext(AuthContext);

	return {
		onLoginSuccess,
		onLogoutSuccess,
	};
};
