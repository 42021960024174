import React from 'react';
import { SectionTitle } from '../../../../../design-library';
import CachedIcon from '@mui/icons-material/Cached';
import { IconButton } from '@mui/material';

function SubmissionHeader({ fetchSubmission }: { fetchSubmission: () => void }) {
	return (
		<SectionTitle
			size={'small'}
			title={'Submission'}
			actions={
				<IconButton onClick={fetchSubmission}>
					<CachedIcon />
				</IconButton>
			}
		/>
	);
}

export default SubmissionHeader;
