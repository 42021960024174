import React from 'react';
import { useTitle } from '../../contexts/TitleContext';

export default function ResetPassword() {
	const { setTitle } = useTitle();
	setTitle('Reset Password');
	return (
		<div>
			<h1>ResetPassword</h1>
		</div>
	);
}
