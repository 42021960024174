import React from 'react';
import { SectionPaper, SectionTitle } from '../../design-library';

import { Grid, Box } from '@mui/material';
import { ContestRead } from '../../core/contests/types';
import { useNavigate } from 'react-router-dom';
import CountdownTimer from '../../design-library/CountdownTimer/CountdownTimer';
import moment from 'moment';

export default function SideBarBriefAndTimer({ contest }: { contest: ContestRead }) {
	const navigate = useNavigate();
	const isEditable = ['STAFF', 'JUDGE'].includes(contest.role);

	const handleOnEditContest = () => {
		navigate(`/contests/${contest.slug}/edit`);
	};

	const timerStatus =
		moment.now() > moment(contest.ends_at).valueOf()
			? 'The contest has ended'
			: moment.now() < moment(contest.starts_at).valueOf()
			? 'The contest has not started yet'
			: 'The contest is running';

	return (
		<Grid container direction='column' rowSpacing='2px'>
			<Grid item>
				<SectionTitle title={contest.title} size='small' isEditable={isEditable} onEdit={handleOnEditContest} />
			</Grid>
			<Grid item>
				<SectionPaper>
					<Box sx={{ display: 'flex', height: '70px', alignItems: 'center', justifyContent: 'center' }}>
						<CountdownTimer starts_at={contest.starts_at} stops_at={contest.ends_at} />
					</Box>
				</SectionPaper>
			</Grid>
			<Grid item>
				<SectionTitle title={timerStatus} size='small' />
			</Grid>
		</Grid>
	);
}
