import React from 'react';
import VerdictChip from '../../../../common/VerdictChip';
import { SubmissionResponse, SubmissionStatus } from '../../../../../core/submissions/types';

interface Props {
	status: SubmissionStatus;
	verdict: SubmissionResponse;
}

function VerdictCell({ status, verdict }: Props) {
	return <VerdictChip status={status} verdict={verdict} />;
}

export default VerdictCell;
