export enum CONTEST_TYPES {
	ACM_ICPC = 'ACM-ICPC',
	CODEJELLY = 'CODEJELLY',
	IOI = 'IOI',
	TIME_TRIAL = 'TIME-TRIAL',
	OTHER = 'OTHER',
}

export enum STANDING_TYPES {
	PUBLIC = 'PUBLIC',
	PRIVATE = 'PRIVATE',
	PROTECTED = 'PROTECTED',
}

export enum CONTEST_VISIBILITY_TYPES {
	PUBLIC = 'PUBLIC',
	UNLISTED = 'UNLISTED',
}

export const CONTEST_TYPE_LABELS = {
	[CONTEST_TYPES.ACM_ICPC]: 'ICPC',
	[CONTEST_TYPES.CODEJELLY]: 'CodeJelly',
	[CONTEST_TYPES.IOI]: 'IOI',
	[CONTEST_TYPES.TIME_TRIAL]: 'Time Trial',
	[CONTEST_TYPES.OTHER]: 'Other',
};

export const CONTEST_VISIBILITY_TYPE_LABELS = {
	[CONTEST_VISIBILITY_TYPES.PUBLIC]: 'Public',
	[CONTEST_VISIBILITY_TYPES.UNLISTED]: 'Unlisted',
};

export const STANDING_TYPE_LABELS = {
	[STANDING_TYPES.PUBLIC]: 'Public',
	[STANDING_TYPES.PRIVATE]: 'Private',
	[STANDING_TYPES.PROTECTED]: 'Protected',
};
