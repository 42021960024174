import React, { useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { updateVerdictForManualJudge } from '../../../../../core/submissions/services';
import { useParams } from 'react-router-dom';
import { ManualJudgeModalStyle } from '../styles';
import {
	SubmissionResponse,
	SubmissionResponseLabels,
	SubmissionResponses,
} from '../../../../../core/submissions/types';

interface ModalProps {
	currentVerdict: SubmissionResponse;
	open: boolean;
	onClose: () => void;
}

function ManualJudgeModal({ currentVerdict, open, onClose }: ModalProps) {
	const [selectedValue, setSelectedValue] = useState<SubmissionResponse>(currentVerdict);
	const { submissionId } = useParams();

	if (!submissionId) return <></>;

	const handleSelectChange = (event: SelectChangeEvent) => {
		setSelectedValue(event.target.value as SubmissionResponse);
	};

	const handleSubmit = () => {
		updateVerdictForManualJudge(submissionId, selectedValue)
			.then(() => {
				enqueueSnackbar('Verdict updated successfully!', {
					autoHideDuration: 3000,
					variant: 'success',
				});
				onClose();
			})
			.catch((error) => {
				console.log(error);
				enqueueSnackbar('Error updating verdict!', {
					autoHideDuration: 3000,
					variant: 'error',
				});
			});
	};

	return (
		<Modal open={open} onClose={onClose} aria-labelledby='modal-title' aria-describedby='modal-description'>
			<div style={ManualJudgeModalStyle}>
				<Typography variant='h6' component='h2' id='modal-title' gutterBottom>
					Manual Judge
				</Typography>

				<FormControl sx={{ width: '100%' }}>
					<InputLabel id='select-submission-verdict-label'>Verdict</InputLabel>
					<Select
						labelId='select-submission-verdict-label'
						id='select-submission-verdict'
						value={selectedValue}
						label='Verdict'
						onChange={handleSelectChange}
					>
						{SubmissionResponses.map((response) => (
							<MenuItem key={response} value={response}>
								{SubmissionResponseLabels[response]}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
					<Button variant='outlined' onClick={onClose} style={{ marginTop: '20px' }}>
						Cancel
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={handleSubmit}
						style={{ marginRight: '10px', marginTop: '20px' }}
					>
						Submit
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default ManualJudgeModal;
