import React from 'react';
import moment from 'moment/moment';
import { Tooltip } from '@mui/material';

export default function SubmissionTimeCell({ submissionTime }: { submissionTime: Date }) {
	const timeAgo = moment(submissionTime).fromNow().toString();

	return (
		<div>
			<Tooltip title={moment(submissionTime).format('llll')}>
				<div>{timeAgo}</div>
			</Tooltip>
		</div>
	);
}
