import React from 'react';

import CodeMirror from '@uiw/react-codemirror';
import { cpp } from '@codemirror/lang-cpp';
import { python } from '@codemirror/lang-python';
import { java } from '@codemirror/lang-java';

export default function CodeViewer({
	language,
	value,
}: {
	language: 'c' | 'cpp' | 'py2' | 'py3' | 'java' | 'text';
	value: string;
}) {
	const cmLanguageSelector = (language: string) => {
		switch (language) {
			case 'c':
				return cpp();
			case 'cpp':
				return cpp();
			case 'python':
				return python();
			case 'java':
				return java();
			default:
				return cpp();
		}
	};
	return (
		<CodeMirror
			editable={false}
			extensions={language !== 'text' ? [cmLanguageSelector(language)] : []}
			value={value}
			readOnly
		/>
	);
}
