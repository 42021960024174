import { Box, Typography } from '@mui/material';
import React from 'react';
import { Moment } from 'moment';
import CountdownTimer from '../../design-library/CountdownTimer/CountdownTimer';

interface ContestHeadingProps {
	name: string;
	starts_at: Moment | string | null | undefined;
	stops_at: Moment | string | null | undefined;
}

function ContestHeading({ name, starts_at, stops_at }: ContestHeadingProps) {
	return (
		<Box display={'flex'} flexDirection='column' alignItems='center' mb={2}>
			<Typography align={'center'} variant={'h4'} mb={1} color={'primary'}>
				{name}
			</Typography>
			<Box>
				<CountdownTimer starts_at={starts_at} stops_at={stops_at} />
			</Box>
		</Box>
	);
}

export default ContestHeading;
