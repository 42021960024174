export type AuthorizedUser = {
	id: number;
	name: string;
	username: string;
	first_name: string;
	last_name: string;
	email: string;
	institution?: string;
	is_staff: boolean;
	is_active: boolean;
	is_banned: boolean;
	is_superuser: boolean;
	is_password_frozen: boolean;
	groups: [];
	user_permissions: [];
};

export type AuthorizationResponse = {
	user: AuthorizedUser;
	auth_token: string;
};

export const USER_ROLE_ENUMS = {
	STAFF: 'STAFF',
	JUDGE: 'JUDGE',
	CONTESTANT: 'CONTESTANT',
	ANONYMOUS: 'ANONYMOUS',
};

export const UserRoles = Object.keys(USER_ROLE_ENUMS) as UserRole[];
export type UserRole = keyof typeof USER_ROLE_ENUMS;
