import React, { ReactNode } from 'react';
import { Grid, IconButton, Typography, TypographyProps } from '@mui/material';
import _ from 'lodash';
import { SectionPaper } from './SectionPaper';
import { ComponentSize } from '../types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export function SectionTitle({
	title,
	size = 'medium',
	isEditable = false,
	onEdit = () => {},
	isDeletable = false,
	onDelete = () => {},
	...props
}: SectionTitleProps) {
	const sizeToVariant = {
		small: 'body1',
		medium: 'h6',
		large: 'h4',
	};
	type AllowedVariants = 'body1' | 'h4' | 'h6';

	const typographyProps: TypographyProps = {
		variant: sizeToVariant[size || 'medium'] as AllowedVariants,
	};

	return (
		<SectionPaper size={size} color={'light'} {...props}>
			<Grid container direction='row' spacing={0.3}>
				<Grid item xs>
					<Typography {...typographyProps} color='primary.main' marginY={1}>
						{_.upperCase(title)}
					</Typography>
				</Grid>
				<Grid item xs='auto'>
					{isEditable && (
						<IconButton size={size} onClick={onEdit} color='primary'>
							<EditIcon fontSize={size} />
						</IconButton>
					)}
					{isDeletable && (
						<IconButton size={size} onClick={onDelete} color='primary'>
							<DeleteIcon fontSize={size} />
						</IconButton>
					)}
					{props.actions && <>{props.actions}</>}
				</Grid>
			</Grid>
		</SectionPaper>
	);
}

type SectionTitleProps = {
	title: string;
	size?: ComponentSize;
	isAddable?: boolean;
	onAdd?: () => void;
	isEditable?: boolean;
	onEdit?: () => void;
	isDeletable?: boolean;
	onDelete?: () => void;
	actions?: ReactNode;
};
