import React from 'react';
import { Box, LinearProgress } from '@mui/material';

export function LinearLoader() {
	return (
		<Box sx={{ width: '100%', margin: '8px 0px' }}>
			<LinearProgress />
		</Box>
	);
}
