import React from 'react';
import { TimerHeaderProps } from './types';
import { Typography } from '@mui/material';

function TimerHeader({ started, finished, size = 'medium' }: TimerHeaderProps) {
	const variantSize = size === 'medium' ? 'h5' : size === 'small' ? 'body1' : 'h4';
	return (
		<Typography textAlign={'center'} variant={variantSize} color={'primary'} sx={{ fontWeight: 700 }}>
			{finished ? 'Finished' : started ? 'Ends in' : 'Starts in'}
		</Typography>
	);
}

export default TimerHeader;
