import React from 'react';
import SubmissionHeader from './SubmissionInfo/SubmissionHeader';
import SubmissionActions from './SubmissionInfo/SubmissionActions';
import SubmissionInfoTable from './SubmissionInfo/SubmissionInfoTable';
import { Submission } from '../../../../core/submissions/types';
import { LinearLoader } from '../../../../design-library/LinearLoader';

interface Props {
	isLoading: boolean;
	submission?: Submission;
	fetchSubmission: () => void;
}

function SubmissionInfo({ isLoading, submission, fetchSubmission }: Props) {
	return (
		<>
			{isLoading && <LinearLoader />}
			<SubmissionHeader fetchSubmission={fetchSubmission} />
			{submission && <SubmissionInfoTable submission={submission} />}
			{submission && <SubmissionActions submission={submission} fetchSubmission={fetchSubmission} />}
		</>
	);
}

export default SubmissionInfo;
