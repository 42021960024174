import React from 'react';
import { Dialog } from '@mui/material';
import DatasetForm from './DatasetForm';
import { Dataset, DatasetSaveBody } from '../../../../core/datasets/types';
import { editContestDataset } from '../../../../core/datasets/services';

export default function EditDatasetModal({
	isOpen,
	onClose,
	dataset,
	setDataset,
	problemSetId,
}: {
	isOpen: boolean;
	onClose: () => void;
	dataset: Dataset;
	setDataset: (dataset: Dataset) => void;
	problemSetId: number;
}) {
	const handleSubmit = async (data: DatasetSaveBody) => {
		const updatedDataset = await editContestDataset(dataset.id!, problemSetId, data);
		setDataset(updatedDataset);
		return updatedDataset;
	};

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'md'}>
			<DatasetForm type='edit' initFormData={dataset} handleSubmit={handleSubmit} onSuccess={onClose} />
		</Dialog>
	);
}
