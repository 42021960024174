import React from 'react';
import { Typography } from '@mui/material';
import { TimerProps } from './types';

function Timer({ remainingTime, size = 'medium' }: TimerProps) {
	const variantSize = size === 'medium' ? 'h5' : size === 'small' ? 'body1' : 'h4';
	return (
		<Typography variant={variantSize} color={'primary'}>
			{remainingTime?.hours}:{remainingTime?.minutes}:{remainingTime?.seconds}
		</Typography>
	);
}

export default Timer;
