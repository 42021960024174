import axios from 'axios';
import { PresignedURLFields } from './Types';

const instance = axios.create();

const initialize = () => {
	instance.interceptors.response.use(
		(response) => {
			// Use interception code here
			return response;
		},
		(error) => {
			return Promise.reject(error);
		},
	);
};

initialize();

const getFileFromUrl = async (url: string) => {
	return (await instance.get(url, { responseType: 'text' })).data;
};

const postPreSignedURL = async (url: string, fields: PresignedURLFields, file: File | null) => {
	const formData = new FormData();
	Object.entries(fields).forEach(([key, value]) => {
		formData.append(key, value);
	});
	if (file) {
		formData.append('file', file);
	}

	return instance.post(url, formData);
};

export { postPreSignedURL, getFileFromUrl };
