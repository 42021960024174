import React, { useEffect, useState } from 'react';
import { Clarification, ClarificationFormData } from '../../../../../core/clarifications/types';
import { Dialog, DialogContent } from '@mui/material';
import { editClarification, getClarificationData } from '../../../../../core/clarifications/services';
import ClarificationForm from '../../../../manager/ClarificationForm';
import _ from 'lodash';
import { LinearLoader } from '../../../../../design-library/LinearLoader';

export default function EditClarificationModal({
	isOpen,
	setIsOpen,
	onSuccess,
	clarificationId,
	isContestManager,
}: {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onSuccess: (clarification: Clarification) => void;
	clarificationId: number;
	isContestManager: boolean;
}) {
	const [isLoading, setIsLoading] = useState(false);
	const [clarification, setClarification] = useState<Clarification | null>(null);

	useEffect(() => {
		const fetchClarification = async () => {
			setIsLoading(true);
			const clarificationData = await getClarificationData(clarificationId);
			setClarification(clarificationData);
			setIsLoading(false);
		};
		fetchClarification();
	}, []);

	if (isLoading) return <LinearLoader />;

	return (
		<>
			{clarification && (
				<Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth={true} maxWidth={'md'}>
					<DialogContent sx={{ backgroundColor: 'white' }}>
						<RenderClarificationForm
							clarification={clarification!}
							setIsOpen={setIsOpen}
							onSuccess={onSuccess}
							isContestManager={isContestManager}
						/>
					</DialogContent>
				</Dialog>
			)}
		</>
	);
}

const RenderClarificationForm = ({
	clarification,
	setIsOpen,
	onSuccess,
	isContestManager,
}: {
	clarification: Clarification;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onSuccess: (clarification: Clarification) => void;
	isContestManager: boolean;
}) => {
	const initFormData: ClarificationFormData = {
		..._.pick(clarification, ['contest', 'question', 'answer', 'clarification_type', 'status']),
		problem_set: clarification.problem_set?.id.toString() ?? null,
	};

	const additionalData = {
		created_at: clarification.created_at,
		updated_at: clarification.updated_at,
		created_by: clarification.created_by,
		answered_by: clarification.answered_by,
	};

	const handleSubmit = async (data: ClarificationFormData) => {
		const newClar = await editClarification(clarification!.id, data);
		onSuccess(newClar);
		return newClar;
	};
	return (
		<ClarificationForm
			type='edit'
			initFormData={initFormData}
			handleSubmit={handleSubmit}
			onSuccess={() => setIsOpen(false)}
			additionalViewData={additionalData}
			isContestManager={isContestManager}
		/>
	);
};
