import React from 'react';
import { Typography, Grid } from '@mui/material';
import { ProblemSetMeta } from '../../../../core/contests/types';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import BugReportIcon from '@mui/icons-material/BugReport';
import Groups2Icon from '@mui/icons-material/Groups2';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { USER_ROLE_ENUMS, UserRole } from '../../../../core/user/types';

export function ProblemMetaDataItem({ problem, userRole }: ProblemMetaDataItemProps) {
	const hasEditAccess = [USER_ROLE_ENUMS.JUDGE, USER_ROLE_ENUMS.STAFF].includes(userRole);

	return (
		<Grid
			item
			container
			direction='row'
			columnSpacing={2}
			alignItems='center'
			alignContent='center'
			sx={{ opacity: problem.is_visible ? 1 : 0.6 }}
		>
			<Grid item xs={1} container justifyContent='center'>
				<Typography variant='h3' color='primary'>
					{problem.problem_order_character}
				</Typography>
			</Grid>
			<Grid item xs={9}>
				<Typography variant='h6' color='primary'>
					{problem.title}
				</Typography>
			</Grid>
			<Grid item container xs={2} spacing={2} direction='row' justifyContent='end'>
				{problem.submission_meta!.has_user_accepted ? (
					<Grid item xs='auto'>
						<TaskAltIcon color='success' />
					</Grid>
				) : problem.submission_meta!.user_tried > 0 ? (
					<Grid item xs='auto'>
						<BugReportIcon color='warning' />
					</Grid>
				) : null}
				{problem.submission_meta!.unique_users_accepted > 0 && (
					<Grid item container direction='row' xs={8} justifyContent={'end'} spacing={1}>
						<Grid item>
							<Groups2Icon color='primary' />
						</Grid>
						<Grid item>
							<Typography variant='h6' color='primary'>
								{problem.submission_meta!.unique_users_accepted}
							</Typography>
						</Grid>
					</Grid>
				)}
				{hasEditAccess && (
					<Grid item xs='auto'>
						{problem.is_visible ? <Visibility color='success' /> : <VisibilityOff color='success' />}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
}

type ProblemMetaDataItemProps = {
	problem: ProblemSetMeta;
	userRole: UserRole;
};
