import React, { useEffect } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { ContestRead } from '../../../../core/contests/types';
import ClarificationList from './ClarificationList';
import ClarificationsSidebar from './ClarificationsSidebar';
import { Clarification, ClarificationsParams } from '../../../../core/clarifications/types';
import { getClarifications } from '../../../../core/clarifications/services';
import { useTheme } from '@mui/material/styles';

export default function ClarificationListWithFilter({ contest }: { contest: ContestRead }) {
	const PAGE_SIZE = 25;
	const [page, setPage] = React.useState(1);
	const [clarifications, setClarifications] = React.useState<Clarification[]>([]);
	const [count, setCount] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(true);
	const [filterParams, setFilterParams] = React.useState<ClarificationsParams>({
		problem_set: null,
		clarification_type: null,
		status: null,
	});

	const fetchClarifications = (page: number) => {
		setIsLoading(true);
		getClarifications({
			contest: contest.id!,
			offset: (page - 1) * PAGE_SIZE,
			limit: PAGE_SIZE,
			...filterParams,
		})
			.then((clarificationsData) => {
				setClarifications([...clarificationsData.announcements, ...clarificationsData.results]);
				setCount(clarificationsData.count);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		fetchClarifications(1);
	}, []);

	const onPageChangeHandler = async (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
		await fetchClarifications(value);
	};

	const onFilterHandler = () => {
		setPage(1);
		fetchClarifications(1);
	};

	const theme = useTheme();
	const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'));
	const isContestManager = ['STAFF', 'JUDGE'].includes(contest.role);
	const onReloadPage = () => fetchClarifications(page);

	return (
		<Grid container direction={greaterThanMid ? 'row' : 'column-reverse'} spacing={4}>
			<Grid item xs={12} lg={9}>
				<ClarificationList
					isLoading={isLoading}
					contestId={contest.id!}
					canEdit={['STAFF', 'JUDGE'].includes(contest.role)}
					clarifications={clarifications}
					setClarifications={setClarifications}
					page={page}
					totalPages={Math.ceil(count / PAGE_SIZE)}
					onPageChangeHandler={onPageChangeHandler}
					isContestManager={isContestManager}
					onReloadPage={onReloadPage}
				/>
			</Grid>
			<Grid item xs={12} lg={3}>
				<ClarificationsSidebar
					contest={contest}
					filterParams={filterParams}
					setFilterParams={setFilterParams}
					onFilterHandler={onFilterHandler}
				/>
			</Grid>
		</Grid>
	);
}
