import React from 'react';
import { Container, Typography } from '@mui/material';
import { useTitle } from '../../contexts/TitleContext';

const PageNotFound = () => {
	const { setTitle } = useTitle();
	setTitle('Page Not Found!');
	return (
		<Container sx={{ marginTop: '43vh' }} maxWidth='sm'>
			<Typography
				sx={{
					fontSize: '2em',
					fontWeight: 300,
					'& strong': { fontWeight: 400 },
				}}
				variant='h1'
				align='center'
			>
				<strong>Error 404</strong>: Page not found
			</Typography>
		</Container>
	);
};

export default PageNotFound;
