import React from 'react';
import {
	Clarification,
	CLARIFICATION_STATUS_ENUMS,
	ClarificationFormData,
	ClarificationStatus,
} from '../../../../../core/clarifications/types';
import { Dialog, DialogContent } from '@mui/material';
import { createNewClarification } from '../../../../../core/clarifications/services';
import ClarificationForm from '../../../../manager/ClarificationForm';

export default function CreateNewClarificationModal({
	isOpen,
	setIsOpen,
	onSuccess,
	contestId,
	isContestManager,
}: {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onSuccess: (clarification: Clarification) => void;
	contestId: number;
	isContestManager: boolean;
}) {
	const initFormData: ClarificationFormData = {
		clarification_type: 'PROBLEM',
		question: '',
		answer: '',
		contest: contestId,
		problem_set: null,
		status: CLARIFICATION_STATUS_ENUMS.PENDING as ClarificationStatus,
	};

	const handleSubmit = async (data: ClarificationFormData) => {
		const newClar = await createNewClarification(data);
		onSuccess(newClar);
		return newClar;
	};

	return (
		<Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth={true} maxWidth={'md'}>
			<DialogContent sx={{ backgroundColor: 'white' }}>
				<ClarificationForm
					type='create'
					initFormData={initFormData}
					handleSubmit={handleSubmit}
					onSuccess={() => setIsOpen(false)}
					isContestManager={isContestManager}
				/>
			</DialogContent>
		</Dialog>
	);
}
