import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProblemOutline from './ProblemOutline';
import ProblemParameters from './ProblemParameters';
import ProblemDataset from './ProblemDataset';
import SampleIO from './SampleIO';
import { Problem } from '../../../core/problems/types';
import { useEffect, useState } from 'react';
import { getContestProblem } from '../../../core/problems/services';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { Skeleton } from '@mui/material';
import { ProblemLanguage } from '../../../core/languages/types';
import { getLanguages } from '../../../core/languages/services';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ padding: '20px' }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function EditProblemTabs({ problemId, problemSetId }: { problemId: number; problemSetId: number }) {
	const [value, setValue] = React.useState(0);
	const [problem, setProblem] = useState<Problem | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [problemLanguages, setProblemLanguages] = React.useState<ProblemLanguage[]>([]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		setIsLoading(true);
		getContestProblem(problemId, problemSetId)
			.then((data) => {
				setProblem(data);
			})
			.catch((e) => {
				console.log(e);
				if (axios.isAxiosError(e)) {
					enqueueSnackbar(`Problem could not be fetched!`, { variant: 'error' });
				}
			})
			.finally(() => setIsLoading(false));
	}, [problemId]);

	useEffect(() => {
		getLanguages().then((data) => {
			setProblemLanguages(data);
		});
	}, []);

	if (!problem || isLoading) return <Skeleton variant='rectangular' width={'100%'} height={600} />;

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
					<Tab label='Outline' {...a11yProps(0)} />
					<Tab label='Sample I/O' {...a11yProps(1)} />
					<Tab label='Parameters' {...a11yProps(2)} />
					<Tab label='Dataset' {...a11yProps(3)} />
					{/*<Tab label="Copy From" {...a11yProps(3)} />*/}
				</Tabs>
			</Box>
			<CustomTabPanel value={value} index={0}>
				<ProblemOutline problem={problem} setProblem={setProblem} />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1}>
				<SampleIO problem={problem} />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={2}>
				<ProblemParameters problem={problem} setProblem={setProblem} languages={problemLanguages} />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={3}>
				<ProblemDataset problem={problem} />
			</CustomTabPanel>
		</Box>
	);
}
