import React from 'react';
import { InputOutputSample, Problem } from '../../../../core/problems/types';
import { Grid, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SampleListItem from './SampleListItem';
import CreateSampleModal from './CreateSampleModal';

export default function SampleIO({ problem }: { problem: Problem }) {
	const [isSampleCreateModalOpen, setIsSampleCreateModalOpen] = React.useState<boolean>(false);
	const [inputOutputSamples, setInputOutputSamples] = React.useState<InputOutputSample[]>(
		problem.input_output_samples ?? [],
	);

	const handleSampleDeleteSuccess = (sampleId: number) => {
		setInputOutputSamples(inputOutputSamples.filter((sample) => sample.id !== sampleId));
	};

	const handleSampleCreateSuccess = (sample: InputOutputSample) => {
		setInputOutputSamples([...inputOutputSamples, sample]);
	};

	return (
		<Grid container direction='column' justifyContent='center' sx={{ width: '100%', gap: 2 }}>
			<Grid item container direction='row' spacing={2}>
				{inputOutputSamples.map((sample) => (
					<Grid item key={sample.id!} xs={12}>
						<SampleListItem
							sample={sample}
							onDeleteSuccess={handleSampleDeleteSuccess}
							problemSetId={problem.problem_set!.id}
						/>
					</Grid>
				))}
			</Grid>
			<Grid item>
				<Button startIcon={<AddIcon />} variant='contained' onClick={() => setIsSampleCreateModalOpen(true)}>
					Add New Sample
				</Button>
			</Grid>
			<CreateSampleModal
				isOpen={isSampleCreateModalOpen}
				onClose={() => setIsSampleCreateModalOpen(false)}
				problemId={problem.id!}
				onSuccess={handleSampleCreateSuccess}
				problemSetId={problem.problem_set!.id}
			/>
		</Grid>
	);
}
