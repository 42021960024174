export const solvedStatusStyle = {
	backgroundColor: '#33bd7a',
	color: '#EFEFEF',
	width: '100%',
};

export const unsolvedStatusStyle = {
	backgroundColor: '#f23030',
	color: '#EFEFEF',
	width: '100%',
	height: '20px',
};

export const statusCardStyle = {
	backgroundColor: '#3e3f39',
	color: '#EFEFEF',
	width: '100%',
	minWidth: '60px',
	maxWidth: '70px',
};

export const solveCountStyle = {
	backgroundColor: '#419ad8',
};

export const problemCellStyle = {
	borderBottom: 'none',
	minWidth: '60px',
	maxWidth: '70px',
};

export const tableHeaderStyle = {
	fontWeight: 'bold',
};

export const problemHeaderStyle = {
	width: '70px',
};
