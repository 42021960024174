import React, { ReactElement } from 'react';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { Navigate, Outlet } from 'react-router-dom';

export default function ContestManagerRoute(): ReactElement {
	const currentUser = useCurrentUser();

	if (!currentUser?.token || !currentUser?.is_superuser || !currentUser?.is_staff) {
		return <Navigate to='/logout' replace />;
	}

	return <Outlet />;
}
