import React, { PropsWithChildren, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { AuthorizedUser } from './types';
import { USER_DATA, USER_TOKEN } from '../store/constants';

export default function AuthProvider({ children }: PropsWithChildren) {
	const localUserData: string | null = localStorage.getItem(USER_DATA);
	const existingUser: AuthorizedUser | null = localUserData ? JSON.parse(localUserData) : null;
	const existingToken: string | null = localStorage.getItem(USER_TOKEN);

	const [user, setUser] = useState(existingUser);
	const [token, setToken] = useState(existingToken);

	const onLoginSuccess = (userInfo?: AuthorizedUser, newToken?: string) => {
		if (userInfo && newToken) {
			localStorage.setItem(USER_TOKEN, newToken);
			localStorage.setItem(USER_DATA, JSON.stringify(userInfo));
			setToken(newToken);
			setUser(userInfo);
		}
	};

	const onLogoutSuccess = () => {
		localStorage.removeItem(USER_TOKEN);
		localStorage.removeItem(USER_DATA);
		setUser(null);
		setToken(null);
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				token,
				onLoginSuccess,
				onLogoutSuccess,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
