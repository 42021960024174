import serverApi from '../serverApi';
import { ProblemSet } from './types';

const problemSetEndpoint = '/api/judge/problem-sets';

const getProblemSet = async (problemSetId: number) => {
	return (await serverApi.get<ProblemSet>(`${problemSetEndpoint}/${problemSetId}/`)).data;
};

const updateProblemSet = async (problemSetId: number, problemSet: Partial<ProblemSet>) => {
	return (await serverApi.patch<ProblemSet>(`${problemSetEndpoint}/${problemSetId}/`, problemSet)).data;
};

export { getProblemSet, updateProblemSet };
