import { Typography } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';

export function Logo() {
	return (
		<React.Fragment>
			<Box component='img' sx={{ height: 36 }} alt='BAPS' src={'/logo.svg'} />
			<Typography
				variant='h6'
				noWrap
				component='a'
				href='/'
				sx={{
					mr: 2,
					display: { xs: 'none', md: 'flex' },
					fontFamily: 'monospace',
					fontWeight: 700,
					letterSpacing: '.1rem',
					color: 'inherit',
					textDecoration: 'none',
				}}
			>
				BAPS
			</Typography>
		</React.Fragment>
	);
}
