const LocalStoreAccessor = function ({ namespace }: { namespace: string }) {
	const store = window.localStorage;

	const _generateKey = function (key: string): string {
		return namespace + '/' + key;
	};

	const get = function (key: string): string | null {
		const localKey = _generateKey(key);
		const value = store.getItem(localKey);
		if (value) {
			try {
				return JSON.parse(value);
			} catch (e) {
				return value;
			}
		}
		return null;
	};

	const set = function (key: string, value: object) {
		const localKey = _generateKey(key);
		try {
			store.setItem(localKey, JSON.stringify(value));
		} catch (e) {
			console.error(e);
		}
	};

	const remove = function (key: string) {
		const localKey = _generateKey(key);
		store.removeItem(localKey);
	};

	return {
		get: get,
		set: set,
		remove: remove,
	};
};

export default LocalStoreAccessor;
