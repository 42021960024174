import { Cancel, CheckCircle } from '@mui/icons-material';
import React, { useState } from 'react';
import {
	Alert,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { getContestData } from '../../../core/contests/services';
import { ContestRead, ProblemSetMeta } from '../../../core/contests/types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { syncAProblem } from '../../../core/problems/services';
import _ from 'lodash';

export default function CloneProblemDialog({
	open,
	setOpen,
	problemId,
}: {
	open: boolean;
	setOpen: (isOpen: boolean) => void;
	problemId: number;
}) {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState(false);

	const onCloneHandler = async () => {
		try {
			setIsLoading(true);
			const response = await syncAProblem(problemId, selectedProblem!);
			enqueueSnackbar(`Problem "${response.title}" cloned!`, {
				variant: 'success',
			});
			navigate(0);
		} catch (e) {
			if (axios.isAxiosError(e)) {
				enqueueSnackbar(`Problem could not be Synced!`, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [contestSlug, setContestSlug] = useState<string>('');
	const [isLoadingContestData, setIsLoadingContestData] = useState<boolean>(false);
	const [contestData, setContestData] = useState<ContestRead | undefined>(undefined);
	const [selectedProblem, setSelectedProblem] = useState<number | null>(null);

	const fetchContestData = (value: string) => {
		setIsLoadingContestData(true);
		getContestData(value)
			.then((data: ContestRead) => {
				setContestData(data);
				setSelectedProblem(null);
			})
			.catch((e) => {
				console.log(e);
				setContestData(undefined);
				setSelectedProblem(null);
			})
			.finally(() => setIsLoadingContestData(false));
	};

	const debouncedContestDataFetch = _.debounce(fetchContestData, 1000);

	const handleContestSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setContestSlug(value);
		if (value) debouncedContestDataFetch(value);
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Sync problem</DialogTitle>
			<DialogContent sx={{ minWidth: '600px', backgroundColor: 'white' }}>
				<DialogContentText>Sync this problem with a target problem.</DialogContentText>
				<Alert severity='warning' sx={{ marginTop: '5px' }}>
					Syncing to the problem will override all changes made here!
				</Alert>
				<Grid
					container
					direction='column'
					justifyContent='center'
					sx={{ width: '100%', padding: '35px', gap: 2 }}
					component={'form'}
				>
					<FormControl required fullWidth variant='outlined' error={!!contestSlug && !contestData}>
						<InputLabel htmlFor='outlined-contest-slug'>Contest Slug</InputLabel>
						<OutlinedInput
							id='outlined-contest-slug'
							endAdornment={
								<InputAdornment position='end'>
									{isLoadingContestData ? (
										<CircularProgress />
									) : contestData ? (
										<CheckCircle color={'success'} />
									) : contestSlug ? (
										<Cancel />
									) : null}
								</InputAdornment>
							}
							label='Contest Slug'
							value={contestSlug}
							onChange={handleContestSlugChange}
							// error={(!!contestSlug && !contestData)}
						/>
						{contestSlug && !contestData && <FormHelperText sx={{ color: 'red' }}>Contest not found!</FormHelperText>}
					</FormControl>
					{contestData?.problem_set?.length && (
						<FormControl sx={{ width: '100%' }}>
							<InputLabel id='select-problem-label'>Problem</InputLabel>
							<Select
								labelId='select-problem-label'
								id='select-problem'
								value={selectedProblem}
								label='Problem'
								onChange={(event) => setSelectedProblem(parseInt(event.target.value as string))}
							>
								{contestData.problem_set.map((problem_set: ProblemSetMeta) => (
									<MenuItem key={problem_set.id} value={problem_set.id}>
										{problem_set.problem_order_character}. {problem_set.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button disabled={isLoading} variant='outlined' onClick={handleClose}>
					Cancel
				</Button>
				<Button disabled={isLoading || !selectedProblem} variant='contained' onClick={onCloneHandler}>
					{isLoading ? <CircularProgress size={25} /> : 'Sync'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
