import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getContestData } from '../../../core/contests/services';
import { useParams } from 'react-router-dom';
import { ProblemSetMeta, ContestRead } from '../../../core/contests/types';
import ProblemSidebar from './ProblemSidebar';
import ProblemDescription from './ProblemDescription';
import { getContestProblem } from '../../../core/problems/services';
import { Problem } from '../../../core/problems/types';
import { LinearLoader } from '../../../design-library/LinearLoader';
import { useTitle } from '../../../contexts/TitleContext';
export default function ProblemPage() {
	const { setTitle } = useTitle();
	const { contestSlug = '', problemCharacter = '' } = useParams<{ contestSlug: string; problemCharacter: string }>();
	const [contest, setContest] = useState<ContestRead | undefined>(undefined);
	const [problemSet, setProblemSet] = useState<ProblemSetMeta | undefined>(undefined);
	const [problem, setProblem] = useState<Problem | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchContest = async () => {
			setIsLoading(true);
			const contestData = await getContestData(contestSlug);
			setContest(contestData);
			setIsLoading(false);
		};
		fetchContest();
	}, [contestSlug]);

	useEffect(() => {
		if (!!contest && !!problemCharacter) {
			setProblemSet(contest!.problem_set.find((problem) => problem.problem_order_character === problemCharacter));
		}
	}, [contest, problemCharacter]);

	useEffect(() => {
		const fetchProblem = async (problemId: number) => {
			setIsLoading(true);
			const problemData = await getContestProblem(problemId, problemSet!.id);
			setProblem(problemData);
			setIsLoading(false);
		};
		if (problemSet) fetchProblem(problemSet!.problem_id);
	}, [problemSet]);

	if (isLoading || !contest || !problem) {
		return <LinearLoader />;
	}

	setTitle(`${problem.problem_set?.order_character}. ${problem.title}`);

	const isEditable = ['STAFF', 'JUDGE'].includes(contest.role);

	return (
		<Grid container direction='row' spacing={4}>
			<Grid item xs={12} lg={9}>
				<ProblemDescription problem={problem} problemSet={problemSet!} isEditable={isEditable} />
			</Grid>
			<Grid item xs={12} lg={3}>
				<ProblemSidebar contest={contest} problemSetId={problemSet!.id} />
			</Grid>
		</Grid>
	);
}
