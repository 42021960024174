import React from 'react';
import { InputOutputSample } from '../../../../core/problems/types';
import { Divider, Grid, Typography } from '@mui/material';
import { SectionPaper } from '../../../../design-library';
import MarkdownView from '../../../../design-library/MarkdownView';

export default function ProblemSample({ sample }: { sample: InputOutputSample }) {
	return (
		<SectionPaper>
			<Grid item container direction='row' spacing={2}>
				<Grid item container direction='column' xs={6}>
					<Grid item>
						<Typography variant='body1' color='primary.main'>
							Input
						</Typography>
					</Grid>
					<Divider sx={{ marginY: '8px' }} />
					<Grid item sx={{ width: '100%' }}>
						<MarkdownView data={`\`\`\`text\n${sample.sample_input}\n\`\`\``} />
					</Grid>
				</Grid>
				<Grid item container direction='column' xs={6}>
					<Grid item>
						<Typography variant='body1' color='primary.main'>
							Output
						</Typography>
					</Grid>
					<Divider sx={{ marginY: '8px' }} />
					<Grid item sx={{ width: '100%' }}>
						<MarkdownView data={`\`\`\`text\n${sample.sample_output}\n\`\`\``} />
					</Grid>
				</Grid>
			</Grid>
		</SectionPaper>
	);
}
