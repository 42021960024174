import React from 'react';
import {
	Accordion,
	AccordionSummary,
	Button,
	CircularProgress,
	FormHelperText,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { Problem } from '../../../core/problems/types';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { editContestProblem } from '../../../core/problems/services';
import _ from 'lodash';
import MarkdownEdit from '../../../design-library/MarkdownEdit';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const OUTLINE_FIELDS: (keyof Problem)[] = [
	'title',
	'description',
	'input_description',
	'output_description',
	'constraints',
	'note',
];

type FieldToTitle = {
	[key in keyof Problem]: string;
};
const FIELD_TITLES: FieldToTitle = {
	title: 'Title',
	description: 'Description',
	input_description: 'Input Description',
	output_description: 'Output Description',
	constraints: 'Constraints',
	note: 'Notes',
};

export default function ProblemOutline({
	problem,
	setProblem,
}: {
	problem: Problem;
	setProblem: (value: Problem) => void;
}) {
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = React.useState(false);
	const [errorData, setErrorData] = React.useState<Partial<Problem>>({});
	const [formData, setFormData] = React.useState<Partial<Problem>>(_.pick(problem, OUTLINE_FIELDS));

	const handleValueChange = (event: React.FocusEvent<HTMLInputElement>) => {
		const { name, value, type, checked } = event.target;
		const updatedValue = type === 'checkbox' ? checked : value;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: updatedValue,
		}));
	};

	const handleNamedValueChange = (name: keyof Problem, value?: string) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const onSubmitHandler = () => {
		setIsLoading(true);
		editContestProblem(problem.id!, formData, problem.problem_set!.id)
			.then((data) => {
				enqueueSnackbar(`Problem edited successfully!`, {
					variant: 'success',
				});
				setProblem(data);
				setErrorData({});
			})
			.catch((e) => {
				if (axios.isAxiosError(e)) {
					setErrorData(e.response?.data ?? {});
					enqueueSnackbar(`Problem could not be edited!`, { variant: 'error' });
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Grid container direction='column' justifyContent='center' sx={{ width: '100%', gap: 2 }} component={'form'}>
			<TextField
				required
				id='problem-title'
				name='title'
				label='Title'
				variant='outlined'
				defaultValue={formData.title}
				onBlur={handleValueChange}
				error={!!errorData.title}
				helperText={errorData.title}
			/>
			{(['description', 'input_description', 'output_description', 'constraints', 'note'] as (keyof Problem)[]).map(
				(fieldName: keyof Problem) => (
					<Accordion key={fieldName}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>{FIELD_TITLES[fieldName]}</Typography>
						</AccordionSummary>
						<MarkdownEdit
							value={formData[fieldName] as string}
							setValue={(value?: string) => handleNamedValueChange(fieldName, value)}
						/>
						{!!errorData[fieldName] && <FormHelperText error>{(errorData[fieldName] as string) ?? ''}</FormHelperText>}
					</Accordion>
				),
			)}
			<Button disabled={isLoading} variant='contained' onClick={onSubmitHandler} size='large'>
				{isLoading ? <CircularProgress size={25} /> : `Save`}
			</Button>
		</Grid>
	);
}
