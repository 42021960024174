import React from 'react';
import Typography from '@mui/material/Typography';
import { SectionPaper, SectionTitle } from '../../../../design-library';
import { ContestRead } from '../../../../core/contests/types';
import { Grid } from '@mui/material';
import SideBarBriefAndTimer from '../../SideBarBriefAndTimer';

export default function DashboardSidebar({ contest }: { contest: ContestRead }) {
	return (
		<Grid container direction='column' rowSpacing={2}>
			<Grid item>
				<SideBarBriefAndTimer contest={contest} />
			</Grid>
			<Grid item container direction='column' rowSpacing='2px'>
				<Grid item>
					<SectionTitle title='Description' size='small' />
				</Grid>
				<Grid item>
					<SectionPaper>
						<Typography variant='subtitle2' color='primary.main'>
							{contest.description}
						</Typography>
					</SectionPaper>
				</Grid>
			</Grid>
			<Grid item container direction='column' rowSpacing='2px'>
				<Grid item>
					<SectionTitle title='Disclaimer' size='small' />
				</Grid>
				<Grid item>
					<SectionPaper>
						<Typography variant='subtitle2' color='primary.main'>
							BAPS owns the absolute right of the problems and platform. If you find any copyright violations or
							otherwise please let us know at: <strong>info@bapsoj.org</strong>.
						</Typography>
					</SectionPaper>
				</Grid>
			</Grid>
			{/*<Grid item container direction='column' rowSpacing='2px'>*/}
			{/*	<Grid item>*/}
			{/*		<SectionTitle title='Practice' size='small' />*/}
			{/*	</Grid>*/}
			{/*	<Grid item></Grid>*/}
			{/*</Grid>*/}
			{/*<Grid item container direction='column' rowSpacing='2px'>*/}
			{/*	<Grid item>*/}
			{/*		<SectionTitle title='Resource' size='small' />*/}
			{/*	</Grid>*/}
			{/*	<Grid item></Grid>*/}
			{/*</Grid>*/}
		</Grid>
	);
}
