import React from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../contexts/TitleContext';

export default function ManageContests() {
	const navigate = useNavigate();
	const { setTitle } = useTitle();
	setTitle('Manage Contests');
	return (
		<Grid container alignItems='center' justifyContent='center'>
			<Grid item container xs={12} justifyContent='center'>
				<Button size={'large'} variant={'contained'} color={'primary'} onClick={() => navigate('/contests/create')}>
					Create New Contest
				</Button>
			</Grid>
		</Grid>
	);
}
