import React, { useEffect, useState } from 'react';
import {
	Grid,
	Button,
	CircularProgress,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	FormHelperText,
	Alert,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import {
	Clarification,
	CLARIFICATION_STATUS_ENUMS,
	CLARIFICATION_TYPE_ENUMS,
	ClarificationFormData,
	ClarificationStatus,
	ClarificationType,
	ClarificationTypeLabels,
	ClarificationTypes,
	ClarificationUser,
} from '../../core/clarifications/types';
import { ContestRead, ProblemSetMeta } from '../../core/contests/types';
import { getContestData } from '../../core/contests/services';
import MarkdownEdit from '../../design-library/MarkdownEdit';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import Tooltip from '@mui/material/Tooltip';
import { LinearLoader } from '../../design-library/LinearLoader';

export default function ClarificationForm({
	type,
	initFormData,
	handleSubmit,
	onSuccess,
	additionalViewData,
	isContestManager,
}: {
	type: 'create' | 'edit';
	initFormData: ClarificationFormData;
	handleSubmit: (data: ClarificationFormData) => Promise<Clarification>;
	onSuccess: () => void;
	additionalViewData?: {
		created_at: Date;
		updated_at: Date;
		created_by: ClarificationUser | null;
		answered_by: ClarificationUser | null;
	};
	isContestManager: boolean;
}) {
	const { contestSlug = '' } = useParams<{ contestSlug: string }>();
	const { enqueueSnackbar } = useSnackbar();
	const [formData, setFormData] = useState<ClarificationFormData>(initFormData);
	const [errorData, setErrorData] = useState<{ [key in keyof ClarificationFormData]?: string[] }>({});
	const [isLoading, setIsLoading] = useState(false);
	const [contest, setContest] = useState<ContestRead | null>(null);

	useEffect(() => {
		const fetchContest = async () => {
			setIsLoading(true);
			const contestData = await getContestData(contestSlug);
			setContest(contestData);
			setIsLoading(false);
		};
		fetchContest();
	}, [contestSlug]);

	if (!contest) {
		return <LinearLoader />;
	}

	const handleNamedValueChange = (name: keyof ClarificationFormData, value?: string) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleProblemSelect = (event: SelectChangeEvent<string | null>) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			problem_set: event.target.value as string,
		}));
	};

	const handleTypeSelect = (event: SelectChangeEvent<string>) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			clarification_type: event.target.value as ClarificationType,
			problem_set: [CLARIFICATION_TYPE_ENUMS.CONTEST, CLARIFICATION_TYPE_ENUMS.ANNOUNCEMENT].includes(
				event.target.value,
			)
				? null
				: prevFormData.problem_set,
		}));
	};

	const onSubmitHandler = async (action?: ClarificationStatus) => {
		const updatedStatus = action
			? action
			: formData.answer!.length > 0
			? (CLARIFICATION_STATUS_ENUMS.ANSWERED as ClarificationStatus)
			: CLARIFICATION_STATUS_ENUMS.PENDING;

		try {
			setIsLoading(true);
			await handleSubmit({
				...formData,
				status: updatedStatus as ClarificationStatus,
			});
			enqueueSnackbar(`Clarification ${type === 'create' ? 'created' : 'edited'} successfully!`, {
				variant: 'success',
			});
			onSuccess();
		} catch (e) {
			if (axios.isAxiosError(e)) {
				setErrorData(e.response?.data ?? {});
				enqueueSnackbar(`Clarification could not be ${type === 'create' ? 'created' : 'edited'}!`, {
					variant: 'error',
				});
			} else {
				enqueueSnackbar(`Something went wrong!`, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
		}
	};

	const isAnswerable = ['STAFF', 'JUDGE'].includes(contest.role);
	const currentAnswerStatusColor =
		formData.status === CLARIFICATION_STATUS_ENUMS.ANSWERED
			? 'success'
			: formData.status === CLARIFICATION_STATUS_ENUMS.IGNORED
			? 'error'
			: 'info';

	const clarTypesToShow = ClarificationTypes;
	if (isContestManager) {
		clarTypesToShow.filter((type) => type !== CLARIFICATION_TYPE_ENUMS.ANNOUNCEMENT);
	}

	return (
		<Grid container alignItems='center' justifyContent='center' sx={{ width: '100%' }}>
			<Grid item container direction='row' xs={12} rowSpacing={'2px'}>
				<Grid item xs={12}>
					<Typography variant={'h4'} color={'primary'}>
						{' '}
						{`${type === 'create' ? 'Create new' : 'Edit'} clarification`}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid
						container
						direction='column'
						justifyContent='center'
						sx={{ width: '100%', padding: '16px', gap: 2 }}
						component={'form'}
					>
						<Grid container direction={'row'} sx={{ width: '100%' }} spacing={2}>
							<Grid item xs={4}>
								<FormControl sx={{ width: '100%' }}>
									<InputLabel id='select-clarification-type-label'>Type</InputLabel>
									<Select
										labelId='select-clarification-type-label'
										id='select-clarification-type'
										value={formData.clarification_type}
										label='Type'
										disabled={isLoading}
										onChange={handleTypeSelect}
									>
										{clarTypesToShow.map((type) => (
											<MenuItem key={type} value={type}>
												{ClarificationTypeLabels[type]}
											</MenuItem>
										))}
									</Select>
									{!!errorData.clarification_type && (
										<FormHelperText error>{errorData.clarification_type ?? ''}</FormHelperText>
									)}
								</FormControl>
							</Grid>
							{![CLARIFICATION_TYPE_ENUMS.CONTEST, CLARIFICATION_TYPE_ENUMS.ANNOUNCEMENT].includes(
								formData.clarification_type,
							) && (
								<Grid item xs={8}>
									<FormControl sx={{ width: '100%' }}>
										<InputLabel id='select-problem-label'>Problem</InputLabel>
										<Select
											labelId='select-problem-label'
											id='select-problem'
											value={formData.problem_set as string}
											label='Problem'
											disabled={isLoading}
											onChange={handleProblemSelect}
										>
											{!isLoading &&
												contest?.problem_set.map((problem_set: ProblemSetMeta) => (
													<MenuItem key={problem_set.id} value={problem_set.id}>
														{problem_set.problem_order_character}. {problem_set.title}
													</MenuItem>
												))}
										</Select>
										{!!errorData.problem_set && <FormHelperText error>{errorData.problem_set ?? ''}</FormHelperText>}
									</FormControl>
								</Grid>
							)}
						</Grid>

						<div>
							<InputLabel>Question/Title</InputLabel>
							<MarkdownEdit
								value={formData.question as string}
								setValue={(value?: string) => handleNamedValueChange('question', value)}
							/>
							{!!errorData.answer && <FormHelperText error>{errorData.question ?? ''}</FormHelperText>}
						</div>
						{isAnswerable && (
							<div>
								<InputLabel>Answer</InputLabel>
								<MarkdownEdit
									value={formData.answer as string}
									setValue={(value?: string) => handleNamedValueChange('answer', value)}
								/>
								{!!errorData.answer && <FormHelperText error>{errorData.answer ?? ''}</FormHelperText>}
							</div>
						)}
						{type === 'create' && (
							<Button disabled={isLoading} variant='contained' onClick={() => onSubmitHandler()} size='large'>
								{isLoading ? <CircularProgress size={25} /> : `Create New Clarification`}
							</Button>
						)}
						{type === 'edit' && (
							<Alert severity={currentAnswerStatusColor}>
								This clarification is currently {_.lowerCase(formData.status)}!
							</Alert>
						)}
						{type === 'edit' && additionalViewData && (
							<Grid item xs={12} container justifyContent={'end'} spacing={2}>
								{additionalViewData.answered_by && (
									<Grid item>
										<Tooltip title={additionalViewData.answered_by!.username}>
											<Typography variant={'subtitle2'}>
												{`Answered by: ${
													additionalViewData.answered_by!.full_name || additionalViewData.answered_by!.username
												}`}
											</Typography>
										</Tooltip>
									</Grid>
								)}
								<Grid item>
									<Tooltip title={additionalViewData.created_by!.username}>
										<Typography variant={'subtitle2'}>
											{`Created by: ${
												additionalViewData.created_by!.full_name || additionalViewData.created_by!.username
											}`}
										</Typography>
									</Tooltip>
								</Grid>
								<Grid item>
									<Tooltip title={moment(additionalViewData.created_at).format('llll')}>
										<Typography variant={'subtitle2'}>
											Created at: {moment(additionalViewData.created_at).fromNow().toString()}
										</Typography>
									</Tooltip>
								</Grid>
								<Grid item>
									<Tooltip title={moment(additionalViewData.updated_at).format('llll')}>
										<Typography variant={'subtitle2'}>
											Updated at: {moment(additionalViewData.updated_at).fromNow().toString()}
										</Typography>
									</Tooltip>
								</Grid>
							</Grid>
						)}
						{type === 'edit' && (
							<Grid container spacing={2} justifyContent={'end'}>
								<Grid item>
									<Button
										disabled={isLoading}
										variant='outlined'
										color='error'
										onClick={() => onSubmitHandler(CLARIFICATION_STATUS_ENUMS.IGNORED as ClarificationStatus)}
										size='large'
									>
										{isLoading ? <CircularProgress size={25} /> : `Ignore`}
									</Button>
								</Grid>
								{formData.status !== CLARIFICATION_STATUS_ENUMS.PENDING && (
									<Grid item>
										<Button
											disabled={isLoading}
											variant='outlined'
											color='info'
											onClick={() => onSubmitHandler(CLARIFICATION_STATUS_ENUMS.PENDING as ClarificationStatus)}
											size='large'
										>
											{isLoading ? <CircularProgress size={25} /> : `Unanswer`}
										</Button>
									</Grid>
								)}
								<Grid item>
									<Button disabled={isLoading} variant='contained' onClick={() => onSubmitHandler()} size='large'>
										{isLoading ? <CircularProgress size={25} /> : `Answer`}
									</Button>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
