import { Table } from '@mui/material';
import React from 'react';
import { TableStyle } from '../styles';
import TestDetailsTableBody from './TestDetailsTableBody';
import TestDetailsTableHead from './TestDetailsTableHead';
import { TestDetail } from '../../../../../core/submissions/types';

interface Props {
	contestSlug: string;
	testsDetails: TestDetail[];
}

function TestDetailsTable({ contestSlug, testsDetails }: Props) {
	return (
		<Table style={TableStyle}>
			<TestDetailsTableHead />
			<TestDetailsTableBody contestSlug={contestSlug} testsDetails={testsDetails} />
		</Table>
	);
}

export default TestDetailsTable;
