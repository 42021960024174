import React, { useEffect } from 'react';
import { editContest, getContestData } from '../../core/contests/services';
import { Contest } from '../../core/contests/types';
import ContestForm from './ContestForm';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import { useTitle } from '../../contexts/TitleContext';

export default function EditContest() {
	const { setTitle } = useTitle();
	const { contestSlug } = useParams();
	setTitle(`${contestSlug} | Edit`);

	const [initFormData, setInitFormData] = React.useState<Contest | undefined>(undefined);
	useEffect(() => {
		const fetchContest = async () => {
			const contestData = await getContestData(contestSlug!);
			setInitFormData({
				...contestData,
				starts_at: moment(contestData.starts_at),
				ends_at: moment(contestData.ends_at),
			});
		};
		fetchContest();
	}, [contestSlug]);

	const onSubmitHandler = async (formData: Contest) => {
		if (initFormData?.id !== undefined) {
			return await editContest(initFormData.id, formData);
		} else {
			throw new Error('Contest id is invalid');
		}
	};

	return (
		<div>
			{initFormData && <ContestForm type={'edit'} initFormData={initFormData} handleSubmit={onSubmitHandler} />}
		</div>
	);
}
