import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getContestData } from '../../../core/contests/services';
import { ContestRead } from '../../../core/contests/types';
import { CloneProblemButton } from '../../manager/CloneProblem/CloneProblemButton';
import ProblemList from './ProblemList';
import DashboardSidebar from './DashboardSidebar';
import { CreateProblemButton } from '../../manager/CreateProblem/CreateProblemButton';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import ContestNotStartedPage from '../ContestNotStartedPage';
import { LinearLoader } from '../../../design-library/LinearLoader';
import { useTitle } from '../../../contexts/TitleContext';

export default function ContestDashboard() {
	const { setTitle } = useTitle();
	const { contestSlug = '' } = useParams<{ contestSlug: string }>();
	const [contest, setContest] = useState<ContestRead | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchContest = async () => {
			setIsLoading(true);
			const contestData = await getContestData(contestSlug);
			setContest(contestData);
			setIsLoading(false);
		};
		fetchContest();
	}, [contestSlug]);

	const theme = useTheme();
	const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'));

	if (isLoading || !contest) {
		return <LinearLoader />;
	}
	setTitle(`${contest.title}`);

	const hasEditAccess = ['STAFF', 'JUDGE'].includes(contest.role);
	const contestNotStarted = moment(contest.starts_at).isAfter(moment());
	if (contestNotStarted && !hasEditAccess) return <ContestNotStartedPage contest={contest} />;

	return (
		<Grid container direction={greaterThanMid ? 'row' : 'column-reverse'} spacing={4}>
			<Grid item xs={9}>
				<ProblemList contestSlug={contestSlug} problemSet={contest.problem_set ?? []} userRole={contest.role} />
				{hasEditAccess && (
					<Grid container direction={'row'} spacing={2} justifyContent={'end'}>
						<Grid item>
							<CloneProblemButton contestId={contest.id!} />
						</Grid>
						<Grid item>
							<CreateProblemButton contestId={contest.id!} contestSlug={contest.slug} />
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid item xs={3}>
				<DashboardSidebar contest={contest} />
			</Grid>
		</Grid>
	);
}
