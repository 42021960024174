import React from 'react';

import { GridRenderCellParams } from '@mui/x-data-grid';
import MemoryCell from './itemComponents/MemoryCell';

export default function RenderMemory(props: GridRenderCellParams) {
	const { value } = props;

	return <MemoryCell memory={value} />;
}
