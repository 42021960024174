import React from 'react';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import { deleteContestDataset } from '../../../../core/datasets/services';

export default function DeleteDatasetModal({
	isOpen,
	onClose,
	datasetId,
	title,
	onDeleteSuccess,
	problemSetId,
}: {
	isOpen: boolean;
	onClose: () => void;
	datasetId: number;
	title: string;
	onDeleteSuccess: (datasetId: number) => void;
	problemSetId: number;
}) {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const handleDeleteConfirmation = async () => {
		setIsLoading(true);
		try {
			await deleteContestDataset(datasetId, problemSetId);
			enqueueSnackbar(`Dataset deleted successfully!`, { variant: 'success' });
			onDeleteSuccess(datasetId);
		} catch (e) {
			if (axios.isAxiosError(e)) {
				enqueueSnackbar(`Dataset could not be deleted!`, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
			onClose();
		}
	};

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Delete Dataset</DialogTitle>
			<DialogContent sx={{ backgroundColor: 'white' }}>
				<DialogContentText>Are you sure you want to delete this dataset {`"${title}"`}?</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button disabled={isLoading} variant='contained' onClick={onClose}>
					Cancel
				</Button>
				<Button disabled={isLoading} variant='outlined' onClick={handleDeleteConfirmation}>
					{isLoading ? <CircularProgress size={25} /> : `Delete`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
