import { useContext } from 'react';
import { AuthContext, AuthContextType } from '../contexts/AuthContext';

export const useCurrentUser = () => {
	const { user, token }: AuthContextType = useContext(AuthContext);

	if (!user || !token) {
		return null;
	}

	return {
		...user,
		token,
	};
};
