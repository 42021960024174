import React from 'react';
import { Problem } from '../../../../core/problems/types';
import { Grid, IconButton, Typography } from '@mui/material';
import { SectionPaper } from '../../../../design-library';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { ProblemSetMeta } from '../../../../core/contests/types';
import ProblemSample from './ProblemSample';
import MarkdownView from '../../../../design-library/MarkdownView';

export default function ProblemDescription({
	problem,
	problemSet,
	isEditable,
}: {
	problem: Problem;
	problemSet: ProblemSetMeta;
	isEditable: boolean;
}) {
	const navigate = useNavigate();
	const { contestSlug = '' } = useParams<{ contestSlug: string }>();

	const handleOnEditProblem = () => {
		navigate(`/contests/${contestSlug}/problems/${problemSet.problem_order_character!}/edit`);
	};

	return (
		<Grid>
			<SectionPaper>
				<Grid container direction='column' rowSpacing={6}>
					{/* for problem meta */}
					<Grid container item direction='column' rowSpacing={4}>
						<Grid item container direction='row' spacing={0.3}>
							<Grid item xs>
								<Typography variant='h4' color='primary.main'>
									{problemSet.problem_order_character}. {problem.title}
								</Typography>
							</Grid>
							<Grid item xs='auto'>
								{isEditable && (
									<IconButton size='medium' onClick={handleOnEditProblem} color='primary'>
										<EditIcon fontSize='medium' />
									</IconButton>
								)}
							</Grid>
						</Grid>
						<Grid item container direction='row' spacing={2} justifyContent='space-between'>
							{/*<Grid item>*/}
							{/*	<Typography color='primary.main'>Score: {problem.score.toFixed(1)}</Typography>*/}
							{/*</Grid>*/}
							<Grid item>
								<Typography color='primary.main'>CPU: {problem.time_limit?.toFixed(2) ?? 'n/a'}s</Typography>
							</Grid>
							<Grid item>
								<Typography color='primary.main'>Memory: {problem.memory_limit?.toFixed(0) ?? 'n/a'}MB</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid container item>
						<MarkdownView data={problem.description!} />
					</Grid>
					<Grid container item direction='column' rowSpacing={2}>
						{/* for problem input description */}
						<Grid item>
							<Typography variant='h5' color='primary.main'>
								Input Description
							</Typography>
						</Grid>
						<Grid item>
							<MarkdownView data={problem.input_description!} />
						</Grid>
					</Grid>
					<Grid container item direction='column' rowSpacing={2}>
						{/* for problem output description */}
						<Grid item>
							<Typography variant='h5' color='primary.main'>
								Output Description
							</Typography>
						</Grid>
						<Grid item>
							<MarkdownView data={problem.output_description!} />
						</Grid>
					</Grid>
					{problem.constraints && (
						<Grid container item direction='column' rowSpacing={2}>
							{/* for problem constraints */}
							<Grid item>
								<Typography variant='h5' color='primary.main'>
									Constraints
								</Typography>
							</Grid>
							<Grid item>
								<MarkdownView data={problem.constraints!} />
							</Grid>
						</Grid>
					)}
					<Grid container item direction='column' spacing={2}>
						{/* for sample input outputs */}
						<Grid item>
							<Typography variant='h5' color='primary.main'>
								Samples
							</Typography>
						</Grid>
						{problem.input_output_samples!.map((sample) => (
							<Grid item key={sample.id!}>
								<ProblemSample sample={sample} />
							</Grid>
						))}
					</Grid>
					{problem.note && (
						<Grid container item direction='column' rowSpacing={2}>
							{/* for problem constraints */}
							<Grid item>
								<Typography variant='h5' color='primary.main'>
									Note
								</Typography>
							</Grid>
							<Grid item>
								<MarkdownView data={problem.note!} />
							</Grid>
						</Grid>
					)}
				</Grid>
			</SectionPaper>
		</Grid>
	);
}
