import { TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TestDetailsTableRow from './TestDetailsTableRow';
import { TestDetail } from '../../../../../core/submissions/types';
import { ContestRead } from '../../../../../core/contests/types';
import { getContestData } from '../../../../../core/contests/services';
import _ from 'lodash';

interface Props {
	contestSlug: string;
	testsDetails: TestDetail[];
}

function TestDetailsTableBody({ contestSlug, testsDetails }: Props) {
	const [contest, setContest] = useState<ContestRead | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchContest = async () => {
			setIsLoading(true);
			const contestData = await getContestData(contestSlug);
			setContest(contestData);
			setIsLoading(false);
		};
		fetchContest();
	}, [contestSlug]);

	if (isLoading || !contest) return <></>;
	const isJudge = ['STAFF', 'JUDGE'].includes(contest.role);

	if (_.isNil(testsDetails)) {
		return <></>;
	}

	return (
		<TableBody>
			{testsDetails.map((detail, index) => (
				<TestDetailsTableRow key={index} isJudge={isJudge} testDetail={detail} index={index} />
			))}
		</TableBody>
	);
}

export default TestDetailsTableBody;
