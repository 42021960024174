import { ProblemStatus, StandingsItem } from '../../../core/standings/types';
import { Card, TableCell, TableRow } from '@mui/material';
import React from 'react';
import ProblemCell from './ProblemCell';
import { problemHeaderStyle, solveCountStyle, statusCardStyle } from './styles';
import Tooltip from '@mui/material/Tooltip';

interface Props {
	offset: number;
	index: number;
	data: StandingsItem;
}

function StandingsRow({ offset, index, data }: Props) {
	return (
		<TableRow
			sx={{
				'&:hover': {
					backgroundColor: '#f5f5f5',
				},
			}}
		>
			<TableCell align={'center'}>
				<strong style={{ fontSize: '16px', color: 'rgb(0,70,101)' }}>{offset + index + 1}</strong>
			</TableCell>
			<TableCell>
				<Tooltip title={data.username}>
					<strong style={{ fontSize: '16px', color: 'rgb(0,73,110)' }}>{data.fullname}</strong>
				</Tooltip>
				<div style={{ fontSize: '12px', color: 'rgb(110,110,110)' }}>{data.institution?.toUpperCase()}</div>
			</TableCell>
			<TableCell align={'center'} style={problemHeaderStyle}>
				<Card style={statusCardStyle}>
					<div style={solveCountStyle}>
						{data.problem_list.filter((problem: ProblemStatus) => problem.is_solved).length}
					</div>
					<div>({data.problem_list.reduce((acc, problem) => acc + (problem.is_solved ? problem.fine : 0), 0)})</div>
				</Card>
			</TableCell>
			{data.problem_list.map((status, index) => (
				<TableCell align={'center'} key={index}>
					<ProblemCell {...status} />
				</TableCell>
			))}
		</TableRow>
	);
}

export default StandingsRow;
