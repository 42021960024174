import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../contexts/TitleContext';

export default function Logout() {
	const { setTitle } = useTitle();
	setTitle('Logout');

	const { onLogoutSuccess } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		onLogoutSuccess();
		navigate('/');
	});

	return <></>;
}
