import { ProblemType } from './types';

export enum PROBLEM_TYPE_ENUMS {
	REGULAR = 'REGULAR',
	SPECIAL = 'SPECIAL',
}

export const ProblemTypes = Object.keys(PROBLEM_TYPE_ENUMS) as ProblemType[];

export const PROBLEM_TYPE_LABELS = {
	[PROBLEM_TYPE_ENUMS.REGULAR]: 'Regular',
	[PROBLEM_TYPE_ENUMS.SPECIAL]: 'Special',
};
