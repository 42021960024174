import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { AuthorizationResponse } from '../../core/user/types';
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import { SectionPaper, SectionTitle } from '../../design-library';
import { useNavigate } from 'react-router-dom';
import { login } from '../../core/user/services';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { forEach } from 'lodash';
import { useTitle } from '../../contexts/TitleContext';

export default function Login() {
	const { setTitle } = useTitle();
	setTitle('Login');
	const { onLoginSuccess } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSubmitHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		try {
			setIsLoading(true);
			const { auth_token, user }: AuthorizationResponse = await login(username, password);
			onLoginSuccess(user, auth_token);
			enqueueSnackbar(`Welcome ${user.username}!`, { variant: 'success', autoHideDuration: 3000 });
			setTimeout(() => {
				navigate('/', { replace: true });
			}, 500);
		} catch (e) {
			if (axios.isAxiosError(e)) {
				const nonFieldErrors: string[] = e.response?.data.non_field_errors;
				forEach(nonFieldErrors, (error) => {
					enqueueSnackbar(error, { variant: 'error' });
				});
			} else {
				enqueueSnackbar(`Something went wrong!`, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Grid container alignItems='center' justifyContent='center'>
			<Grid item container direction='row' xs={4} sx={{ marginY: '100px', minWidth: '450px' }} rowSpacing={'2px'}>
				<Grid item xs={12}>
					<SectionTitle title='Login' size='small' />
				</Grid>
				<Grid item xs={12}>
					<SectionPaper>
						<Grid
							container
							direction='column'
							justifyContent='center'
							sx={{ width: '100%', padding: '35px', gap: 2 }}
							component={'form'}
						>
							<TextField
								autoFocus
								id='outlined-basic'
								label='Username'
								variant='outlined'
								value={username}
								onChange={(event) => setUsername(event.target.value)}
							/>
							<TextField
								id='outlined-basic'
								label='Password'
								variant='outlined'
								type='password'
								value={password}
								onChange={(event) => setPassword(event.target.value)}
							/>
							<Button disabled={isLoading} variant='contained' onClick={onSubmitHandler} size='large' type={'submit'}>
								{isLoading ? <CircularProgress size='26px' color='inherit' /> : 'Login'}
							</Button>
							{/*<Box sx={{ gap: 2 }} />*/}
							{/*<Link to='/reset' underline='none' component={NavLink}>*/}
							{/*	<Typography align='center'>Forgot password?</Typography>*/}
							{/*</Link>*/}
						</Grid>
					</SectionPaper>
				</Grid>
			</Grid>
		</Grid>
	);
}
