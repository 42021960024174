import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getContestData } from '../../../core/contests/services';
import { ContestRead } from '../../../core/contests/types';
import ClarificationListWithFilter from './ClarificationListWithFilter';
import moment from 'moment/moment';
import ContestNotStartedPage from '../ContestNotStartedPage';
import { LinearLoader } from '../../../design-library/LinearLoader';
import { useTitle } from '../../../contexts/TitleContext';

export default function ContestClarifications() {
	const { setTitle } = useTitle();
	setTitle('Clarifications');
	const { contestSlug = '' } = useParams<{ contestSlug: string }>();
	const [contest, setContest] = useState<ContestRead | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchContest = async () => {
			setIsLoading(true);
			const contestData = await getContestData(contestSlug);
			setContest(contestData);
			setIsLoading(false);
		};
		fetchContest();
	}, [contestSlug]);

	if (isLoading || !contest) {
		return <LinearLoader />;
	}

	const hasEditAccess = ['STAFF', 'JUDGE'].includes(contest.role);
	const contestNotStarted = moment(contest.starts_at).isAfter(moment());
	if (contestNotStarted && !hasEditAccess) return <ContestNotStartedPage contest={contest} />;

	return <ClarificationListWithFilter contest={contest} />;
}
