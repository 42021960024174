import { ProblemStatus } from '../../../core/standings/types';
import { Box } from '@mui/material';
import SolveStatus from './SolveStatus';
import React from 'react';
import { problemCellStyle } from './styles';

function ProblemCell(problem: ProblemStatus) {
	return (
		<Box style={problemCellStyle}>
			{problem.total_tries === 0 ? (
				'' // Problem not attempted
			) : (
				<SolveStatus {...problem} />
			)}
		</Box>
	);
}

export default ProblemCell;
