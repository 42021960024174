import React from 'react';
import { Contest } from '../../../core/contests/types';
import { Typography, Grid } from '@mui/material';
import moment from 'moment';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CountdownTimer from '../../../design-library/CountdownTimer/CountdownTimer';

export function ContestItem({ contest }: { contest: Contest }) {
	const startTime = contest.starts_at ? moment(contest.starts_at) : null;

	function hoursToHoursMinutes(decimalHours: number) {
		if (isNaN(decimalHours)) {
			return 'Invalid input';
		}

		const hours = Math.floor(decimalHours);
		const minutes = Math.round((decimalHours - hours) * 60);

		const formattedHours = String(hours).padStart(2, '0');
		const formattedMinutes = String(minutes).padStart(2, '0');

		return `${formattedHours}:${formattedMinutes}`;
	}

	return (
		<Grid container direction='row' alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
			<Grid item container direction='column' alignItems='start' xs={'auto'}>
				<Grid item>
					<Typography variant='h6' color='primary' sx={{ fontWeight: 700 }}>
						{contest.title}
						&nbsp;&nbsp;
						{contest.contest_visibility !== 'PUBLIC' && <VisibilityOffIcon />}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body1' color={'primary'}>
						{startTime ? startTime.toString() : 'Time is not set yet'} {'||'} Duration:{' '}
						{contest.duration ? `${hoursToHoursMinutes(contest.duration)}Hrs` : ''}
					</Typography>
				</Grid>
			</Grid>
			<Grid container item direction={'row'} alignItems={'center'} justifyContent={'end'} spacing={2} xs={'auto'}>
				<Grid item>
					<CountdownTimer starts_at={contest.starts_at} stops_at={contest.ends_at} inline />
				</Grid>
			</Grid>
		</Grid>
	);
}
