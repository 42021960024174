import serverApi from '../serverApi';
import {
	GetSubmissionsParams,
	PaginatedSubmissions,
	RejudgeSubmissionsParams,
	SubmissionResponse,
	SubmissionStatus,
} from './types';

const submissionsEndpoint = '/api/judge/submissions';

const getSubmissions = async (params: GetSubmissionsParams) => {
	return (await serverApi.get<PaginatedSubmissions>(`${submissionsEndpoint}/`, { params }))
		.data as PaginatedSubmissions;
};

const getSubmissionDetails = async (submissionId: string | undefined) => {
	return (await serverApi.get(`${submissionsEndpoint}/${submissionId}/`)).data;
};

const createSubmission = async (body: { language: string; problem_set: number }) => {
	return (await serverApi.post(`${submissionsEndpoint}/`, body)).data;
};

const acknowledgeSubmission = async (submissionId: number) => {
	return serverApi.patch(`${submissionsEndpoint}/${submissionId}/`, { status: 'QUEUED' });
};

const updateSubmissionStatus = async (
	submissionId: string | number,
	status: SubmissionStatus,
	response: SubmissionResponse,
) => {
	return await serverApi.patch(`${submissionsEndpoint}/${submissionId}/`, {
		status,
		response,
	});
};

const requeueSubmission = async (submissionId: string | number) => {
	return await serverApi.get(`${submissionsEndpoint}/${submissionId}/requeue/`);
};

const updateVerdictForManualJudge = (submissionId: string | number, verdict: string) => {
	return serverApi.patch(`${submissionsEndpoint}/${submissionId}/manual-judge/`, {
		response: verdict,
	});
};

const rejudgeBulkSubmissions = async (params: RejudgeSubmissionsParams, contestId: number) => {
	return (
		await serverApi.get(`/api/judge/bulk-submission-rejudge/`, {
			params: {
				...params,
				contest: contestId,
			},
		})
	).data;
};

export {
	getSubmissions,
	getSubmissionDetails,
	createSubmission,
	acknowledgeSubmission,
	requeueSubmission,
	updateSubmissionStatus,
	updateVerdictForManualJudge,
	rejudgeBulkSubmissions,
};
