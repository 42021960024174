import React, { useState } from 'react';
import { Grid, TextField, Button, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { InputOutputSample } from '../../../../core/problems/types';
import { SectionPaper, SectionTitle } from '../../../../design-library';

export default function SampleIOForm({
	type,
	initFormData,
	handleSubmit,
	onSuccess,
}: {
	type: 'create' | 'edit';
	initFormData: InputOutputSample;
	handleSubmit: (data: InputOutputSample) => Promise<InputOutputSample>;
	onSuccess: () => void;
}) {
	const { enqueueSnackbar } = useSnackbar();
	const [formData, setFormData] = useState<InputOutputSample>(initFormData);
	const [errorData, setErrorData] = useState<{ [key in keyof InputOutputSample]?: string[] }>({});
	const [isLoading, setIsLoading] = useState(false);

	const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, type, checked } = event.target;
		let updatedValue = type === 'checkbox' ? checked : value;
		if (name === 'slug') {
			updatedValue = (value as string).replace(/\s+/g, '-').toLowerCase();
		}

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: updatedValue,
		}));
	};

	const onSubmitHandler = async () => {
		try {
			setIsLoading(true);
			const response = await handleSubmit(formData);
			enqueueSnackbar(`Sample "${response.title}" ${type === 'create' ? 'created' : 'edited'} successfully!`, {
				variant: 'success',
			});
			onSuccess();
		} catch (e) {
			if (axios.isAxiosError(e)) {
				setErrorData(e.response?.data ?? {});
				enqueueSnackbar(`Sample could not be ${type === 'create' ? 'created' : 'edited'}!`, { variant: 'error' });
			} else {
				enqueueSnackbar(`Something went wrong!`, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Grid container alignItems='center' justifyContent='center' sx={{ width: '100%' }}>
			<Grid item container direction='row' xs={12} rowSpacing={'2px'}>
				<Grid item xs={12}>
					<SectionTitle title={`${type === 'create' ? 'Create new' : 'Edit'} sample`} size='small' />
				</Grid>
				<Grid item xs={12}>
					<SectionPaper>
						<Grid
							container
							direction='column'
							justifyContent='center'
							sx={{ width: '100%', padding: '35px', gap: 2 }}
							component={'form'}
						>
							<TextField
								id='sample-title'
								name='title'
								label='Title'
								variant='outlined'
								value={formData.title}
								onChange={handleValueChange}
								error={!!errorData.title}
								helperText={errorData.title || 'Visible to contestants ATM! Please do not use any private title.'}
							/>
							<TextField
								multiline
								id='sample-input-field'
								name='sample_input'
								required
								label='Input'
								variant='outlined'
								value={formData.sample_input}
								onChange={handleValueChange}
								error={!!errorData.sample_input}
								helperText={errorData.sample_input}
							/>
							<TextField
								multiline
								id='sample-output-field'
								name='sample_output'
								required
								label='Output'
								variant='outlined'
								value={formData.sample_output}
								onChange={handleValueChange}
								error={!!errorData.sample_output}
								helperText={errorData.sample_output}
							/>
							<Button disabled={isLoading} variant='contained' onClick={onSubmitHandler} size='large'>
								{isLoading ? <CircularProgress size={25} /> : `${type === 'create' ? 'Create' : 'Edit'} Sample`}
							</Button>
						</Grid>
					</SectionPaper>
				</Grid>
			</Grid>
		</Grid>
	);
}
