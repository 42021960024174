import { TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import VerdictCell from '../Commons/VerdictCell';
import AuthorCell from '../../../../common/tableCellRenderers/itemComponents/AuthorCell';
import ProblemTitleCell from '../../../../common/tableCellRenderers/itemComponents/ProblemTitleCell';
import { useParams } from 'react-router-dom';
import SubmissionTimeCell from '../../../../common/tableCellRenderers/itemComponents/SubmissionTimeCell';
import CPUCell from '../../../../common/tableCellRenderers/itemComponents/CPUCell';
import MemoryCell from '../../../../common/tableCellRenderers/itemComponents/MemoryCell';
import { Submission } from '../../../../../core/submissions/types';

function SubmissionInfoTableBody({ submission }: { submission: Submission }) {
	const { contestSlug } = useParams();

	return (
		<TableBody>
			<TableRow>
				<TableCell>{submission.id}</TableCell>
				<TableCell>
					<AuthorCell
						fullName={submission.submitted_by.full_name}
						username={submission.submitted_by.username}
						submission_type={submission.submission_type}
					/>
				</TableCell>
				<TableCell>
					<ProblemTitleCell
						contestSlug={contestSlug!}
						orderCharacter={submission.problem_set.order_character}
						problemTitle={submission.problem.title}
					/>
				</TableCell>
				<TableCell>
					<SubmissionTimeCell submissionTime={submission.created_at} />
				</TableCell>
				<TableCell>{submission.language}</TableCell>
				<TableCell>
					<CPUCell cpu={submission.cpu} />
				</TableCell>
				<TableCell>
					<MemoryCell memory={submission.memory} />
				</TableCell>
				<TableCell>
					<VerdictCell status={submission.status} verdict={submission.response} />
				</TableCell>
			</TableRow>
		</TableBody>
	);
}

export default SubmissionInfoTableBody;
