import React from 'react';
import { TableStyle } from '../styles';
import { Table } from '@mui/material';
import SubmissionInfoTableBody from './SubmissionInfoTableBody';
import SubmissionInfoTableHead from './SubmissionInfoTableHead';
import { Submission } from '../../../../../core/submissions/types';

function SubmissionInfoTable({ submission }: { submission: Submission }) {
	return (
		<Table style={TableStyle}>
			<SubmissionInfoTableHead />
			<SubmissionInfoTableBody submission={submission} />
		</Table>
	);
}

export default SubmissionInfoTable;
