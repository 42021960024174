import React, { ReactElement, useEffect } from 'react';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { ContestRead } from '../core/contests/types';
import { getContestData } from '../core/contests/services';
import { LinearLoader } from '../design-library/LinearLoader';

export default function ContestJudgeRoute(): ReactElement {
	const currentUser = useCurrentUser();
	const { contestSlug }: { contestSlug?: string } = useParams();
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [contest, setContest] = React.useState<ContestRead | null>(null);

	if (!contestSlug) {
		return <Navigate to='/contests' replace />;
	}

	useEffect(() => {
		getContestData(contestSlug)
			.then((data) => setContest(data))
			.finally(() => setIsLoading(false));
	}, []);

	if (isLoading || !contest) return <LinearLoader />;

	if (!currentUser?.token) {
		return <Navigate to='/logout' replace />;
	}

	if (currentUser?.is_superuser || currentUser?.is_staff || ['STAFF', 'JUDGE'].includes(contest.role)) {
		return <Outlet />;
	}

	return <Navigate to='/logout' replace />;
}
