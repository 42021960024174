import React from 'react';
import {
	Alert,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { RejudgeSubmissionsParams } from '../../../../../core/submissions/types';

export default function RejudgeAllConfirmationDialog({
	open,
	onClose,
	onConfirmation,
	filters,
	isLoading,
}: {
	open: boolean;
	onClose: () => void;
	onConfirmation: () => void;
	filters: RejudgeSubmissionsParams;
	isLoading: boolean;
}) {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Rejudge all submissions</DialogTitle>
			<DialogContent sx={{ backgroundColor: 'white' }}>
				<DialogContentText sx={{ fontWeight: 'bolder', color: '#d32f2f' }}>
					Are you sure you want to rejudge all submissions following the filters?
				</DialogContentText>
				<div style={{ margin: '5px auto' }}>
					{Object.entries(filters).map(([key, value]) => (
						<div key={key}>
							<strong>{key}</strong>: {value ?? 'all'}
						</div>
					))}
				</div>
				<Alert severity='error' variant='filled'>
					This action will rejudge all selected submissions. Are you sure?
				</Alert>
			</DialogContent>
			<DialogActions>
				<Button disabled={isLoading} variant='contained' color='error' onClick={onConfirmation}>
					{isLoading ? <CircularProgress size={25} /> : `Rejudge`}
				</Button>
				<Button disabled={isLoading} variant='outlined' onClick={onClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
