import React from 'react';

import { GridRenderCellParams } from '@mui/x-data-grid';
import CPUCell from './itemComponents/CPUCell';

export default function RenderCPU(props: GridRenderCellParams) {
	const { value } = props;

	return <CPUCell cpu={value} />;
}
