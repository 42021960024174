import { ProblemStatus } from '../../../core/standings/types';
import { Card } from '@mui/material';
import React from 'react';
import { solvedStatusStyle, statusCardStyle, unsolvedStatusStyle } from './styles';

function SolveStatus(problem: ProblemStatus) {
	return (
		<Card style={statusCardStyle}>
			<div>
				{problem.is_solved ? (
					<div style={solvedStatusStyle}>&#10003;</div>
				) : (
					<>{problem.total_tries > 0 ? <div style={unsolvedStatusStyle}></div> : <div></div>}</>
				)}
			</div>
			<div>
				{problem.total_tries}
				<span>{problem.is_solved && <span>({problem.fine})</span>}</span>
			</div>
		</Card>
	);
}

export default SolveStatus;
