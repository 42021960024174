import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { AppToolbar } from './AppToolbar';
import { Container } from '@mui/material';
import { useTitle } from '../../contexts/TitleContext';

export default function AppLayout() {
	const { setTitle } = useTitle();
	setTitle('BAPS OJ');

	return (
		<React.Fragment>
			<AppToolbar />
			<React.Suspense>
				<Container maxWidth='xl' sx={{ padding: '24px' }}>
					<Outlet />
				</Container>
			</React.Suspense>
		</React.Fragment>
	);
}
