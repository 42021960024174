import React from 'react';

import CodeMirror from '@uiw/react-codemirror';
import { cpp } from '@codemirror/lang-cpp';
import { python } from '@codemirror/lang-python';
import { java } from '@codemirror/lang-java';

export default function CodeEditor({
	language,
	value,
	setValue,
}: {
	language: 'c' | 'cpp' | 'py2' | 'py3' | 'java' | 'text';
	value: string;
	setValue: (value: string) => void;
}) {
	const cmLanguageSelector = (language: string) => {
		switch (language) {
			case 'cpp':
				return cpp();
			case 'python':
				return python();
			case 'java':
				return java();
			default:
				return cpp();
		}
	};
	return <CodeMirror extensions={[cmLanguageSelector(language)]} value={value} onChange={setValue} />;
}
