import serverApi from '../serverApi';
import { InputOutputSample, Problem } from './types';

const problemsEndpoint = '/api/judge/problems';
const problemSamplesEndpoint = '/api/judge/problems-input-output';

const getProblem = async (problemId: number) => {
	return (await serverApi.get<Problem>(`${problemsEndpoint}/${problemId}/`)).data;
};

const getContestProblem = async (problemId: number, problemSetId: number) => {
	return (
		await serverApi.get<Problem>(`${problemsEndpoint}/${problemId}/`, { params: { problem_set_id: problemSetId } })
	).data;
};

const createNewProblem = async (contestId: number, problem: Problem) => {
	return (await serverApi.post(`${problemsEndpoint}/`, { ...problem, contest_id: contestId })).data as Problem;
};

const editProblem = async (problemId: number, problem: Partial<Problem>) => {
	return (await serverApi.patch(`${problemsEndpoint}/${problemId}/`, problem)).data as Problem;
};

const editContestProblem = async (problemId: number, problem: Partial<Problem>, problemSetId: number) => {
	return (
		await serverApi.patch(`${problemsEndpoint}/${problemId}/`, problem, { params: { problem_set_id: problemSetId } })
	).data as Problem;
};

const createNewProblemSample = async (problemId: number, sample: InputOutputSample) => {
	return (await serverApi.post(`${problemSamplesEndpoint}/`, { problem: problemId, ...sample }))
		.data as InputOutputSample;
};

const createNewContestProblemSample = async (problemId: number, sample: InputOutputSample, problemSetId: number) => {
	return (
		await serverApi.post(
			`${problemSamplesEndpoint}/`,
			{ problem: problemId, ...sample },
			{ params: { problem_set_id: problemSetId } },
		)
	).data as InputOutputSample;
};

const editProblemSample = async (sampleId: number, sample: Partial<InputOutputSample>) => {
	return (await serverApi.patch(`${problemSamplesEndpoint}/${sampleId}/`, sample)).data as InputOutputSample;
};

const editContestProblemSample = async (sampleId: number, sample: Partial<InputOutputSample>, problemSetId: number) => {
	return (
		await serverApi.patch(`${problemSamplesEndpoint}/${sampleId}/`, sample, {
			params: { problem_set_id: problemSetId },
		})
	).data as InputOutputSample;
};

const deleteProblemSample = async (sampleId: number) => {
	await serverApi.delete(`${problemSamplesEndpoint}/${sampleId}/`);
};

const deleteContestProblemSample = async (sampleId: number, problemSetId: number) => {
	await serverApi.delete(`${problemSamplesEndpoint}/${sampleId}/`, { params: { problem_set_id: problemSetId } });
};

const cloneAProblem = async (targetContestId: number, problemToCloneId: number) => {
	return (await serverApi.post(`${problemsEndpoint}/clone/`, { contest: targetContestId, problem: problemToCloneId }))
		.data as Problem;
};

const syncAProblem = async (currentProblemId: number, targetProblemId: number) => {
	return (await serverApi.post(`${problemsEndpoint}/${currentProblemId}/sync/`, { from_problem: targetProblemId }))
		.data;
};

export {
	getProblem,
	getContestProblem,
	createNewProblem,
	cloneAProblem,
	syncAProblem,
	editProblem,
	editContestProblem,
	createNewProblemSample,
	createNewContestProblemSample,
	editProblemSample,
	editContestProblemSample,
	deleteProblemSample,
	deleteContestProblemSample,
	problemsEndpoint,
};
