import { createTheme, Theme } from '@mui/material/styles';

const theme: Theme = createTheme({
	palette: {
		primary: {
			main: '#2F5D62',
		},
		secondary: {
			main: '#A7C4BC',
		},
		text: {
			primary: '#000000',
		},
		background: {
			paper: '#f8f6f6',
		},
	},
	components: {
		MuiChip: {
			styleOverrides: {
				root: {
					borderRadius: '4px',
					fontWeight: 'bold',
				},
				labelSmall: {
					fontSize: '10px',
				},
			},
		},
	},
});

export default theme;
